import { type UpdateHookResponse, mutationUpdate } from '../hooks'
import { useTantalimMutation } from '../mutation'
import { useEventOutcomesCache } from './cache'
import { API, Logger, type EventOutcomeData } from '@terros/common'

const logger = new Logger('useUpdateEventOutcome')
// logger.level = 'verbose'

export function useUpdateEventOutcome(): UpdateHookResponse<EventOutcomeData> {
  const { replaceItem } = useEventOutcomesCache()
  const mutation = useTantalimMutation<API.EventOutcomeUpdateInput, API.EventOutcomeUpdateSuccess>({
    url: '/calendar/outcome/update',
  })

  async function update(unsaved: EventOutcomeData): Promise<EventOutcomeData> {
    try {
      const { outcome } = await mutation.mutateAsync({ outcome: unsaved })
      logger.verbose('update succeeded', outcome)
      replaceItem(outcome)
      return outcome
    } catch (error) {
      throw API.toResponseError(error)
    }
  }

  return mutationUpdate(mutation, update)
}
