import { type TinyUser } from '../model/user'
import { isEmpty, isNotEmpty } from './isEmpty'

export type UserNames = Pick<TinyUser, 'lastName' | 'firstName' | 'preferredName'>
export type NameDisplayOptions = {
  order?: NameDisplayOrder
}
export type NameDisplayOrder = 'last, first' | 'first last' | 'first l'

export function getName(user: UserNames | undefined, options?: NameDisplayOptions): string {
  if (!user) return ''
  const first = getPreferredName(user) || '?'
  const last = user.lastName || '?'
  switch (options?.order) {
    case 'last, first':
      return `${last}, ${first}`.trim()
    case 'first l':
      return `${first} ${getInitial(last)}`.trim()
    default:
      return `${first} ${last}`.trim()
  }
}

export function getInitials(user: UserNames | undefined): string {
  if (!user) return ''
  const first = getInitial(getPreferredName(user)) || '?'
  const last = getInitial(user.lastName) || '?'
  return `${first}${last}`
}

function getInitial(name?: string): string | undefined {
  if (isEmpty(name)) return
  return name.trim()[0]
}

export function getPreferredName(user: Omit<UserNames, 'lastName'> | undefined): string {
  if (user) {
    if (isNotEmpty(user.preferredName?.trim())) return user.preferredName.trim()
    if (isNotEmpty(user.firstName?.trim())) return user.firstName.trim()
  }
  return ''
}
