import { AddHookResponse, mutationAdd } from '../../hooks'
import { useTantalimMutation } from '../../mutation'
import { useInterviewQuestionCache } from './cache'
import { API, InterviewQuestionData, InterviewQuestionMask, Logger, UnsavedInterviewQuestion } from '@terros/common'

const logger = new Logger('useAddInterviewQuestion')

export function useAddInterviewQuestion(): AddHookResponse<
  UnsavedInterviewQuestion | InterviewQuestionMask,
  InterviewQuestionData
> {
  logger.verbose('calling useAddInterviewQuestion')
  const cache = useInterviewQuestionCache()
  const mutation = useTantalimMutation<API.InterviewQuestionAddInput, API.InterviewQuestionAddSuccess>({
    url: '/interview/question/add',
  })

  async function add(question: UnsavedInterviewQuestion | InterviewQuestionMask): Promise<InterviewQuestionData> {
    try {
      const output = await mutation.mutateAsync(question)
      cache.addItem(output.question)
      return output.question
    } catch (error) {
      throw API.toResponseError(error)
    }
  }

  return mutationAdd(mutation, add)
}
