import { type ButtonType, type ButtonSize, type ButtonVariant, type TButtonSize, type MantineButtonSize } from './types'
import { Size, SizePrimitives } from '@front/model'
import { type MantineColor } from '@mantine/core'

export const isIconButton = (type: ButtonType): boolean => {
  return type === 'icon-rounded' || type === 'icon-full'
}

export const getButtonSize = (size: ButtonSize): MantineButtonSize => {
  switch (size) {
    case 'medium':
      return 'sm'
    case 'small':
      return 'xs'
    default:
      return size
  }
}

export const getButtonColor = (variant: ButtonVariant): MantineColor | undefined => {
  switch (variant) {
    case 'primary':
    case 'default':
    case 'ghost':
      return 'brand'
    case 'destructive':
      return 'rose'
    case 'gray':
      return 'neutral'
    case 'accent':
      return 'yellow'
    case 'text':
    case 'secondary':
      return undefined
  }
}

export const getVariant = (variant: ButtonVariant): string => {
  switch (variant) {
    case 'secondary':
    case 'destructive':
      return 'outline'
    case 'ghost':
      return 'subtle'
    case 'text':
      return 'transparent'
    case 'gray':
      return 'light'
    case 'accent':
    case 'primary':
    case 'default':
    default:
      return 'filled'
  }
}

export const isTButtonSize = (size: ButtonSize): size is TButtonSize => {
  return size === 'small' || size === 'medium'
}

type ButtonPadding = {
  paddingLeft?: number
  paddingRight?: number
  paddingTop?: number
  paddingBottom?: number
}

export const getButtonPadding = (variant: ButtonVariant, size: ButtonSize): ButtonPadding | undefined => {
  if (variant === 'text') return
  return {
    paddingLeft: size === 'small' ? SizePrimitives['3'] : SizePrimitives['4'],
    paddingRight: size === 'small' ? SizePrimitives['3'] : SizePrimitives['4'],
    paddingTop: size === 'small' ? SizePrimitives['1.5'] : SizePrimitives['2.5'],
    paddingBottom: size === 'small' ? SizePrimitives['1.5'] : SizePrimitives['2.5'],
  }
}

export const getBorderRadius = (type: ButtonType, size: ButtonSize): number => {
  if (type === 'icon-full') {
    return Size.radius.full
  }
  return size === 'small' ? Size.radius.md : Size.radius.lg
}

export const getDefaultIconSize = (size: ButtonSize): number => {
  switch (size) {
    case 'small':
      return SizePrimitives['5']
    case 'medium':
    default:
      return SizePrimitives['6']
  }
}
