import { type MediaSchema } from '../schemas'
import type { UserId } from './user'
import { type z } from 'zod'

/** Switch closer and setter on calendarEvent */
export const CURRENT_VERSION: Version = 2
export type Version = number

export type AuditProps = {
  createdAt?: number
  createdBy?: UserId
  updatedAt?: number
  updatedBy?: UserId
  lastUpdateProcess?: string
  version?: Version
}

export type Archivable = {
  isDeleted?: boolean
}

// Consider merging the concepts of BooleanFilter and ArchivedFilter
export type BooleanFilter = 'true' | 'false' | 'undefined' | 'defined'

export type ArchivedFilter = true | false | 'all'

export type WithArchivedFilter = {
  archived?: ArchivedFilter
}

export type WebHookResponseError = {
  errorCode: WebHookErrorCode
  errorMessage: string
}

export type WebHookErrorCode = 'InvalidUser' | 'UnknownError'

export type Media = z.infer<typeof MediaSchema>
