import { type GetHookResponse } from '../hooks'
import { questionKey } from '../keys'
import { useTantalimQuery } from '../query'
import { type API, DAY, HOUR, Logger, type QuestionData, type QuestionId } from '@terros/common'

const logger = new Logger('useQuestion')

// logger.level = 'verbose'

export function useQuestion(questionId: QuestionId): GetHookResponse<QuestionData> {
  return useTantalimQuery<API.QuestionGetInput, API.QuestionGetSuccess, QuestionData>({
    url: '/question/manage/get',
    queryKey: questionKey(questionId),
    input: { questionId },
    convertResponse: (data) => data.question,
    options: {
      gcTime: HOUR,
      staleTime: DAY,
    },
  })
}
