import { type BackendContextType, type Stage } from './BackendContext'
import { HTTPStatuses } from './types'
import { API, Logger, APIError } from '@terros/common'

const logger = new Logger('api-core')
// logger.level = 'verbose'
// logger.disabled = false

type Props<I> = {
  backend: BackendContextType
  path: string
  input?: I
}

export async function serverRequest<I, O extends API.ResponseSuccess>({ backend, path, input }: Props<I>): Promise<O> {
  const fullURL = getUrl(backend.getStage()) + path

  try {
    const options = await getRequestOptions(backend, fullURL, input)
    const response = await fetch(fullURL, options)
    const data = await response.json()

    logger.verbose('response', response.status, data)

    if (!response.ok) {
      const statusText = HTTPStatuses[response.status] ?? 'Unknown server error'
      throw new APIError({
        type: 'error',
        error: 'Server',
        message: statusText,
        retry: false,
      })
    }

    if (API.isResponseError(data)) throw new APIError(data)

    return data as O
  } catch (error) {
    if (error instanceof TypeError) {
      // Handle network errors (e.g., no internet, server unreachable, timeout)
      logger.error(`Network error for ${fullURL}:`, error)
      throw new APIError({ type: 'error', error: 'Network' })
    }

    logger.warn(`Request failed for ${fullURL}:`, error)
    if (error instanceof APIError) throw error
    throw new APIError({
      type: 'error',
      error: 'Server',
      message: error instanceof Error ? error.message : String(error),
      retry: false,
    })
  } finally {
    logger.verbose('Request completed for', fullURL)
  }
}

async function getRequestOptions(backend: BackendContextType, fullURL: string, input: unknown): Promise<RequestInit> {
  if (!backend.isAuthenticated()) {
    throw new APIError({
      type: 'error',
      error: 'NotAuthorized',
      message: 'You are not authenticated',
      retry: false,
    })
  }

  const token = await backend.getToken()
  if (!token) {
    throw new APIError({
      type: 'error',
      error: 'NotAuthorized',
      message: 'Your token is empty',
      retry: false,
    })
  }

  const impersonating = backend.getImpersonating()

  const impersonatingHeader: Record<string, string> = impersonating ? { impersonate_user_id: impersonating } : {}

  logger.info(`POST to ${fullURL} ${JSON.stringify(input)} Impersonating: ${JSON.stringify(impersonatingHeader)}`)

  const options: RequestInit = {
    method: 'POST',
    headers: {
      ...impersonatingHeader,
      Authorization: `Bearer ${token}`,
      Accept: 'application/json',
      'Content-Type': 'application/json;charset=UTF-8',
    },
    body: JSON.stringify(input || '{}'),
  }

  logger.verbose(`with options ${JSON.stringify(options, null, 2)}`)
  return options
}

function getUrl(stage: Stage): string {
  let baseUrl = 'terros.com'
  baseUrl = stage === 'prod' ? baseUrl : `${stage}.${baseUrl}`
  return `https://api.${baseUrl}`
}
