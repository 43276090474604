import { mutationRemove, type RemoveHookResponse } from '../hooks'
import { useLocationCache } from '../location'
import { useTantalimMutation } from '../mutation'
import { useReplaceLocation } from './replaceLocation'
import { API, Logger } from '@terros/common'

const logger = new Logger('useRemoveContact')

export function useRemoveContact(): RemoveHookResponse<API.ContactRemoveInput> {
  logger.verbose('calling useRemoveContact')
  const replaceLocation = useReplaceLocation()
  const { replaceItem } = useLocationCache()
  const mutation = useTantalimMutation<API.ContactRemoveInput, API.ContactRemoveSuccess>({ url: '/contact/remove' })

  async function remove(input: API.ContactRemoveInput): Promise<void> {
    try {
      logger.verbose('starting remove')
      const { location } = await mutation.mutateAsync(input)
      const { locationId } = location
      replaceItem(locationId, location)
      replaceLocation({ locationId, newLocation: location, disposition: location.disposition })
      logger.verbose('done with remove')
    } catch (error) {
      throw API.toResponseError(error)
    }
  }

  return mutationRemove(mutation, remove)
}
