import { mutationUpdate, UpdateHookResponse } from '../../hooks'
import { useTantalimMutation } from '../../mutation'
import { useInterviewQuestionCache } from './cache'
import { API, InterviewQuestionData, Logger } from '@terros/common'

const logger = new Logger('useUpdateInterviewQuestion')

export function useUpdateInterviewQuestion(): UpdateHookResponse<InterviewQuestionData> {
  logger.verbose('calling useUpdateInterviewQuestion')
  const cache = useInterviewQuestionCache()

  const mutation = useTantalimMutation<API.InterviewQuestionUpdateInput, API.InterviewQuestionUpdateSuccess>({
    url: '/interview/question/update',
  })

  async function update(question: InterviewQuestionData): Promise<InterviewQuestionData> {
    try {
      logger.verbose('starting update')
      const response = await mutation.mutateAsync({ question })
      cache.replaceItem(response.question)
      logger.verbose('done with update')
      return response.question
    } catch (error) {
      throw API.toResponseError(error)
    }
  }

  return mutationUpdate(mutation, update)
}
