import { isEmpty } from './isEmpty'

type SplitArrayOutput<T> = { yes: T[]; no: T[] }

export function splitArray<T>(array: T[], fxn: (t: T) => boolean): SplitArrayOutput<T> {
  const output: SplitArrayOutput<T> = { yes: [], no: [] }
  array.forEach((t) => {
    if (fxn(t)) {
      output.yes.push(t)
    } else {
      output.no.push(t)
    }
  })
  return output
}

export function unique<T extends number | string | undefined>(array: T[] | undefined): T[] {
  if (isEmpty(array)) return []
  return [...new Set(array)]
}

export function sameArray(arr1: unknown[], arr2: unknown[]): boolean {
  if (arr1 === arr2) return true
  if (arr1.length !== arr2.length) return false
  const sorted1 = arr1.toSorted()
  const sorted2 = arr2.toSorted()
  if (sorted1.every((item, index) => item === sorted2[index])) return true
  return false
}
