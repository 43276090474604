import { mutationRemove, type RemoveHookResponse } from '../hooks'
import { useTantalimMutation } from '../mutation'
import { useRoleCache } from './cache'
import { API, Logger } from '@terros/common'

const logger = new Logger('useRemoveRole')

export function useRemoveRole(): RemoveHookResponse<API.RoleRemoveInput> {
  logger.verbose('calling useRemoveRole')
  const cache = useRoleCache()
  const mutation = useTantalimMutation<API.RoleRemoveInput, API.RoleRemoveSuccess>({ url: '/role/remove' })

  async function remove(input: API.RoleRemoveInput): Promise<void> {
    try {
      await mutation.mutateAsync(input)
      cache.clearList()
      cache.clearItem(input.roleId)
    } catch (error) {
      throw API.toResponseError(error)
    }
  }

  return mutationRemove(mutation, remove)
}
