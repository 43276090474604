import {
  getTeams,
  isEmpty,
  type PermissionType,
  type UserData,
  type UserProfileData,
  getName,
  isNotEmpty,
  TERROS_USER_PERMISSIONS,
} from '@terros/common'

export function matchUser(
  user: Pick<UserData, 'firstName' | 'lastName' | 'preferredName' | 'email' | 'phone' | 'teams'>,
  searchText?: string
): boolean {
  if (isEmpty(searchText)) return true
  const needle = searchText.toUpperCase()
  const { email, phone, teams } = user
  if (getName(user).toUpperCase().includes(needle)) return true
  if (
    getName({ ...user, preferredName: undefined })
      .toUpperCase()
      .includes(needle)
  )
    return true
  if (email.toUpperCase().includes(needle)) return true
  if (phone?.toUpperCase().includes(needle)) return true
  const teamNames = getTeams(teams).map((t) => t.name.toUpperCase())
  if (teamNames.some((name) => name.includes(needle))) return true
  if (teams?.groups && teams.groups.filter((group) => group.name.toUpperCase().includes(needle)).length > 0) return true
  return false
}

export function sortUsersByLastName(
  a: Pick<UserData, 'firstName' | 'lastName' | 'preferredName'>,
  b: Pick<UserData, 'firstName' | 'lastName' | 'preferredName'>
): number {
  if (!a) return -1
  if (!b) return 1
  if (a.lastName !== b.lastName) {
    return a.lastName.trim().localeCompare(b.lastName.trim())
  }
  const aFirst = getFirstName(a)
  const bFirst = getFirstName(b)
  return aFirst.localeCompare(bFirst)
}

export function sortUsersByFirstName(
  a: Pick<UserData, 'firstName' | 'lastName' | 'preferredName'>,
  b: Pick<UserData, 'firstName' | 'lastName' | 'preferredName'>
): number {
  if (!a) return -1
  if (!b) return 1

  const aFirst = getFirstName(a)
  const bFirst = getFirstName(b)
  if (aFirst !== bFirst) {
    return aFirst.trim().localeCompare(bFirst.trim())
  }
  return a.lastName.trim().localeCompare(b.lastName.trim())
}

export function getFirstName({ preferredName, firstName }: Pick<UserData, 'firstName' | 'preferredName'>): string {
  return preferredName?.trim() || firstName.trim()
}

/** checks for permission on any team */
export function hasUserPermission(
  user: UserProfileData | undefined,
  permissionType: PermissionType,
  isTerros = false
): boolean {
  if (!user) return false
  if (isTerros && TERROS_USER_PERMISSIONS.includes(permissionType)) return true
  return isNotEmpty(user.permissionMap?.[permissionType])
}
