import { serverRequest, useBackend } from './api'
import { useMutation, type UseMutationResult } from '@tanstack/react-query'
import { type ResponseSuccess, type APIError } from '@terros/common'

type MutationInput = {
  url: string
}

export function useTantalimMutation<ApiInput, ApiSuccess extends ResponseSuccess>({
  url,
}: MutationInput): TantalimMutationResponse<ApiInput, ApiSuccess> {
  const backend = useBackend()
  const mutationFn = (variables: ApiInput): Promise<ApiSuccess> => {
    return serverRequest<ApiInput, ApiSuccess>({ backend, path: url, input: variables })
  }

  return useMutation<ApiSuccess, APIError, ApiInput>({ mutationFn })
}

export type TantalimMutationResponse<ApiInput, ApiSuccess> = UseMutationResult<ApiSuccess, APIError, ApiInput>
