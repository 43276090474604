import { mutationUpdate, type UpdateHookResponse } from '../hooks'
import { useTantalimMutation } from '../mutation'
import { useTaskCache } from './cache'
import { API, Logger, type TaskData } from '@terros/common'

const logger = new Logger('useUpdateTask')

export function useUpdateTask(): UpdateHookResponse<TaskData> {
  logger.verbose('calling useUpdateTask')
  const cache = useTaskCache()

  const mutation = useTantalimMutation<API.TaskUpdateInput, API.TaskUpdateSuccess>({
    url: '/task/update',
  })

  async function update(task: TaskData): Promise<TaskData> {
    try {
      logger.verbose('starting update')
      const response = await mutation.mutateAsync({ task })
      if (response.task) {
        cache.clearList()
      }
      logger.verbose('done with update')
      return response.task
    } catch (error) {
      throw API.toResponseError(error)
    }
  }

  return mutationUpdate(mutation, update)
}
