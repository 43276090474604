import { type AccountStatusId, type API, CELL_SIZE, type GeoIdParts, type LatLng, Logger } from '@terros/common'

const logger = new Logger('location/util')

export function getSector(coordinates: LatLng): GeoIdParts {
  const sectors = getSectors(coordinates, { latitude: 0, longitude: 0 }, CELL_SIZE)
  if (sectors.length !== 1)
    throw new Error(`failed to find ${sectors.length} sectors instead of just 1 from ${coordinates}`)
  return sectors[0]
}

export function getSectors(center: LatLng, width: LatLng, cellSize: number): GeoIdParts[] {
  const sectors: GeoIdParts[] = []
  logger.verbose('request', center, width)

  const longRange = getSingleRange(center.longitude, width.longitude / 2, cellSize)
  const latRange = getSingleRange(center.latitude, width.latitude / 2, cellSize)

  longRange.forEach((x) => {
    latRange.forEach((y) => {
      sectors.push({
        cellSize,
        longitude: x,
        latitude: y,
      })
    })
  })
  return sectors
}

export function getSingleRange(value: number, radius = 0, cellSize = 1): number[] {
  if (radius < 0) throw new Error('getSingleRange: radius cannot be negative')
  if (cellSize <= 0) throw new Error('getSingleRange: cellSize must be a positive number')
  const range = new Set<number>()
  const factor = 1 / cellSize
  const floating_point_error = 0.00000001
  const minRange = value - radius
  const maxRange = value + radius + floating_point_error
  logger.verbose(`value=${value} radius=${radius} maxRange=${maxRange} cellSize=${cellSize} factor=${factor}`)
  for (let x = minRange; x <= maxRange; x = x + cellSize) {
    logger.verbose(`x=${x} next=${x + cellSize}`)
    range.add(Math.floor(x * factor) / factor)
  }
  // If the radius is smaller than the cellSize, we can miss these two cases.
  // There may be a more elegant solution but I'm not sure what it is.
  range.add(Math.floor(value * factor) / factor)
  range.add(Math.floor((value + radius) * factor) / factor)
  return [...range]
}

export function getScorecardAccountStatusRecord(
  scorecard: API.LocationScorecardSuccess
): Record<AccountStatusId, number> {
  const { type, users, locationsPitched, ...record } = scorecard
  return record
}
