import { type Archivable, type AuditProps } from './common'
import { isId } from './ids'
import { type LatLng } from './location'
import { type WithPermissionOverrides } from './permissions'
import { type RoleData, type RoleId } from './role'
import { type TinyUser, type UserData } from './user'

export const TERROS_TECH_TEAM_ID: TeamId = 'Team:tech'

export type TeamId = `Team:${string}` | `Team.${string}`

export type WithTeamId = Pick<TeamData, 'teamId'>

export function isTeamId(val: unknown): val is TeamId {
  return isId(val, 'Team')
}

export function toTeamId(team: WithTeamId): TeamId {
  return team.teamId
}

export type UnsavedTeam = {
  name: string
  parentId?: TeamId
  /** is this an office or region or other */
  level: TeamLevel
  externalId?: string
  description?: string
}

export type TeamLevel = 1 | 2 | 3 | 4 | 5 | 6

export type DefaultRole = Pick<RoleData, 'name' | 'roleId'> & WithPermissionOverrides

export interface MicroMembership {
  teamId: TeamId
  /** @deprecated - use roleIds */
  membership: TeamMembershipType
  roleIds?: RoleId[]
}

export type MicroTeam = Pick<TeamData, 'teamId' | 'parentId' | 'restrictVisibility'>

export interface TeamData extends Archivable, AuditProps, UnsavedTeam {
  readonly teamId: TeamId
  officeLocation?: LatLng
  officeAddress?: string
  externalId?: string
  isCloserBoundary?: boolean
  /** Whether users on this team can view teams and users outside this team */
  restrictVisibility?: boolean
  avatarBlurhash?: string
  /** Calculated field */
  avatarUrl?: string
  /** Calculated field, non-inclusive upline */
  parentTeamIds: TeamId[]
  /** Calculated field */
  uploadAvatarUrl?: string
}

export type PartialTeamData = Partial<TeamData> & WithTeamId

export type TeamFilterData = Pick<TeamData, 'teamId' | 'name'>

/** @deprecated - use roles instead */
export type TeamMembershipType = 'manager' | 'member'

export interface TeamMemberData {
  user: UserData
  /** @deprecated - use roleIds */
  membership: TeamMembershipType
  /** locked team memberships can't be deleted by automated processes */
  locked?: boolean
  roleIds?: RoleId[]
}

export type TinyTeam = Pick<
  TeamData,
  'teamId' | 'name' | 'parentId' | 'isDeleted' | 'externalId' | 'avatarBlurhash' | 'avatarUrl' | 'restrictVisibility'
>

/** TODO: move to TinyTeam */
export type TinyTeamWithSingleManager = TinyTeam & {
  /** @deprecated see UserTeamData */
  manager?: TinyUser
}

export type TinyTeamWithLocked = TinyTeam & {
  locked?: boolean
}

export type UserTeamData = UserTeamsByLevel & {
  manages: TeamId[]
  directMemberOf: TinyTeam[]
  /** includes all upline teams */
  memberOf: TinyTeam[]
  /** all possible managers for this user */
  managers: TinyUser[]
}

export type UserTeamsByLevel = {
  team1?: TinyTeam[]
  team2?: TinyTeam[]
  team3?: TinyTeam[]
  team4?: TinyTeam[]
  team5?: TinyTeam[]
  team6?: TinyTeam[]
  /** Overflow (`groups` does not contain any of team1 team2 team3 team4 or manages) */
  groups: TinyTeam[]
}
