import AsyncStorage from '@react-native-async-storage/async-storage'
import { Logger } from '@terros/common'

const logger = new Logger('storage')

// logger.level = 'verbose'

/** Native asynchronous storage. Stored permanently. */
export async function getStoredKeys(): Promise<readonly string[]> {
  const keys = await AsyncStorage.getAllKeys()
  logger.verbose('async storage keys', keys)
  return keys
}

export async function storeItem<T>(key: string, value: T): Promise<T> {
  logger.verbose('async storage store', key, value)
  const json = JSON.stringify(value)
  await AsyncStorage.setItem(key, json)
  return value
}

export async function readItem<T>(key: string): Promise<T | undefined> {
  logger.verbose('async storage read', key)
  const json = await AsyncStorage.getItem(key)
  return json !== null ? JSON.parse(json) : undefined
}

export async function clearItem<T>(key: string): Promise<void> {
  logger.verbose('async storage clear', key)
  await AsyncStorage.removeItem(key)
}
