import { accountStatusesKey } from '../keys'
import { useQueryClient } from '@tanstack/react-query'
import { type AccountStatusData, type API, Logger } from '@terros/common'

const logger = new Logger('useAccountStatusCache')
// logger.level = 'verbose'

type Cached = API.AccountStatusListSuccess

export function useAccountStatusCache() {
  const queryClient = useQueryClient()
  return {
    clearList: () => {
      logger.verbose('invalidateQueries accountStatusesKey')
      queryClient.invalidateQueries({ queryKey: accountStatusesKey() })
    },
    replaceList: (response: Cached) => queryClient.setQueryData<Cached>(accountStatusesKey(), response),
    addItem: (status: AccountStatusData) => {
      logger.verbose('replaceItem', status)
      queryClient.setQueryData<Cached>(accountStatusesKey(), (existing) => {
        if (!existing) return
        return {
          ...existing,
          statuses: [...existing.statuses, status],
        }
      })
    },
    replaceItem: (status: AccountStatusData) => {
      logger.verbose('replaceItem', status)
      queryClient.setQueryData<Cached>(accountStatusesKey(), (existing) => {
        if (!existing) return
        return {
          ...existing,
          statuses: existing.statuses.map((item) => (item.statusId === status.statusId ? status : item)),
        }
      })
    },
    removeItem: (statusId: string) => {
      logger.verbose('removeItem', statusId)
      queryClient.setQueryData<Cached>(accountStatusesKey(), (existing) => {
        if (!existing) return
        return {
          ...existing,
          statuses: existing.statuses.filter((item) => item.statusId !== statusId),
        }
      })
    },
  }
}
