import { useBackend } from '../api'
import { usePermissionCheck } from '../permissions'
import { Logger, type UserId } from '@terros/common'
import { useCallback } from 'react'

const logger = new Logger('useImpersonate')

type State = {
  start: VoidFunction
  stop: VoidFunction
  allowed: boolean
  isLoading: boolean
}

export function useImpersonate(targetId: UserId): State {
  logger.verbose('calling useImpersonate', targetId)
  const backend = useBackend()
  const { data: allowed, isLoading } = usePermissionCheck({ permissionType: 'user:login', targetId })

  const start = useCallback((): void => {
    if (!allowed) return
    logger.info('startImpersonating')
    backend.setImpersonating(targetId)
  }, [allowed, targetId])

  const stop = useCallback((): void => {
    backend.setImpersonating()
  }, [])

  return {
    start,
    stop,
    allowed: allowed ?? false,
    isLoading,
  }
}
