import { mutationUpdate, type UpdateHookResponse } from '../hooks'
import { useTantalimMutation } from '../mutation'
import { useInterviewCache } from './cache'
import { API, type InterviewData, Logger } from '@terros/common'

const logger = new Logger('useUpdateInterview')

export function useUpdateInterview(): UpdateHookResponse<InterviewData> {
  logger.verbose('calling useUpdateInterview')
  const cache = useInterviewCache()

  const mutation = useTantalimMutation<API.InterviewUpdateInput, API.InterviewUpdateSuccess>({
    url: '/interview/update',
  })

  async function update(interview: InterviewData): Promise<InterviewData> {
    try {
      logger.verbose('starting update')
      const response = await mutation.mutateAsync({ interview })
      cache.setItem(response.interview)
      logger.verbose('done with update')
      return response.interview
    } catch (error) {
      throw API.toResponseError(error)
    }
  }

  return mutationUpdate(mutation, update)
}
