import { locationKey } from '../keys'
import { useQueryClient } from '@tanstack/react-query'
import { type LocationId, type ExtendedLocationData, type PrescreenDecision, type ResidentId } from '@terros/common'

export function useLocationCache() {
  const queryClient = useQueryClient()
  const clearItem = (locationId: LocationId): void => {
    queryClient.invalidateQueries({ queryKey: locationKey(locationId) })
  }
  return {
    replaceItem: (locationId: LocationId, data: ExtendedLocationData) => {
      queryClient.setQueryData(locationKey(locationId), data)
    },
    clearItem,
    updatePrescreen: (locationId: LocationId, residentId: ResidentId | undefined, prescreen: PrescreenDecision) => {
      if (residentId) {
        queryClient.setQueryData<ExtendedLocationData>(locationKey(locationId), (old) => {
          if (!old) return

          return {
            ...old,
            residents: old.residents?.map((resident) => {
              if (resident.residentId !== residentId) return resident
              return {
                ...resident,
                decision: prescreen,
              }
            }),
          }
        })
      }
      clearItem(locationId)
    },
  }
}
