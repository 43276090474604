import { type GetHookResponse } from '../hooks'
import { questionsKey } from '../keys'
import { useTantalimQuery } from '../query'
import { type API, DAY, HOUR, Logger, type QuestionData } from '@terros/common'

const logger = new Logger('useQuestions')

// logger.level = 'verbose'

export function useQuestions(): GetHookResponse<QuestionData[]> {
  return useTantalimQuery<API.QuestionListInput, API.QuestionListSuccess, QuestionData[]>({
    url: '/question/manage/list',
    queryKey: questionsKey(),
    input: {},
    convertResponse: (data) => data.questions,
    options: {
      gcTime: HOUR,
      staleTime: DAY,
    },
  })
}
