import { Logger } from '@terros/common'

const logger = new Logger('basicS3Upload')

export const basicS3Upload = async (uploadAvatarUrl: string | undefined, blob: Blob | null): Promise<void> => {
  if (!blob) throw new Error('No file selected')
  if (!uploadAvatarUrl) throw new Error('No upload url found')
  logger.info(`Uploading ${blob.size} bytes to`, uploadAvatarUrl)
  const res = await fetch(uploadAvatarUrl, {
    method: 'PUT',
    body: blob,
    headers: {
      'Content-Type': blob.type,
    },
  })
  if (!res.ok) {
    logger.error('Failed to upload', await res.text())
    throw new Error('Failed to upload')
  }
}
