import { type CloserStatus, type UserId, isSystemUser, isUserId } from '../model'
import { isEmpty } from './isEmpty'

export function isRealUserId(userId?: UserId): boolean {
  if (isEmpty(userId)) return false
  if (isSystemUser(userId)) return false
  return isUserId(userId)
}

export function canSet(closerStatus?: CloserStatus): boolean {
  if (isEmpty(closerStatus)) return false
  const setterStatuses: CloserStatus[] = ['Needs_Help', 'Can_Close']
  return setterStatuses.includes(closerStatus)
}

export function canClose(closerStatus?: CloserStatus): boolean {
  if (isEmpty(closerStatus)) return false
  const closingStatuses: CloserStatus[] = ['Can_Close', 'Closer_Only', 'Can_Help']
  return closingStatuses.includes(closerStatus)
}
