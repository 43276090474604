import { type RemoveHookResponse, mutationRemove } from '../hooks'
import { useLocationCache } from '../location'
import { useTantalimMutation } from '../mutation'
import { API, Logger, type LocationId } from '@terros/common'

const logger = new Logger('note')

export function useDeleteLocationNote(locationId: LocationId): RemoveHookResponse<API.LocationNoteDeleteInput> {
  logger.verbose('calling useRemoveLocationNote')
  const { replaceItem } = useLocationCache()

  const mutation = useTantalimMutation<API.LocationNoteDeleteInput, API.LocationNoteDeleteSuccess>({
    url: '/location/note/delete',
  })

  async function remove(input: API.LocationNoteDeleteInput): Promise<void> {
    try {
      const { location: newLocation } = await mutation.mutateAsync(input)
      replaceItem(locationId, newLocation)
    } catch (error) {
      throw API.toResponseError(error)
    }
  }

  return mutationRemove(mutation, remove)
}
