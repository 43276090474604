import { type PermissionDefinition } from './types'

export type PermissionDefinitions = typeof PERMISSION_DEFINITION
export type PermissionType = keyof typeof PERMISSION_DEFINITION

export const PERMISSION_DEFINITION = {
  'account:import': {
    category: 'Account',
    label: 'Import Accounts',
    description: 'Allow importing accounts in bulk',
    entities: ['company'],
  } satisfies PermissionDefinition,
  'account:manage': {
    category: 'Account',
    label: 'Manage Accounts',
    description: 'Allow managing accounts including account duplicates',
    entities: ['company'],
  } satisfies PermissionDefinition,
  'account:list': {
    category: 'Account',
    label: 'View Accounts',
    description: 'Allow viewing all accounts',
    entities: ['self', 'downline', 'company'],
  } satisfies PermissionDefinition,
  'accountStatus:list': {
    category: 'Settings',
    label: 'View Account Statuses',
    description: 'View all account statuses and workflows',
    entities: ['company'],
  } satisfies PermissionDefinition,
  'accountStatus:save': {
    category: 'Settings',
    label: 'Manage Account Statuses',
    description: 'Can update account statuses workflow settings',
    entities: ['company'],
  } satisfies PermissionDefinition,
  'accountRule:save': {
    category: 'Settings',
    label: 'Manage Account Rules',
    description: 'Allow managing account rules',
    entities: ['company'],
  } satisfies PermissionDefinition,

  'apikey:manage': {
    category: 'Settings',
    label: 'Manage API Keys',
    description: 'Allow managing API keys',
    entities: ['company'],
  } satisfies PermissionDefinition,

  'attachment:manage': {
    category: 'Calendar',
    label: 'Save Attachments',
    description: 'Allow adding and deleting attachments',
    entities: ['self', 'downline', 'company'],
  } satisfies PermissionDefinition,
  'attachment:list': {
    category: 'Calendar',
    label: 'View Attachments',
    description: 'Allow viewing attachments',
    entities: ['self', 'downline', 'company'],
  } satisfies PermissionDefinition,

  'area:add': {
    category: 'Prospecting',
    label: 'Manage Areas',
    description: 'Allow managing area assignments including adding, editing, and removing',
    entities: ['company', 'downline'],
  } satisfies PermissionDefinition,
  'area:list': {
    category: 'Prospecting',
    label: 'View Areas',
    description: 'Allow viewing area assignments',
    entities: ['company', 'downline', 'self'],
  } satisfies PermissionDefinition,

  'company:add': {
    category: 'Settings',
    label: 'Add Company',
    description: 'Allow creating a new company account',
    entities: [],
  } satisfies PermissionDefinition,
  'company:delete': {
    category: 'Company',
    label: 'Delete Company',
    description: 'Allow permanently deleting a company account',
    entities: [], // only for terros tech
  } satisfies PermissionDefinition,
  'company:read': {
    category: 'Settings',
    label: 'View Company',
    description: 'Allow viewing company account and settings',
    entities: ['company'],
  } satisfies PermissionDefinition,
  'company:update': {
    category: 'Settings',
    label: 'Update Company',
    description: 'Allow updating your company account settings',
    entities: ['company'],
  } satisfies PermissionDefinition,
  'company:list': {
    category: 'Settings',
    label: 'View All Companies',
    description: 'Allow viewing all companies (only for Terros staff)',
    entities: [],
  } satisfies PermissionDefinition,

  'contact:list': {
    category: 'Prospecting',
    label: 'View Prospecting Details',
    description: 'Allow viewing prospecting details',
    entities: ['self', 'downline', 'company'],
  } satisfies PermissionDefinition,
  'contact:add': {
    category: 'Prospecting',
    label: 'Add Disposition',
    description: 'Disposition a home or property',
    entities: ['self'],
  } satisfies PermissionDefinition,
  'contact:delete': {
    category: 'Prospecting',
    label: 'Delete Disposition',
    description: 'Permanently delete a Disposition for a home or property',
    entities: ['self'],
  } satisfies PermissionDefinition,
  'contact:coach': {
    category: 'Prospecting',
    label: 'Prospecting Coach',
    description: "Manage and coach sales reps' prospecting activities",
    entities: ['downline', 'company'],
  } satisfies PermissionDefinition,
  'contact:import': {
    category: 'Prospecting',
    label: 'Import Knocks',
    description: 'Allow importing knocking data in bulk',
    entities: ['company'],
  } satisfies PermissionDefinition,
  'contact:update': {
    category: 'Prospecting',
    label: 'Update Disposition',
    description: 'Make changes to a Disposition for a home or property',
    entities: ['self'],
  } satisfies PermissionDefinition,

  'customField:list': {
    category: 'Settings',
    label: 'View Custom Fields',
    description: 'Allow viewing custom fields',
    entities: ['company'],
  } satisfies PermissionDefinition,
  'customField:save': {
    category: 'Settings',
    label: 'Save Custom Fields',
    description: 'Allow adding, editing, and hiding custom fields',
    entities: ['company'],
  } satisfies PermissionDefinition,
  'disposition:list': {
    category: 'Settings',
    label: 'View Dispositions',
    description: 'Allow viewing disposition types',
    entities: ['self', 'downline', 'company'],
  } satisfies PermissionDefinition,
  'disposition:save': {
    category: 'Settings',
    label: 'Save Dispositions',
    description: 'Allow adding, editing, and hiding disposition types',
    entities: ['self', 'downline', 'company'],
  } satisfies PermissionDefinition,

  'event:read': {
    category: 'Calendar',
    label: 'View Events',
    description: 'Allow viewing events',
    entities: ['self', 'downline', 'company'],
  } satisfies PermissionDefinition,
  'event:save': {
    category: 'Calendar',
    label: 'Save Event',
    description: 'Allow saving events',
    entities: ['self', 'downline', 'company'],
  } satisfies PermissionDefinition,
  'event:delete': {
    category: 'Calendar',
    label: 'Delete Event',
    description: 'Allow deleting events',
    entities: ['self', 'downline', 'company'],
  } satisfies PermissionDefinition,
  'event:import': {
    category: 'Calendar',
    label: 'Import Events',
    description: 'Allow importing events in bulk',
    entities: ['company'],
  } satisfies PermissionDefinition,
  'event:manage': {
    category: 'Calendar',
    label: 'Manage Event',
    description: 'Allow managing events including assigning events like closes',
    entities: ['company', 'downline'],
  } satisfies PermissionDefinition,

  'pitch:record': {
    category: 'Pitch',
    label: 'Record Pitch',
    description: 'Allow for recording pitches',
    entities: ['self', 'downline'],
  } satisfies PermissionDefinition,
  'pitch:delete': {
    category: 'Pitch',
    label: 'Delete Pitch',
    description: 'Allow for permanently deleting pitches',
    entities: ['downline'],
  } satisfies PermissionDefinition,

  'evaluation:add': {
    category: 'Evaluation',
    label: 'Add Evaluation',
    description: 'Allow for adding evaluations',
    entities: ['self', 'downline', 'company'],
  } satisfies PermissionDefinition,
  'evaluation:delete': {
    category: 'Evaluation',
    label: 'Delete Evaluation',
    description: 'Allow for permanently deleting evaluations',
    entities: ['downline'],
  } satisfies PermissionDefinition,
  'evaluation:list': {
    category: 'Evaluation',
    label: 'View Evaluations',
    description: 'Allow for viewing evaluations',
    entities: ['self', 'downline', 'company'],
  } satisfies PermissionDefinition,
  'evaluation:manage': {
    category: 'Evaluation',
    label: 'Manage Evaluation',
    description: 'Allow for managing evaluations',
    entities: ['downline'],
  } satisfies PermissionDefinition,

  'event:outcome:save': {
    category: 'Settings',
    label: 'Manage Closer Outcome Types',
    description: 'Allow adding, editing, and hiding closer event outcomes',
    entities: ['company'],
  } satisfies PermissionDefinition,
  'event:outcome:list': {
    category: 'Settings',
    label: 'View Closer Outcome Types',
    description: 'Allow viewing closer event outcome types',
    entities: ['company'],
  } satisfies PermissionDefinition,
  'availability:list': {
    category: 'Calendar',
    label: 'View Closer Availability',
    description: 'Allow viewing closer availability',
    entities: ['downline', 'company'],
  } satisfies PermissionDefinition,

  'location:list': {
    category: 'Prospecting',
    label: 'View Locations',
    description: 'Allow viewing property locations (aka pins) on a map',
    entities: ['global'],
  } satisfies PermissionDefinition,
  'location:add': {
    category: 'Prospecting',
    label: 'Add Custom Location',
    description: 'Allow adding custom locations (or dropping a new pin) to a map',
    entities: ['company'],
  } satisfies PermissionDefinition,
  'location:delete': {
    category: 'Prospecting',
    label: 'Delete Custom Location',
    description: 'Allow permanently deleting a custom location (or pin) from a map',
    entities: ['global'],
  },

  'message:archive': {
    category: 'Messages',
    label: 'Archive Message',
    description: 'Allow archiving messages',
    entities: ['self'],
  } satisfies PermissionDefinition,
  'message:delete': {
    category: 'Messages',
    label: 'Delete Message',
    description: 'Allow permanently deleting messages',
    entities: ['company'],
  } satisfies PermissionDefinition,
  'message:read': {
    category: 'Messages',
    label: 'View Message',
    description: 'Allow viewing messages',
    entities: ['self'],
  } satisfies PermissionDefinition,
  'message:send': {
    category: 'Messages',
    label: 'Send Message',
    description: 'Allow sending messages to individuals',
    entities: ['self', 'downline', 'company'],
  } satisfies PermissionDefinition,
  'message:send:group': {
    category: 'Messages',
    label: 'Send Group Messages',
    description: 'Allow sending messages to a group',
    entities: ['downline', 'company'],
  } satisfies PermissionDefinition,

  'plan:add': {
    category: 'Goals & Plans',
    label: 'Add Plan',
    description: 'Allow creating a new quarterly and weekly plans',
    entities: ['downline', 'self'],
    // TODO consider a team entity scope where it only applies to the team and not the up or down line
  } satisfies PermissionDefinition,
  'plan:delete': {
    category: 'Goals & Plans',
    label: 'Delete Plan',
    description: 'Allow permanently deleting a quarterly or weekly plan',
    entities: ['company'],
  } satisfies PermissionDefinition,
  'plan:list': {
    category: 'Goals & Plans',
    label: 'View Plans',
    description: 'Allow viewing quarterly and weekly plans',
    entities: ['company', 'downline', 'self'],
  } satisfies PermissionDefinition,
  'plan:update': {
    category: 'Goals & Plans',
    label: 'Update Plan',
    description: 'Allow updating quarterly and weekly plans',
    entities: ['company', 'downline', 'self'],
  } satisfies PermissionDefinition,
  'plan:manage': {
    category: 'Goals & Plans',
    label: 'Manage Plan',
    description: 'Allow managing plans including approving quarterly plans',
    entities: ['company', 'downline'],
  } satisfies PermissionDefinition,

  'task:list': {
    category: 'Tasks',
    label: 'View Tasks',
    description: 'Allow viewing tasks',
    entities: ['self', 'downline', 'company'],
  } satisfies PermissionDefinition,
  'task:add': {
    category: 'Tasks',
    label: 'Add Task',
    description: 'Allow creating tasks',
    entities: ['company', 'downline', 'self'],
  } satisfies PermissionDefinition,
  'task:update': {
    category: 'Tasks',
    label: 'Update Task',
    description: 'Allow updating tasks including snooze and ignore',
    entities: ['company', 'downline', 'self'],
  } satisfies PermissionDefinition,
  'task:delete': {
    category: 'Tasks',
    label: 'Delete Task',
    description: 'Allow permanently deleting tasks',
    entities: [], // only for terros tech
  } satisfies PermissionDefinition,

  'team:add': {
    category: 'Teams',
    label: 'Add Team',
    description: 'Allow creating a new team',
    entities: ['company', 'downline'],
  } satisfies PermissionDefinition,
  'team:delete': {
    category: 'Teams',
    label: 'Delete Team',
    description: 'Allow permanently deleting a team',
    entities: [], // only for terros tech
  } satisfies PermissionDefinition,
  'team:list': {
    deprecated: true, // replacing with restrictVisibility on TeamDb
    category: 'Teams',
    label: 'View Teams',
    description: 'Allow viewing teams',
    entities: ['company', 'downline'],
  } satisfies PermissionDefinition,
  'team:update': {
    category: 'Teams',
    label: 'Update Team',
    description: 'Allow updating team settings',
    entities: ['company', 'downline'],
  } satisfies PermissionDefinition,
  'team:archive': {
    category: 'Teams',
    label: 'Archive Team',
    description: 'Allow archiving a team',
    entities: ['company', 'downline'],
  } satisfies PermissionDefinition,

  'ratio:list': {
    category: 'Settings',
    label: 'View Ratios',
    description: 'Allow viewing ratios',
    entities: ['company'],
  } satisfies PermissionDefinition,

  'ratio:save': {
    category: 'Settings',
    label: 'Save Ratios',
    description: 'Allow adding and editing ratios',
    entities: ['company'],
  } satisfies PermissionDefinition,

  'role:manage': {
    category: 'Roles',
    label: 'Manage Roles',
    description: 'Allow managing roles',
    entities: ['company'],
  } satisfies PermissionDefinition,
  'role:list': {
    category: 'Roles',
    label: 'View Roles',
    description: 'Allow viewing roles in your company',
    entities: ['company'],
  } satisfies PermissionDefinition,
  'role:delete': {
    category: 'Roles',
    label: 'Delete Roles',
    description: 'Allow deleting roles in your company',
    entities: [],
  } satisfies PermissionDefinition,

  'user:export': {
    category: 'Users',
    label: 'Export Users',
    description: 'Allow exporting all users',
    entities: ['company', 'downline'],
  } satisfies PermissionDefinition,
  'user:import': {
    category: 'Users',
    label: 'Import Users',
    description: 'Allow bulk importing users',
    entities: ['company'],
  } satisfies PermissionDefinition,
  'user:login': {
    category: 'Users',
    label: 'Login as a User',
    description: 'Allow logging in as another user (aka Impersonation)',
    entities: ['company', 'downline'],
  } satisfies PermissionDefinition,
  'user:list': {
    category: 'Users',
    label: 'View Users',
    description: 'Allow viewing users',
    entities: ['company', 'downline'],
  } satisfies PermissionDefinition,
  'user:add': {
    category: 'Users',
    label: 'Add User',
    description: 'Allow creating a new user',
    entities: ['company', 'downline'],
  } satisfies PermissionDefinition,
  'user:manage': {
    category: 'Users',
    label: 'Manage User',
    description: 'Allow enabling/disabling the user and updating all user fields including the manager and external id',
    entities: ['company', 'downline'],
  } satisfies PermissionDefinition,
  'user:update': {
    category: 'Users',
    label: 'Update User',
    description: 'Allow updating a user',
    entities: ['company', 'downline', 'self'],
  } satisfies PermissionDefinition,
  'user:delete': {
    category: 'Users',
    label: 'Delete User',
    description: 'Allow permanently deleting a user',
    entities: [], // only for terros tech
  } satisfies PermissionDefinition,
  'admin:assign': {
    category: 'Users',
    label: 'Assign Admin',
    description: 'Allow assigning a user to an admin role',
    entities: ['company'],
  } satisfies PermissionDefinition,

  'webhook:manage': {
    category: 'Settings',
    label: 'Manage Webhooks',
    description: 'Allow managing webhooks',
    entities: ['company'],
  } satisfies PermissionDefinition,

  'game:list': {
    category: 'Settings',
    label: 'View All Games',
    description: 'Allow viewing all games',
    entities: ['company'],
  } satisfies PermissionDefinition,
  'game:save': {
    category: 'Settings',
    label: 'Save Games',
    description: 'Allow adding, editing, and hiding games',
    entities: ['company', 'downline'],
  } satisfies PermissionDefinition,
} as const

export const PERMISSION_TYPES = Object.keys(PERMISSION_DEFINITION) as PermissionType[]
