import { mutationUpdate, type UpdateHookResponse } from '../hooks'
import { useLocationCache } from '../location'
import { useTantalimMutation } from '../mutation'
import { API, type ContactData, Logger } from '@terros/common'

const logger = new Logger('useUpdateContact')

export function useUpdateContact(): UpdateHookResponse<ContactData, void> {
  logger.verbose('calling useUpdateContact')
  const { clearItem } = useLocationCache()
  const mutation = useTantalimMutation<API.ContactUpdateInput, API.ContactUpdateSuccess>({ url: '/contact/update' })

  async function update(contact: ContactData): Promise<void> {
    try {
      logger.verbose('starting update')
      await mutation.mutateAsync({ contact })
      logger.verbose('done with update')
      const { locationId } = contact
      clearItem(locationId)
    } catch (error) {
      throw API.toResponseError(error)
    }
  }

  return mutationUpdate(mutation, update)
}
