import { type GetHookResponse } from '../hooks'
import { teamKey } from '../keys'
import { useTantalimQuery } from '../query'
import { type API, HOUR, Logger, type TeamId } from '@terros/common'

const logger = new Logger('useTeam')

export function useTeam(teamId?: TeamId, enabled = true): GetHookResponse<API.TeamGetSuccess> {
  logger.verbose('calling useTeam', teamId)
  return useTantalimQuery<API.TeamGetInput, API.TeamGetSuccess, API.TeamGetSuccess>({
    url: '/team/get',
    queryKey: teamKey(teamId),
    input: {
      teamId: teamId ?? 'Team:EMPTY_TEAM',
    },
    convertResponse: (data) => data,
    options: {
      gcTime: HOUR,
      staleTime: HOUR,
      enabled: Boolean(teamId) && enabled,
    },
  })
}
