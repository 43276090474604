import { threadKey, threadListKey } from '../keys'
import { useQueryClient } from '@tanstack/react-query'
import { Logger, type MessageEntityId } from '@terros/common'

const logger = new Logger('useThreadCache')

// logger.level = 'verbose'

export function useThreadCache() {
  const queryClient = useQueryClient()
  return {
    clearItem: (targetId: MessageEntityId) => {
      logger.verbose('invalidateQueries threadKey', targetId)
      queryClient.invalidateQueries({ queryKey: threadKey(targetId) })
    },
    clearList: () => {
      logger.verbose('invalidateQueries threadListKey')
      queryClient.invalidateQueries({ queryKey: threadListKey() })
    },
  }
}
