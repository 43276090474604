import { useQueryClient } from '@tanstack/react-query'
import { Logger } from '@terros/common'

const logger = new Logger('useEmailCache')

logger.level = 'verbose'

export function useEmailCache() {
  const queryClient = useQueryClient()
  return {
    clearList: () => {
      logger.verbose('invalidateQueries emailListKey')
      queryClient.invalidateQueries({ queryKey: ['emails'] })
    },
  }
}
