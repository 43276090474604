import { serverRequest, useBackend } from '../api'
import { type API, type LocationData, Logger } from '@terros/common'
import { useState } from 'react'

const logger = new Logger('useLocation')
// logger.level = 'verbose'

type FinderState = {
  isLoading: boolean
  error?: string
  findLocation: (input: API.LocationFindInput) => Promise<LocationData | undefined>
}

export function useLocationFinder(): FinderState {
  const [isLoading, setLoading] = useState(false)
  const [error, setError] = useState<string | undefined>()
  const backend = useBackend()

  async function findLocation(input: API.LocationFindInput): Promise<LocationData | undefined> {
    try {
      setLoading(true)
      setError(undefined)
      const response = await serverRequest<API.LocationFindInput, API.LocationFindSuccess>({
        backend,
        path: '/location/find',
        input,
      })
      return response.location
    } catch (e) {
      setError((e as Error).message)
    } finally {
      setLoading(false)
    }
  }

  return { findLocation, isLoading, error }
}
