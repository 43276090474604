import { BLACK_BLURHASH } from '../avatar'
import { profileKey, userKey, userListKey } from '../keys'
import { useQueryClient } from '@tanstack/react-query'
import { type API, type CompanyId, type UserId, type UserProfileData, type UserData } from '@terros/common'

export function useUserCache() {
  const queryClient = useQueryClient()

  return {
    setProfile: (user: UserProfileData, userId?: UserId) => {
      queryClient.setQueryData<API.UserProfileSuccess>(profileKey(userId), (previous) => {
        if (!previous) return
        return {
          ...previous,
          user,
        }
      })
    },
    clearProfile: (userId?: UserId) => {
      queryClient.invalidateQueries({ queryKey: profileKey(userId) })
    },
    clearItem: (userId: UserId) => {
      queryClient.invalidateQueries({ queryKey: userKey(userId) })
    },
    clearList: (companyId?: CompanyId) => {
      queryClient.invalidateQueries({ queryKey: userListKey() })
    },
    getItem: (userId: UserId): UserData | undefined => {
      return queryClient.getQueryData<UserData>(userKey(userId))
    },
    updateAvatar: (userId: UserId | undefined) => {
      queryClient.setQueryData<API.UserProfileSuccess>(profileKey(userId), (existing) => {
        if (!existing) return
        return {
          ...existing,
          user: {
            ...existing.user,
            avatarUrl: existing.user.avatarUrl + '?v=' + Date.now(),
            avatarBlurhash: existing.user.avatarBlurhash ?? BLACK_BLURHASH,
          },
        }
      })
      queryClient.setQueryData<UserData>(userKey(userId), (existing) => {
        if (!existing) return
        return {
          ...existing,
          avatarUrl: existing.avatarUrl + '?v=' + Date.now(),
          avatarBlurhash: existing.avatarBlurhash ?? BLACK_BLURHASH,
        }
      })
    },
  }
}
