import { mutationRemove, type RemoveHookResponse } from '../hooks'
import { useTantalimMutation } from '../mutation'
import { useApiKeyCache } from './cache'
import { API, Logger } from '@terros/common'

const logger = new Logger('useApiKeys')

export function useRemoveApiKey(): RemoveHookResponse<API.ApiKeyRemoveInput> {
  logger.verbose('calling useRemoveApiKey')
  const mutation = useTantalimMutation<API.ApiKeyRemoveInput, API.ApiKeyRemoveSuccess>({
    url: '/apikey/remove',
  })
  const cache = useApiKeyCache()

  async function remove(input: API.ApiKeyRemoveInput): Promise<void> {
    try {
      await mutation.mutateAsync(input)
      if (!input.createdAt) return
      cache.removeItem(input.createdAt)
    } catch (error) {
      throw API.toResponseError(error)
    }
  }

  return mutationRemove(mutation, remove)
}
