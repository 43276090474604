import { mutationUpdate, type UpdateHookResponse } from '../hooks'
import { useLocationCache } from '../location'
import { useTantalimMutation } from '../mutation'
import { useAccountCache } from './cache'
import { type AccountData, API, Logger } from '@terros/common'

const logger = new Logger('useAccounts')

export function useUpdateAccount(): UpdateHookResponse<AccountData, void> {
  logger.verbose('calling useUpdateAccount')
  const { clearItem: clearLocationItem } = useLocationCache()
  const { updateItem } = useAccountCache()

  const mutation = useTantalimMutation<API.AccountUpdateInput, API.AccountUpdateSuccess>({
    url: '/account/update',
  })

  async function update(account: AccountData): Promise<void> {
    try {
      logger.verbose('starting update')
      const data = await mutation.mutateAsync({ account })
      logger.verbose('done with update')
      const { locationId } = account
      if (locationId) clearLocationItem(locationId)
      updateItem(data.account)
    } catch (error) {
      throw API.toResponseError(error)
    }
  }

  return mutationUpdate(mutation, update)
}
