import { mutationUpdate, type UpdateHookResponse } from '../hooks'
import { useTantalimMutation } from '../mutation'
import { useTaskCache } from '../task'
import { useEventCache } from './cache'
import { API, type CalendarEventData, Logger, CURRENT_VERSION } from '@terros/common'

const logger = new Logger('calendar-update')
export function useUpdateCalendarEvent(): UpdateHookResponse<API.CalendarEventUpdateInput['event'], CalendarEventData> {
  const { replaceItem } = useEventCache()
  const { clearList } = useTaskCache()
  const mutation = useTantalimMutation<API.CalendarEventUpdateInput, API.CalendarEventUpdateSuccess>({
    url: '/calendar/event/update',
  })

  async function update(event: API.CalendarEventUpdateInput['event']): Promise<CalendarEventData> {
    try {
      const output = await mutation.mutateAsync({ version: CURRENT_VERSION, event })
      replaceItem(output.event)
      clearList()
      logger.info('update succeeded', output.event)
      return output.event
    } catch (error) {
      throw API.toResponseError(error)
    }
  }

  return mutationUpdate(mutation, update)
}
