import { type AccountAddress, type AccountData } from './account'
import { type TinyAccountContact } from './accountContact'
import { type Address, type CountryCode, type TinyAddress } from './address'
import { type ContactData } from './contact'
import { type DispositionId } from './disposition'
import { type EvaluationData } from './evaluation'
import { type CalendarEventData } from './event'
import { isId } from './ids'
import { type NoteWithOwner } from './notes'
import { type PrescreenDecision } from './prescreen'
import { type ResidentData, type TinyResidentData } from './resident'

export type CanadaId = `Can.${string}`
export type AttomId = `Attom:${number}`

export type ExternalLocationId = AttomId | CanadaId

export type CustomLocationId = `LocNew:${string}` | `LocNew.${string}`
export type LegacyLocationId = `Loc:${string}` | `Loc.${string}`
export type TerrosLocationId = `TLoc:${string}` | `TLoc.${string}`
export type LocationId = AttomId | CustomLocationId | LegacyLocationId | TerrosLocationId | CanadaId

/** TODO: move isAttomLocationId, isCanadaLocationId, etc. to common */
export function isLocationId(val: unknown): val is LocationId {
  if (isId(val, 'Attom')) return true
  if (isId(val, 'Can')) return true
  if (isId(val, 'LocNew')) return true
  if (isId(val, 'Loc')) return true
  return isId(val, 'TLoc')
}

export type UnsavedLocation = Partial<Address> &
  Pick<Address, 'countrySubd' | 'line1' | 'locality' | 'postal1'> & {
    latlng: LatLng
  }

/**
 * This is a combination of global, company-specific, and user-specific data
 * for a single location or property
 */
export type LocationData = Address & {
  readonly locationId: LocationId
  /** an alternate identifier for external vendors such as attomid */
  altId?: string
  latlng: LatLng
  fields?: FieldData[]
  /** This is the primary point of contact */
  resident?: TinyResidentData
  /** Optional list of residents to choose the primary point of contact */
  residents?: ResidentData[]
  msa?: MetropolitanStatisticalArea
  propertyTypeId?: PropertyType
  notes?: NoteWithOwner[]
  disposition?: DispositionId
}

export type ExtendedLocationData = LocationData & {
  contacts?: ContactData[]
  account?: AccountData
  events?: CalendarEventData[]
  evaluations?: EvaluationData[]
}

export type TinyLocationData = TinyAddress &
  LatLng & {
    readonly locationId: LocationId
    accountContact?: TinyAccountContact
    resident?: TinyResidentData
    notes?: NoteWithOwner[]
  }

export type LatLng = {
  latitude: number
  longitude: number
}

export type FieldData = {
  field: string
  value: string
}

export type MetropolitanStatisticalArea = {
  name: string
  code: string
}

export type PropertyType = number | string

/** WARNING - Changing this value requires clearing the Geo cache from DynamoDB */
export const CELL_SIZE = 0.005

type Longitude = number
type Latitude = number
/** Geo200:Longitude,Latitude or Geo200:x,y */
export type Geo200Id = `Geo200:${Longitude},${Latitude}`

export function isGeo200Id(value: unknown): value is Geo200Id {
  if (typeof value !== 'string') return false
  return value.startsWith('Geo200:')
}

export type GeoIdParts = LatLng & {
  cellSize: number
}

export type WithLocationId = {
  locationId: LocationId
}

export type WithOptionalLocationId = Partial<WithLocationId>

type SunrunRepId = `SR${string}`
export type ExternalPrescreenInput = {
  salesRepId: SunrunRepId
  contact: ExternalPrescreenIndividual & ExternalPrescreenAddress
}

/**  Si: + (md5 27 char hash of locationId + resident firstName + resident lastName) */
export type PrescreenIndividualId = `Si:${string}`

export type ExternalPrescreenIndividual = {
  individualId: PrescreenIndividualId
  firstName: string
  lastName: string
  birthDate?: string
}

export type ExternalPrescreenAddress = {
  city: string
  state: string
  street: string
  zipCode: string
}

export type ExternalPrescreenResponse = {
  statusCode: number
  body: {
    individualId: PrescreenIndividualId
    decision?: PrescreenDecision
  }
}

export type ExternalAddress = Omit<AccountAddress, 'line2'> & {
  country: CountryCode
}

export type ExternalAddressWithLocationId = ExternalAddress & WithOptionalLocationId

export type SmallLocationData = Pick<
  LocationData,
  'locationId' | 'latlng' | 'line1' | 'line2' | 'countrySubd' | 'locality' | 'postal1' | 'residents'
>

export type LocationMatchReport = {
  /** The smaller the number, the closer match it is (0 is the closest) */
  closeness: number
  haversineDistance?: number
}

export type ExtendedLocationMatchReport = LocationMatchReport & {
  locationId: LocationId
  line1: string
  latlng: LatLng
  isHighConfidenceMatch?: boolean
  isMediumConfidenceMatch?: boolean
}
