export const MILLISECOND = 1
export const SECOND = 1000 * MILLISECOND
export const MINUTE = 60 * SECOND
export const HOUR = 60 * MINUTE
export const DAY = 24 * HOUR
export const WEEK = 7 * DAY
export const MONTH = 30 * DAY
export const YEAR = 365 * DAY

export function daysSince(time: number): number {
  return (Date.now() - time) / DAY
}

// We could get ALL the timezones, but we only a few in the US, so I'm just going to simplify this.
export const US_TIME_ZONES = new Set<string>([
  'America/Los_Angeles',
  'America/Denver',
  'America/Phoenix',
  'America/Chicago',
  'America/New_York',
])
