import { isNotDefined } from '../util'
import { type AuditProps } from './common'
import { isId } from './ids'
import { type TeamLevel } from './team'
import type { UserId } from './user'

/**
 * The company id is used to uniquely identify a client or company.
 */
export type CompanyId = `C:${string}` | `C.${string}`
export const ALL_COMPANIES: CompanyId = 'C:*'
export const TANTALIM_ID: CompanyId = 'C:tantalim'
export const GLOBALTEMPLATE_ID: CompanyId = 'C.template1'
export const SUNRUN_ID: CompanyId = 'C:7df4ea7b'
export const AMBIA_ID: CompanyId = 'C:4e858521'
export const SUNRUN_TEST_ID: CompanyId = 'C:bfc8a009'
export const WILDCATSOLAR_ID: CompanyId = 'C:a33bfbbc'
export const MAYERSOLAR_ID: CompanyId = 'C:165c71f1'
export const ELITEWINDOWS_ID: CompanyId = 'C:50edb984'
export const BLUEENERGY_ID: CompanyId = 'C.jI_3cwal'
export const ROSEMAXX_ID: CompanyId = 'C.jdCT2iZe'
export const SEP_ID: CompanyId = 'C:f16652c0'
export const TOPTIER_ID: CompanyId = 'C:50e3e575'
export const TOPTIER_ROOFING_ID: CompanyId = 'C.GyfWAJKg'
export const BRIGHTSPEED_ID: CompanyId = 'C.8fUM1Wbg'

export function isTantalim(company?: WithCompanyId | CompanyId): boolean {
  if (typeof company === 'object') {
    return isTantalim(company.companyId)
  }
  return company === TANTALIM_ID
}

export function isSunrun(company?: WithCompanyId | CompanyId): boolean {
  if (typeof company === 'object') {
    return isSunrun(company.companyId)
  }
  return company === SUNRUN_ID || company === SUNRUN_TEST_ID
}

export type WithCompanyId = {
  companyId: CompanyId
}

/** used in unit tests */
export function stubCompanyId(id?: string): CompanyId {
  return `C:${id ?? 'ID'}`
}

export function isCompanyId(val: unknown): val is CompanyId {
  return isId(val, 'C')
}

export const toCompanyId = (company: WithCompanyId): CompanyId => company.companyId

export type UnsavedCompany = {
  name: string
}

/** used in unit tests */
export function stubUnsavedCompany(data?: Partial<UnsavedCompany>): UnsavedCompany {
  return {
    name: 'Stub Company',
    ...data,
  }
}

export type CompanyState = 'active' | 'demo' | 'suspended'

export type CloserModel = 'setter' | 'dispatcher'

export interface CompanyData extends UnsavedCompany, AuditProps {
  readonly companyId: CompanyId
  state: CompanyState
  adminIds?: UserId[]
  /** @defaultValue flat organization  */
  highestTeamLevel?: TeamLevel
  lowestTeamLevel?: TeamLevel
  /** custom names for each level such as Office, Region */
  levelNames?: Record<string, string>
  avatarUrl?: string
  avatarBlurhash?: string
  uploadAvatarUrl?: string
  timeZone?: string
}

export type PartialCompanyData = Partial<CompanyData> & WithCompanyId

/** used in unit tests */
export function stubCompanyData(data?: Partial<CompanyData>): CompanyData {
  return {
    ...stubUnsavedCompany(),
    companyId: stubCompanyId(),
    state: 'demo',
    ...data,
  }
}

export type TinyCompany = Pick<CompanyData, 'companyId' | 'name' | 'state' | 'avatarUrl' | 'avatarBlurhash'>

export function toTinyCompany(data: CompanyData): TinyCompany {
  return {
    companyId: data.companyId,
    name: data.name,
    state: data.state,
    avatarUrl: data.avatarUrl,
    avatarBlurhash: data.avatarBlurhash,
  }
}

export function doesCompanyNeedEmailForCloseEvent(companyId: CompanyId): boolean {
  return [TOPTIER_ID, TOPTIER_ROOFING_ID].includes(companyId)
}

export function isFiberCompany(company?: WithCompanyId | CompanyId): boolean {
  if (isNotDefined(company)) return false
  if (typeof company === 'object') {
    return isFiberCompany(company.companyId)
  }
  return [BRIGHTSPEED_ID].includes(company)
}
