import { type AddHookResponse, mutationAdd } from '../hooks'
import { useTantalimMutation } from '../mutation'
import { useAreaCache } from './cache'
import { API, type AreaData, Logger, type UnsavedArea } from '@terros/common'
import { useRef, useCallback } from 'react'

const logger = new Logger('useAddArea')
// logger.level = 'verbose'

export function useAddArea(): AddHookResponse<UnsavedArea, AreaData> {
  logger.verbose('calling useAddArea')
  const cache = useAreaCache()

  const mutation = useTantalimMutation<API.AreaAddInput, API.AreaAddSuccess>({ url: '/area/add' })

  async function add(area: UnsavedArea): Promise<AreaData> {
    try {
      logger.verbose('saving', area)
      const output = await mutation.mutateAsync(area)
      cache.addArea(output.area)
      return output.area
    } catch (error) {
      throw API.toResponseError(error)
    }
  }

  return mutationAdd(mutation, add)
}

export function useAddAreaWithMutex(): AddHookResponse<UnsavedArea, AreaData> {
  const { add: _add, ...rest } = useAddArea()

  const pendingRequests = useRef<Map<string, Promise<AreaData>>>(new Map())

  const add = useCallback(
    async (area: UnsavedArea): Promise<AreaData> => {
      const requestKey = JSON.stringify({
        coordinates: area.coordinates,
      })

      const existingRequest = pendingRequests.current.get(requestKey)
      if (existingRequest) {
        return existingRequest
      }

      const requestPromise = (async () => {
        try {
          const result = await _add(area)
          return result
        } finally {
          pendingRequests.current.delete(requestKey)
        }
      })()

      pendingRequests.current.set(requestKey, requestPromise)

      return requestPromise
    },
    [_add]
  )

  return {
    ...rest,
    add,
  }
}
