export function isEmpty<T>(val: T | undefined): val is undefined {
  if (val === undefined) return true
  if (val === null) return true
  if (typeof val === 'string') {
    if (val.trim().length === 0) return true
    return false
  }
  if (typeof val === 'number') {
    if (Number.isNaN(val)) return true
    if (val === 0) return true
    return false
  }
  if (Array.isArray(val)) {
    return val.length === 0
  }
  if (typeof val === 'boolean') {
    return false
  }
  if (typeof val === 'object') {
    if (Object.keys(val).length > 0) return false
    try {
      return JSON.stringify(val) === '{}'
    } catch (e) {
      return false
    }
  }
  throw new Error(`isEmpty is not implemented for ${typeof val} with value ${val}`)
}

export function isNotEmpty<T>(val: T | undefined): val is T {
  return !isEmpty(val)
}

/** Useful predicates to reduce the amount of casting necessary when calling Array.filter */
export function isDefined<T>(argument: T | undefined | null): argument is T {
  return argument !== undefined && argument !== null
}

export function isNotDefined<T>(argument: T | undefined | null): argument is undefined | null {
  return !isDefined(argument)
}

export async function filterEmptyPromises<T>(promises: Promise<T | undefined>[]): Promise<T[]> {
  const list = await Promise.all(promises)
  return list.filter(isNotEmpty)
}

export function isEmptyAll(items: unknown[]): boolean {
  return items.every(isEmpty)
}
