import { type RoleId } from '../../role'
import type { DefaultRole } from '../../team'

export const DEFAULT_USER_ID: RoleId = 'Role.user'

export const DEFAULT_USER: DefaultRole = {
  roleId: DEFAULT_USER_ID,
  name: 'User',
  permissionOverrides: [
    { permissionType: 'accountStatus:list', scope: 'company' }, // show account states for my company
    { permissionType: 'account:list', scope: 'self' }, // list accounts for my self
    { permissionType: 'area:list', scope: 'self' }, // TODO - most companies want reps to see other reps areas, do we want this to be the default?
    { permissionType: 'attachment:list', scope: 'self' }, // for my own attachments
    { permissionType: 'attachment:manage', scope: 'self' }, // for my own attachments
    { permissionType: 'company:read', scope: 'company' }, // view my own company name and other settings
    { permissionType: 'contact:list', scope: 'downline' }, // list contacts for my downline TODO - this shouldn't be a default but added for certain teams
    { permissionType: 'contact:list', scope: 'self' }, // list contacts for my self
    { permissionType: 'contact:add', scope: 'self' }, // add contacts for my self
    { permissionType: 'contact:update', scope: 'self' }, // update contacts for my self
    { permissionType: 'contact:delete', scope: 'self' }, // delete contacts for my self
    { permissionType: 'disposition:list', scope: 'company' }, // list dispositions for my company and upline
    { permissionType: 'disposition:list', scope: 'self' }, // list dispositions for my self
    { permissionType: 'disposition:save', scope: 'self' }, // save dispositions for my self
    { permissionType: 'evaluation:add', scope: 'self' }, // add evaluation for my self
    { permissionType: 'evaluation:list', scope: 'self' }, // get evaluation for my self
    { permissionType: 'evaluation:delete', scope: 'self' }, // delete evaluation for my self
    { permissionType: 'event:read', scope: 'self' }, // read calendar events for my self
    { permissionType: 'event:save', scope: 'self' }, // save calendar events for my self
    { permissionType: 'event:delete', scope: 'self' }, // delete calendar events for my self
    { permissionType: 'event:outcome:list', scope: 'company' }, // list event outcomes for my company
    { permissionType: 'location:add', scope: 'company' }, // add custom property locations for my company
    { permissionType: 'location:list', scope: 'global' }, // list locations for my self
    { permissionType: 'message:read', scope: 'self' }, // read messages
    { permissionType: 'message:send', scope: 'company' }, // send messages to other users in my company
    { permissionType: 'message:send', scope: 'downline' }, // send messages to my downline
    { permissionType: 'message:archive', scope: 'self' }, // archive messages for myself
    { permissionType: 'plan:list', scope: 'self' }, // list plans for myself
    { permissionType: 'plan:update', scope: 'self' }, // update my own plans
    { permissionType: 'plan:add', scope: 'self' }, // add plans for myself
    { permissionType: 'ratio:list', scope: 'company' }, // list roles for company
    { permissionType: 'role:list', scope: 'company' }, // list roles for my company
    { permissionType: 'task:add', scope: 'self' }, // add tasks for myself
    { permissionType: 'task:update', scope: 'self' }, // update my own tasks
    { permissionType: 'task:list', scope: 'self' }, // list tasks for myself
    { permissionType: 'team:list', scope: 'company' }, // list teams for my company
    { permissionType: 'user:list', scope: 'company' }, // list users for my company
    { permissionType: 'user:update', scope: 'self' }, // update my own user
    { permissionType: 'game:list', scope: 'company' }, // list games for my company
  ],
}
