import { areaListKey, areaCanvassKey } from '../keys'
import { useQueryClient } from '@tanstack/react-query'
import { type AreaData, type AreaId, isEmpty, Logger } from '@terros/common'
import { useCallback, useMemo } from 'react'

const logger = new Logger('useAreaCache')

type Result = {
  clearList: VoidFunction
  clearSelf: VoidFunction
  addArea: (area: AreaData) => void
  updateArea: (area: AreaData) => void
  removeArea: (areaId: AreaId) => void
}

export function useAreaCache(): Result {
  const queryClient = useQueryClient()

  const clearList = useCallback((): void => {
    logger.verbose('invalidateQueries areaListKey')
    queryClient.invalidateQueries({ queryKey: areaListKey() })
  }, [])

  const clearSelf = useCallback((): void => {
    logger.verbose('invalidateQueries areaCanvassKey')
    queryClient.invalidateQueries({ queryKey: areaCanvassKey() })
  }, [])

  const addArea = useCallback((area: AreaData): void => {
    logger.verbose('updating cache', area)
    queryClient.setQueriesData<AreaData[]>({ queryKey: areaListKey() }, (prev) => [...(prev ?? []), area])
    clearSelf()
  }, [])

  const updateArea = useCallback((area: AreaData): void => {
    logger.verbose('updating cache', area)
    queryClient.setQueriesData<AreaData[]>({ queryKey: areaListKey() }, (previous) => {
      if (isEmpty(previous)) return [area]
      const { areaId } = area
      return previous.map((a) => (a.areaId === areaId ? area : a))
    })
    clearSelf()
  }, [])

  const removeArea = useCallback((areaId: AreaId): void => {
    logger.verbose('removing areaId from cache', areaId)
    queryClient.setQueriesData<AreaData[]>({ queryKey: areaListKey() }, (previous) => {
      if (isEmpty(previous)) return []
      return previous.filter((a) => a.areaId !== areaId)
    })
    clearSelf()
  }, [])

  return useMemo(
    () => ({
      clearList,
      clearSelf,
      addArea,
      updateArea,
      removeArea,
    }),
    [clearList, clearSelf, addArea, updateArea, removeArea]
  )
}
