import { type GetHookResponse } from '../hooks'
import { userSearchKey } from '../keys'
import { useTantalimQuery } from '../query'
import { type API, HOUR, Logger, type TinyUser, isNotEmpty } from '@terros/common'

const logger = new Logger('useSearchUsers')

export function useSearchUsers(input: API.UserSearchInput, enabled = true): GetHookResponse<TinyUser[]> {
  logger.verbose('calling useSearchUsers', input)
  return useTantalimQuery<API.UserSearchInput, API.UserSearchSuccess, TinyUser[]>({
    url: '/user/search',
    queryKey: userSearchKey(input),
    input,
    convertResponse: (data) => data.users,
    options: {
      gcTime: HOUR,
      staleTime: HOUR,
      enabled: isNotEmpty(input.query) && enabled,
    },
  })
}
