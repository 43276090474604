import { BLACK_BLURHASH } from '../avatar'
import { roleKey, roleListKey } from '../keys'
import { type RoleList } from '../types'
import { useQueryClient } from '@tanstack/react-query'
import { type API, Logger, type RoleId, type RoleData } from '@terros/common'

const logger = new Logger('useRoleCache')

// logger.level = 'verbose'

export function useRoleCache() {
  const queryClient = useQueryClient()

  const clearItem = (roleId: RoleId): void => {
    logger.verbose('invalidateQueries roleKey', roleId)
    queryClient.invalidateQueries({ queryKey: roleKey({ roleId }) })
  }

  return {
    clearItem,
    clearList: (props: API.RoleListInput = {}) => {
      logger.verbose('invalidateQueries roleListKey', props)
      queryClient.invalidateQueries({ queryKey: roleListKey(props) })
    },
    getItem: (roleId: RoleId): RoleData | undefined => {
      logger.verbose('get roleKey', roleId)
      return queryClient.getQueryData<RoleList>(roleListKey({}))?.find((t) => roleId === t.roleId)
    },
    updateAvatar: (roleId: RoleId): void => {
      queryClient.setQueriesData<API.RoleGetSuccess>({ queryKey: roleKey({ roleId }) }, (existing) => {
        if (!existing) return
        return {
          ...existing,
          role: {
            ...existing.role,
            avatarUrl: existing.role.avatarUrl + '?v=' + Date.now(),
            avatarBlurhash: existing.role.avatarBlurhash ?? BLACK_BLURHASH,
          },
        }
      })
    },
  }
}
