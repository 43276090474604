import { statsKey } from '../keys'
import { type QueryClient, useQueryClient } from '@tanstack/react-query'
import {
  type DispositionId,
  type LocationData,
  type LocationId,
  Logger,
  type TinyLocationData,
  type TinyAccountContact,
} from '@terros/common'

const logger = new Logger('contact-api')

type Props = {
  locationId: LocationId
  newLocation: LocationData
  disposition?: DispositionId
}

const replaceLocation = (queryClient: QueryClient, { locationId, newLocation, disposition }: Props): void => {
  queryClient.invalidateQueries({ queryKey: statsKey() })
  logger.verbose('getting placeholder for', locationId)
  {
    const allSectorCache = queryClient.getQueriesData<TinyLocationData[]>({
      queryKey: ['locations'],
    })
    allSectorCache.forEach(([key, data]) => {
      if (!data) return
      data.forEach((locationCache) => {
        if (locationCache.locationId === locationId) {
          queryClient.setQueryData<TinyLocationData[]>(key, (previous = []) => {
            return previous.map((l) => {
              const match = l.locationId === locationId
              return match ? toTinyLocation(newLocation, l, disposition) : l
            })
          })
        }
      })
    })
  }
}

export const useReplaceLocation = (): ((props: Props) => void) => {
  const queryClient = useQueryClient()
  return (props: Props) => {
    replaceLocation(queryClient, props)
  }
}

const invalidateLocationSector = (queryClient: QueryClient, locationId: LocationId): void => {
  queryClient.invalidateQueries({ queryKey: statsKey() })
  const allSectorCache = queryClient.getQueriesData<TinyLocationData[]>({
    queryKey: ['locations'],
  })
  allSectorCache.forEach(([key, data]) => {
    if (!data) return
    for (const locationCache of data) {
      if (locationCache.locationId === locationId) {
        queryClient.invalidateQueries({ queryKey: key })
      }
    }
  })
}

export const useInvalidateLocation = (): ((locationId: LocationId) => void) => {
  const queryClient = useQueryClient()
  return (locationId: LocationId): void => {
    invalidateLocationSector(queryClient, locationId)
  }
}

function toTinyLocation(l: LocationData, prev: TinyLocationData, disposition?: DispositionId): TinyLocationData {
  const accountContact: TinyAccountContact | undefined = toAccountContact(l, prev, disposition)
  return {
    ...l.latlng,
    locationId: l.locationId,
    address: l.line1,
    city: l.locality,
    state: l.countrySubd,
    zip: l.postal1,
    accountContact,
    resident: l.resident,
    notes: l.notes,
  }
}

function toAccountContact(l: LocationData, prev: TinyLocationData, disposition?: DispositionId): TinyAccountContact {
  return {
    locationId: l.locationId,
    user: 'self',
    disposition,
    statusId: prev.accountContact?.statusId,
  }
}
