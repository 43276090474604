import { mutationRemove, type RemoveHookResponse } from '../hooks'
import { useTantalimMutation } from '../mutation'
import { useEvaluationCache } from './cache'
import { API, Logger } from '@terros/common'

const logger = new Logger('useEvaluations')

export function useRemoveEvaluation(): RemoveHookResponse<API.EvaluationRemoveInput> {
  logger.verbose('calling useRemoveEvaluation')
  const cache = useEvaluationCache()
  const mutation = useTantalimMutation<API.EvaluationRemoveInput, API.EvaluationRemoveSuccess>({
    url: '/evaluation/remove',
  })

  async function remove(input: API.EvaluationRemoveInput): Promise<void> {
    try {
      await mutation.mutateAsync(input)
      cache.clearList()
    } catch (error) {
      throw API.toResponseError(error)
    }
  }

  return mutationRemove(mutation, remove)
}
