import { type UserId } from '@terros/common'
import { createContext, useContext } from 'react'

export type Auth = {
  getToken: () => Promise<string | undefined>
  isAuthenticated: boolean
}

export type Stage = 'prod' | 'dev' | string

export type BackendContextType = {
  getStage: () => Stage
  setStage: (stage: Stage) => void
  getImpersonating: () => UserId | undefined
  setImpersonating: (userId?: UserId) => void
  setAuth: (auth: Auth) => void
  getToken: () => Promise<string | undefined>
  isAuthenticated: () => boolean
}

export const BackendContext = createContext<BackendContextType | undefined>(undefined)

export function useBackend(): BackendContextType {
  const context = useContext(BackendContext)
  if (!context) throw new Error('useBackend must be used within a BackendProvider')
  return context
}
