import { duplicateAccountsKey } from '../keys'
import { useQueryClient } from '@tanstack/react-query'
import { Logger } from '@terros/common'

const logger = new Logger('useAccountDuplicateCache')

type Result = {
  clearList: VoidFunction
}

export function useAccountDuplicateCache(): Result {
  const queryClient = useQueryClient()
  return {
    clearList: () => {
      logger.verbose('clearList accountDuplicateCache')
      queryClient.invalidateQueries({ queryKey: duplicateAccountsKey() })
    },
  }
}
