export const SizePrimitives = {
  '0': 0,
  PX: 1,
  '0.5': 2,
  '1': 4,
  '1.5': 6,
  '2': 8,
  '2.5': 10,
  '3': 12,
  '4': 16,
  '5': 20,
  '6': 24,
  '7': 28,
  '8': 32,
  '9': 36,
  '10': 40,
  '11': 44,
  '12': 48,
  '16': 64,
  '20': 80,
  '24': 96,
  '32': 128,
  '40': 160,
  '60': 240,
} as const

export const Size = {
  gap: {
    basic: 24,
    list: 1,
  },
  padding: {
    basic: 16,
    list: 0,
  },
  radius: {
    none: 0,
    xs: 2,
    sm: 4,
    md: 6,
    lg: 8,
    xl: 12,
    '2xl': 16,
    '3xl': 24,
    full: 9999,
  },
  space: {
    none: 0,
    xs: 1,
    sm: 2,
    md: 4,
    lg: 6,
    xl: 8,
    '2xl': 12,
    '3xl': 16,
    '4xl': 24,
    '5xl': 32,
    '6xl': 48,
  },
} as const
