import { serverRequest, useBackend } from './api'
import { type GetHookResponse } from './hooks'
import { type QueryKey, useQuery, type UseQueryOptions } from '@tanstack/react-query'
import { Logger, type ResponseSuccess, type APIError, messageFromError } from '@terros/common'

const logger = new Logger('useTantalimQuery')
// logger.level = 'verbose'
// logger.disabled = false

type QueryInputs<Input, Success, Data> = {
  url: string
  queryKey: QueryKey
  input: Input
  convertResponse: (success: Success) => Data
  options?: Omit<UseQueryOptions<Data, APIError>, 'queryKey'>
}

export function useTantalimQuery<ApiInput, ApiSuccess extends ResponseSuccess, Data = ResponseSuccess>({
  url,
  queryKey,
  input,
  options,
  convertResponse,
}: QueryInputs<ApiInput, ApiSuccess, Data>): GetHookResponse<Data> {
  const backend = useBackend()
  async function queryFn(): Promise<Data> {
    const result = await serverRequest<ApiInput, ApiSuccess>({ backend, path: url, input })
    return convertResponse(result)
  }

  const query = useQuery<Data, APIError>({
    queryKey,
    queryFn,
    retry: false,
    ...options,
  })

  logger.verbose(
    `render useTantalimQuery: url=${url} key=${queryKey} loading=${query.isLoading}, fetching=${query.isFetching}, success=${query.isSuccess}`
  )

  const error = messageFromError(query.error)
  return { ...query, error }
}
