import { mutationRemove, type RemoveHookResponse } from '../hooks'
import { useTantalimMutation } from '../mutation'
import { useAreaCache } from './cache'
import { API, Logger } from '@terros/common'

const logger = new Logger('useRemoveArea')

// logger.level = 'timer'

export function useRemoveArea(): RemoveHookResponse<API.AreaRemoveInput> {
  logger.verbose('calling useRemoveArea')
  const cache = useAreaCache()
  const mutation = useTantalimMutation<API.AreaRemoveInput, API.AreaRemoveSuccess>({ url: '/area/remove' })

  async function remove(input: API.AreaRemoveInput): Promise<void> {
    try {
      await mutation.mutateAsync(input)
      cache.removeArea(input.areaId)
    } catch (error) {
      throw API.toResponseError(error)
    }
  }

  return mutationRemove(mutation, remove)
}
