import { interviewQuestionKey, interviewQuestionsListKey } from '../../keys'
import { useQueryClient } from '@tanstack/react-query'
import { InterviewQuestionData, InterviewQuestionId, API, Logger, sortInterviewQuestionsByOrder } from '@terros/common'

const logger = new Logger('useInterviewQuestionCache')

// logger.level = 'verbose'

export function useInterviewQuestionCache() {
  const queryClient = useQueryClient()
  return {
    replaceItem: (newIQData: InterviewQuestionData) => {
      logger.verbose('setQueryData interviewQuestion', newIQData.questionId)
      queryClient.setQueryData<InterviewQuestionData>(interviewQuestionKey(newIQData.questionId), newIQData)
      queryClient.setQueryData<InterviewQuestionData[]>(interviewQuestionsListKey({}), (oldData) => {
        if (!oldData) return
        const updatedData = oldData.map((q) => (q.questionId === newIQData.questionId ? newIQData : q))
        const sortedQuestions = sortInterviewQuestionsByOrder(updatedData)
        return sortedQuestions
      })
    },
    setList: (data: InterviewQuestionData[], props: API.InterviewQuestionListInput = {}) => {
      logger.verbose('setQueryData interviewQuestionsListKey')
      queryClient.setQueryData(interviewQuestionsListKey(props), data)
    },
    removeItem: (questionId: InterviewQuestionId) => {
      queryClient.invalidateQueries({ queryKey: interviewQuestionKey(questionId) })
      queryClient.setQueryData<InterviewQuestionData[]>(interviewQuestionsListKey({}), (oldData) => {
        if (!oldData) return
        const updatedData = oldData.filter((q) => q.questionId !== questionId)
        const sortedQuestions = sortInterviewQuestionsByOrder(updatedData)
        return sortedQuestions
      })
    },
    addItem: (questionData: InterviewQuestionData) => {
      queryClient.setQueryData<InterviewQuestionData>(interviewQuestionKey(questionData.questionId), questionData)
      queryClient.setQueryData<InterviewQuestionData[]>(interviewQuestionsListKey({}), (oldData) => {
        if (!oldData) return [questionData]
        const updatedData = [...oldData, questionData]
        const sortedQuestions = sortInterviewQuestionsByOrder(updatedData)
        return sortedQuestions
      })
    },
  }
}
