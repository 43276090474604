import { serverRequest, useBackend, type BackendContextType } from '../api'
import { hasUserPermission } from '../data'
import { type GetHookResponse } from '../hooks'
import { profileKey, userKey, userListKey } from '../keys'
import { useTantalimQuery } from '../query'
import { readItem, storeItem } from '../storage'
import { type UserList } from '../types'
import { useQueryClient } from '@tanstack/react-query'
import {
  type API,
  type CompanyData,
  HOUR,
  isEmpty,
  isTantalim,
  Logger,
  type PermissionType,
  type UserData,
  type UserId,
  type UserProfileData,
  TANTALIM_ID,
  isRealUserId,
} from '@terros/common'
import { useEffect, useState, useRef } from 'react'

const logger = new Logger('useUser')
// logger.level = 'verbose'

// logger.disabled = false

function getPlaceholderData(userId?: UserId): UserData | undefined {
  if (isEmpty(userId)) return
  return {
    userId,
    email: 'support@terros.com',
    firstName: 'System',
    lastName: 'Bot',
    avatarUrl: 'https://avatars.terros.com/U:system',
    avatarBlurhash: 'KVQvwRj[~qayfQay?bfQM{',
    companyId: TANTALIM_ID,
  }
}

export function useUser(userId?: UserId): GetHookResponse<UserData> {
  logger.verbose('calling useUser', userId)
  const queryClient = useQueryClient()
  const real = isRealUserId(userId)
  const placeholderData = real
    ? queryClient.getQueryData<UserList>(userListKey())?.find((c) => userId === c.userId)
    : getPlaceholderData(userId)

  return useTantalimQuery<API.UserGetInput, API.UserGetSuccess, UserData>({
    url: '/user/get',
    queryKey: userKey(userId),
    input: {
      userId,
    },
    convertResponse: (data) => data.user,
    options: {
      enabled: real,
      gcTime: Infinity,
      staleTime: HOUR,
      placeholderData,
    },
  })
}

export type ProfileState = GetHookResponse<void> & {
  user?: UserProfileData
  company?: CompanyData
  /** checks for permission on any team */
  hasPermission: (permission: PermissionType) => boolean
  isTantalim: boolean
  isAdmin: boolean
}

/**
 * return UserProfileData, which includes all data for user and is useful for managers too
 */
const PROFILE_STORAGE_KEY = 'user_profile'

export function useProfile(userId?: UserId | undefined, enabled?: boolean): ProfileState {
  logger.verbose('calling useProfile', userId)
  const backend = useBackend()
  const loaded = useRef(false)
  const [storedProfile, setStoredProfile] = useState<API.UserProfileSuccess | undefined>()

  useEffect(() => {
    async function loadProfileFromStorage() {
      if (!userId && loaded.current && backend.getStage() === 'prod') {
        const cachedProfile = await readItem<API.UserProfileSuccess>(PROFILE_STORAGE_KEY)
        setStoredProfile(cachedProfile)
        loaded.current = true
      }
    }

    loadProfileFromStorage()
  }, [userId])

  const query = useTantalimQuery<API.UserProfileInput, API.UserProfileSuccess, API.UserProfileSuccess>({
    url: '/user/profile',
    queryKey: profileKey(userId),
    input: {
      userId,
    },
    convertResponse: (data) => data,
    options: {
      enabled,
      gcTime: Infinity,
      staleTime: HOUR,
    },
  })

  useEffect(() => {
    async function storeProfile() {
      if (
        !userId &&
        data?.user &&
        data.user.updatedAt !== storedProfile?.user.updatedAt &&
        !backend.getImpersonating()
      ) {
        logger.verbose('Storing profile in asyncstorage')
        await storeItem(PROFILE_STORAGE_KEY, data)
        setStoredProfile(data)
      }
    }

    storeProfile()
  }, [query.data, storedProfile, userId])

  logger.verbose('query.data', query.data)

  const isTerros = isTantalim(query.data?.company)

  function hasPermission(permission: PermissionType): boolean {
    const dataToUse = query.data || storedProfile
    if (!dataToUse) {
      return false
    }
    return hasUserPermission(dataToUse.user, permission, isTerros)
  }

  const dataToUse = query.data || storedProfile
  const isAdminValue = hasAdminRole(dataToUse)
  const cachedResponse = storedProfile
    ? {
        isLoading: false,
        isSuccess: true,
        placeholderData: true,
      }
    : {}
  const { data, ...queryWithData } = query
  return {
    ...queryWithData,
    ...dataToUse,
    ...cachedResponse,
    data: undefined,
    hasPermission,
    isTantalim: isTerros,
    isAdmin: isAdminValue,
  }
}

export async function getProfile(backend: BackendContextType, userId: UserId): Promise<API.UserProfileSuccess> {
  return serverRequest<API.UserProfileInput, API.UserProfileSuccess>({
    backend,
    path: '/user/profile',
    input: {
      userId,
    },
  })
}

const hasAdminRole = (profile: API.UserProfileSuccess | undefined): boolean => {
  return Boolean(profile?.user.roles?.find((role) => role.roleType === 'admin'))
}
