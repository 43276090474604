import { ratioKey, ratioListKey } from '../keys'
import { useQueryClient } from '@tanstack/react-query'
import { EntityId, Logger, RatioId, type RatioData } from '@terros/common'

const logger = new Logger('usePlanCache')

export const useRatioCache = () => {
  const queryClient = useQueryClient()

  return {
    updateItem: (newRatio: RatioData) => {
      logger.verbose('addItem setQueryData ratioListKey', newRatio)
      queryClient.setQueryData<RatioData>(ratioKey(newRatio.ratioId), newRatio)
      queryClient.setQueriesData<RatioData[]>({ queryKey: ratioListKey({ scopeId: newRatio.scopeId }) }, (prev) => {
        if (!prev) return [newRatio]
        const arr = prev.filter((ratio) => ratio.ratioId !== newRatio.ratioId)
        return [...arr, newRatio]
      })
    },
    clearItem: (ratioId: RatioId) => {
      logger.verbose('removeQueries ratioKey', ratioId)
      queryClient.setQueriesData<RatioData[]>({ queryKey: ['ratios'] }, (prev) => {
        if (!prev) return
        return prev.filter((ratio) => ratio.ratioId !== ratioId)
      })
    },
    clearList: (scopeId: EntityId) => {
      logger.verbose('invalidateQueries ratioListKey')
      queryClient.invalidateQueries({ queryKey: ratioListKey({ scopeId }) })
    },
  }
}
