import { mutationUpdate, type UpdateHookResponse } from '../hooks'
import { useTantalimMutation } from '../mutation'
import { useAreaCache } from './cache'
import { API, type AreaData, Logger } from '@terros/common'

const logger = new Logger('useUpdateArea')

export function useUpdateArea(): UpdateHookResponse<AreaData> {
  logger.verbose('calling useUpdateArea')
  const cache = useAreaCache()

  const mutation = useTantalimMutation<API.AreaUpdateInput, API.AreaUpdateSuccess>({ url: '/area/update' })

  async function update(unsaved: AreaData): Promise<AreaData> {
    try {
      logger.verbose('starting update')
      const { area } = await mutation.mutateAsync({ area: unsaved })
      cache.updateArea(area)
      cache.clearSelf()
      logger.verbose('done with update')
      return area
    } catch (error) {
      throw API.toResponseError(error)
    }
  }

  return mutationUpdate(mutation, update)
}
