import { type AddHookResponse, mutationAdd } from '../hooks'
import { locationsKey } from '../keys'
import { useTantalimMutation } from '../mutation'
import { getSector } from './util'
import { useQueryClient } from '@tanstack/react-query'
import { API, type LocationData, Logger, type UnsavedLocation } from '@terros/common'

const logger = new Logger('locations/add')
logger.level = 'verbose'

export function useAddLocation(): AddHookResponse<UnsavedLocation, LocationData> {
  const mutation = useTantalimMutation<API.LocationAddInput, API.LocationGetSuccess>({ url: '/location/add' })
  const queryClient = useQueryClient()

  async function add(unsavedLocation: UnsavedLocation): Promise<LocationData> {
    try {
      const output = await mutation.mutateAsync(unsavedLocation)
      invalidateSectorCache(output.location)
      return output.location
    } catch (error) {
      throw API.toResponseError(error)
    }
  }

  function invalidateSectorCache(location: LocationData): void {
    const sector = getSector(location.latlng)
    const queryKey = locationsKey(sector)
    queryClient.invalidateQueries({ queryKey })
  }

  return mutationAdd(mutation, add)
}
