import { TantalimMutationResponse } from './mutation'
import { isNotEmpty, messageFromError } from '@terros/common'

// TODO merge with query.ts and move to a new folder, either api or query or something

export type GetHookResponse<Output> = QueryResponseWithoutData & {
  data: Output | undefined
}

export type QueryResponseWithoutData = WithError & {
  isLoading: boolean
  isFetching: boolean
  isSuccess: boolean
  isPlaceholderData: boolean
  refetch: VoidFunction
}

export type AddHookResponse<Input, Output> = WithError & {
  isAdding: boolean
  add: ServerRequest<Input, Output>
}

type ServerRequest<Input, Output> = (input: Input) => Promise<Output>

export type UpdateHookResponse<Input, Output = Input> = WithError & {
  isUpdating: boolean
  update: ServerRequest<Input, Output>
}

export type RemoveHookResponse<DataType> = WithError & {
  isRemoving: boolean
  remove: ServerRequest<DataType, void>
}

export type WithError = {
  error?: string
}

export type WithEnabled = {
  enabled?: boolean
}

export function mutationAdd<ApiInput, ApiOutput, DataInput, DataOutput>(
  mutation: TantalimMutationResponse<ApiInput, ApiOutput>,
  add: ServerRequest<DataInput, DataOutput>
): AddHookResponse<DataInput, DataOutput> {
  return { add, isAdding: mutation.isPending, error: messageFromError(mutation.error) }
}

export function mutationUpdate<ApiInput, ApiOutput, DataInput, DataOutput>(
  mutation: TantalimMutationResponse<ApiInput, ApiOutput>,
  update: ServerRequest<DataInput, DataOutput>
): UpdateHookResponse<DataInput, DataOutput> {
  return { update, isUpdating: mutation.isPending, error: messageFromError(mutation.error) }
}

export function mutationRemove<ApiInput, ApiOutput>(
  mutation: TantalimMutationResponse<ApiInput, ApiOutput>,
  remove: ServerRequest<ApiInput, void>
): RemoveHookResponse<ApiInput> {
  return { remove, isRemoving: mutation.isPending, error: messageFromError(mutation.error) }
}

export function mergeGetHookResponses<T>(responses: QueryResponseWithoutData[]): QueryResponseWithoutData {
  const reduceOr = (a: boolean, b: boolean) => a || b
  return {
    isFetching: responses.map((r) => r.isFetching).reduce(reduceOr, false),
    isLoading: responses.map((r) => r.isLoading).reduce(reduceOr, false),
    isPlaceholderData: responses.map((r) => r.isPlaceholderData).reduce(reduceOr, false),
    isSuccess: responses.map((r) => r.isSuccess).reduce(reduceOr, false),
    refetch: () => {
      responses.forEach((r) => {
        r.refetch()
      })
    },
    error: responses.map((r) => r.error).find(isNotEmpty),
  }
}
