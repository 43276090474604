import { mutationUpdate, type UpdateHookResponse } from '../hooks'
import { useTantalimMutation } from '../mutation'
import { useRoleCache } from './cache'
import { API, Logger, type RoleData } from '@terros/common'

const logger = new Logger('useUpdateRole')

export function useUpdateRole(): UpdateHookResponse<RoleData, RoleData> {
  logger.verbose('calling useUpdateRole')
  const cache = useRoleCache()

  const mutation = useTantalimMutation<API.RoleUpdateInput, API.RoleUpdateSuccess>({ url: '/role/update' })

  async function update(role: RoleData): Promise<RoleData> {
    try {
      logger.verbose('starting update')
      const response = await mutation.mutateAsync({ role })
      if (response.role) {
        cache.clearItem(role.roleId)
        cache.clearList()
      }
      logger.verbose('done with update')
      return response.role
    } catch (error) {
      throw API.toResponseError(error)
    }
  }

  return mutationUpdate(mutation, update)
}
