import { featureKey } from '../keys'
import { useTantalimQuery } from '../query'
import { type API, type FeatureId, isNotEmpty, MINUTE, MONTH, Logger } from '@terros/common'

export type FeatureState = {
  isLoading: boolean
  isEnabled: boolean
}

export type FeatureCheckInput = {
  featureId: FeatureId
  placeholder?: boolean
}

const logger = new Logger('useFeature')

export function useFeature(input: FeatureCheckInput): FeatureState {
  const response = useTantalimQuery<API.FeatureCheckInput, API.FeatureCheckSuccess, boolean>({
    url: '/feature/check',
    queryKey: featureKey(input.featureId),
    input,
    convertResponse: (data) => data.enabled,
    options: {
      enabled: isNotEmpty(input.featureId),
      gcTime: MINUTE * 15,
      staleTime: MONTH,
    },
  })

  if (response.data !== undefined) {
    return {
      isLoading: response.isLoading,
      isEnabled: response.data,
    }
  }

  if (!response.isLoading) {
    logger.warn(`${input.featureId} is missing. Make sure it's defined. ${response.error}`)
  }

  return {
    isLoading: response.isLoading,
    isEnabled: input.placeholder ?? false,
  }
}
