import { mutationUpdate, type UpdateHookResponse } from '../hooks'
import { useTantalimMutation } from '../mutation'
import { useTaskCache } from '../task'
import { useTeamCache } from '../team'
import { useVideoCache } from '../video/cache'
import { useUserCache } from './cache'
import { useProfile } from './get'
import { type API, Logger, type UserProfileData } from '@terros/common'

const logger = new Logger('useUpdateUser')

//logger.level = 'verbose'

export function useUpdateUser(): UpdateHookResponse<Partial<UserProfileData>, UserProfileData> {
  logger.verbose('calling useUpdateUser')
  const mutation = useTantalimMutation<API.UserUpdateInput, API.UserUpdateSuccess>({ url: '/user/update' })
  const cache = useUserCache()
  const taskCache = useTaskCache()
  const teamCache = useTeamCache()
  const profile = useProfile()
  const videoCache = useVideoCache()

  async function update(user: Partial<UserProfileData>): Promise<UserProfileData> {
    const response = await mutation.mutateAsync({ user })
    logger.verbose('saved user', response.user)
    updateCache(response.user)
    return response.user
  }

  function updateCache(user: UserProfileData): void {
    const { userId } = user
    cache.clearItem(userId)
    cache.clearProfile(userId)
    cache.clearList()
    cache.clearList(user.companyId)
    taskCache.clearList()
    teamCache.replaceUser(user)
    videoCache.replaceProfileItem(user)
    if (profile.user?.userId === user.userId) {
      cache.clearProfile()
    }
  }

  return mutationUpdate(mutation, update)
}
