import { mutationUpdate, type UpdateHookResponse } from '../hooks'
import { useTantalimMutation } from '../mutation'
import { usePreferenceCache } from './cache'
import { API, Logger, type Preference, type Preferences, type EntityId } from '@terros/common'

const logger = new Logger('useUpdatePreference')

export function useUpdatePreference<T extends Preference>(
  preference: T,
  targetId?: EntityId
): UpdateHookResponse<Preferences[T]> {
  const { replaceItem } = usePreferenceCache()
  const mutation = useTantalimMutation<API.PreferenceUpdateInput<T>, API.PreferenceUpdateSuccess<T>>({
    url: '/preference/update',
  })

  async function update(value: Preferences[T]): Promise<Preferences[T]> {
    try {
      const output = await mutation.mutateAsync({ preference, value, targetId })
      replaceItem(preference, output.value, targetId)
      logger.info('update succeeded', preference, output.value)
      return output.value
    } catch (error) {
      throw API.toResponseError(error)
    }
  }

  return mutationUpdate(mutation, update)
}
