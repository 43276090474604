import { type RoleId } from '../../role'
import type { DefaultRole } from '../../team'

export const DEFAULT_TEAM_MANAGER_ID: RoleId = 'Role.manager'

export const DEFAULT_TEAM_MANAGER: DefaultRole = {
  roleId: DEFAULT_TEAM_MANAGER_ID,
  name: 'Manager',
  permissionOverrides: [
    { permissionType: 'account:list', scope: 'downline' }, // view accounts for my downline
    { permissionType: 'area:add', scope: 'downline' }, // add areas for teams and users in my downline
    { permissionType: 'area:list', scope: 'downline' }, // list areas for teams and users in my downline
    { permissionType: 'attachment:list', scope: 'downline' }, // list attachments for my downline
    { permissionType: 'attachment:manage', scope: 'downline' }, // manage attachments for my downline
    { permissionType: 'contact:list', scope: 'downline' }, // list contacts for my downline
    { permissionType: 'contact:coach', scope: 'downline' }, // receive tasks to coach anyone in your downline
    { permissionType: 'disposition:list', scope: 'downline' }, // able to view dispositions created by users in my downline
    { permissionType: 'disposition:save', scope: 'downline' }, // save dispositions for my team and downline users
    { permissionType: 'evaluation:add', scope: 'downline' }, // able to record evaluation for any user in my downline
    { permissionType: 'evaluation:delete', scope: 'downline' }, // delete evaluation for my downline
    { permissionType: 'evaluation:list', scope: 'downline' }, // get evaluation for my downline
    { permissionType: 'evaluation:manage', scope: 'downline' }, // update evaluation for my downline
    { permissionType: 'event:read', scope: 'downline' }, // list events for any user in my downline
    { permissionType: 'event:manage', scope: 'downline' }, // manage events for any user in my downline
    { permissionType: 'event:save', scope: 'downline' }, // save events for any user in my downline
    { permissionType: 'event:delete', scope: 'downline' }, // delete events for my downline
    { permissionType: 'availability:list', scope: 'downline' }, // list closer availability for any user in my downline
    { permissionType: 'message:send', scope: 'downline' }, // send messages to my downline
    { permissionType: 'message:send:group', scope: 'downline' }, // send messages to groups in my downline
    { permissionType: 'plan:list', scope: 'downline' }, // list plans for team and downline
    { permissionType: 'plan:add', scope: 'downline' }, // add plans for team and downline
    { permissionType: 'plan:update', scope: 'downline' }, // update plans for team and users in my downline
    { permissionType: 'plan:manage', scope: 'downline' }, // manage and approve plans for team and downline
    { permissionType: 'team:add', scope: 'downline' }, // add sub teams for my downline
    { permissionType: 'team:update', scope: 'downline' }, // update my own team or downline
    { permissionType: 'team:list', scope: 'downline' }, // list teams for my downline
    { permissionType: 'task:list', scope: 'downline' }, // list tasks for my downline
    { permissionType: 'task:add', scope: 'downline' }, // add tasks for my downline users
    { permissionType: 'task:update', scope: 'downline' }, // update or archive tasks for any user in my downline
    // TODO some companies don't want managers to add users
    { permissionType: 'user:add', scope: 'downline' }, // add users for my downline
    { permissionType: 'user:export', scope: 'downline' }, // export users for my downline
    { permissionType: 'user:manage', scope: 'downline' }, // update users in my downline
    { permissionType: 'user:update', scope: 'downline' }, // update users in my downline
    { permissionType: 'game:save', scope: 'downline' }, // save games for my downline
  ],
}
