import { type UpdateHookResponse, mutationUpdate } from '../hooks'
import { useTantalimMutation } from '../mutation'
import { useAccountStatusCache } from './cache'
import { type AccountStatusData, API, Logger } from '@terros/common'

const logger = new Logger('useUpdateAccountStatus')
// logger.level = 'verbose'

export function useUpdateAccountStatus(): UpdateHookResponse<AccountStatusData> {
  const { replaceItem } = useAccountStatusCache()
  const mutation = useTantalimMutation<API.AccountStatusUpdateInput, API.AccountStatusUpdateSuccess>({
    url: '/account/status/update',
  })

  async function update(unsaved: AccountStatusData): Promise<AccountStatusData> {
    try {
      const { status } = await mutation.mutateAsync({ status: unsaved })
      logger.verbose('update succeeded', status)
      replaceItem(status)
      return status
    } catch (error) {
      throw API.toResponseError(error)
    }
  }

  return mutationUpdate(mutation, update)
}
