import { dispositionKey, dispositionsKey } from '../keys'
import { useQueryClient } from '@tanstack/react-query'
import { type DispositionData, Logger, type DispositionId } from '@terros/common'

const logger = new Logger('useDispositionCache')

const sortByOrder = (a: DispositionData, b: DispositionData): number => a.order - b.order

export const useDispositionCache = () => {
  const queryClient = useQueryClient()
  return {
    addItem: (dispositionData: DispositionData) => {
      logger.verbose(`addItem disposition ${dispositionData.dispositionId}`)
      queryClient.setQueryData(dispositionKey(dispositionData.dispositionId), dispositionData)
    },
    appendItem: (dispositionData: DispositionData) => {
      logger.verbose(`appendItem disposition ${dispositionData.dispositionId}`)
      queryClient.setQueryData(dispositionsKey(), (old: DispositionData[] | undefined) => {
        const previous = old || []
        const modified = [...previous, dispositionData]
        modified.sort(sortByOrder)
        return modified
      })
    },
    removeItem: (dispositionId: DispositionId) => {
      logger.verbose(`removeItem disposition ${dispositionId}`)
      queryClient.setQueryData<DispositionData[]>(dispositionsKey(), (previous) => {
        if (!previous) return
        const modified = previous.filter((d) => d.dispositionId !== dispositionId)
        modified.sort(sortByOrder)
        return modified
      })
    },
    replaceItem: (dispositionData: DispositionData) => {
      logger.verbose(`replaceItem disposition ${dispositionData.dispositionId}`)
      queryClient.setQueryData(dispositionKey(dispositionData.dispositionId), dispositionData)
      queryClient.setQueryData(dispositionsKey(), (old: DispositionData[] | undefined) => {
        const previous = old || []
        const modified = previous.map((d) => (d.dispositionId === dispositionData.dispositionId ? dispositionData : d))
        modified.sort(sortByOrder)
        return modified
      })
    },
    setList: (data: DispositionData[]) => {
      logger.verbose('setList dispositions')
      queryClient.setQueryData(dispositionsKey(), data)
    },
    clearList: () => {
      logger.verbose('invalidateQueries dispositionListKey')
      queryClient.invalidateQueries({ queryKey: dispositionsKey() })
    },
  }
}
