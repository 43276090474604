import { BLACK_BLURHASH } from '../avatar'
import { companyKey, companyListKey } from '../keys'
import { type CompanyList } from '../types'
import { useQueryClient } from '@tanstack/react-query'
import { type CompanyId, Logger, type TinyCompany, type CompanyData } from '@terros/common'

const logger = new Logger('useCompanyCache')

// logger.level = 'verbose'

export function useCompanyCache() {
  const queryClient = useQueryClient()
  return {
    clearItem: (companyId: CompanyId) => {
      logger.verbose('invalidateQueries companyKey', companyId)
      queryClient.invalidateQueries({ queryKey: companyKey(companyId) })
    },
    clearList: () => {
      logger.verbose('invalidateQueries companyListKey')
      queryClient.invalidateQueries({ queryKey: companyListKey() })
    },
    getItem: (companyId?: CompanyId): TinyCompany | undefined => {
      if (!companyId) return
      logger.verbose('get teamKey', companyId)
      return queryClient.getQueryData<CompanyList>(companyListKey())?.find((t) => companyId === t.companyId)
    },
    updateAvatar: (companyId: CompanyId): void => {
      queryClient.setQueryData<CompanyData>(companyKey(companyId), (existing) => {
        if (!existing) return
        return {
          ...existing,
          avatarUrl: existing.avatarUrl + '?v=' + Date.now(),
          avatarBlurhash: existing.avatarBlurhash ?? BLACK_BLURHASH,
        }
      })
    },
  }
}
