import { mutationUpdate, type UpdateHookResponse } from '../hooks'
import { useTantalimMutation } from '../mutation'
import { useTeamCache } from './cache'
import { API, Logger, type TeamData, type PartialTeamData } from '@terros/common'

const logger = new Logger('useTeams')

export function useUpdateTeam(): UpdateHookResponse<PartialTeamData, TeamData> {
  logger.verbose('calling useUpdateTeam')
  const cache = useTeamCache()

  const mutation = useTantalimMutation<API.TeamUpdateInput, API.TeamUpdateSuccess>({ url: '/team/update' })

  async function update(team: PartialTeamData): Promise<TeamData> {
    try {
      logger.verbose('starting update')
      const response = await mutation.mutateAsync({ team })
      if (response.team) {
        cache.clearItem(team.teamId)
        cache.clearList()
      }
      logger.verbose('done with update')
      return response.team
    } catch (error) {
      throw API.toResponseError(error)
    }
  }

  return mutationUpdate(mutation, update)
}
