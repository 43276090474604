import { mutationRemove, RemoveHookResponse } from '../../hooks'
import { useTantalimMutation } from '../../mutation'
import { useInterviewQuestionCache } from './cache'
import { API, Logger } from '@terros/common'

const logger = new Logger('useRemoveInterviewQuestion')

export function useRemoveInterviewQuestion(): RemoveHookResponse<API.InterviewQuestionRemoveInput> {
  logger.verbose('calling useRemoveInterviewQuestion')
  const cache = useInterviewQuestionCache()
  const mutation = useTantalimMutation<API.InterviewQuestionRemoveInput, API.InterviewQuestionRemoveSuccess>({
    url: '/interview/question/remove',
  })

  async function remove(input: API.InterviewQuestionRemoveInput): Promise<void> {
    try {
      await mutation.mutateAsync(input)
      cache.removeItem(input.questionId)
    } catch (error) {
      throw API.toResponseError(error)
    }
  }

  return mutationRemove(mutation, remove)
}
