import { type GetHookResponse } from '../hooks'
import { interviewKey } from '../keys'
import { useTantalimQuery } from '../query'
import { type API, type InterviewData, type InterviewId, Logger } from '@terros/common'

const logger = new Logger('useInterview')

export function useInterview(interviewId: InterviewId): GetHookResponse<InterviewData> {
  logger.verbose('calling useInterview', interviewId)
  return useTantalimQuery<API.InterviewGetInput, API.InterviewGetSuccess, InterviewData>({
    url: '/interview/get',
    queryKey: interviewKey(interviewId),
    input: {
      interviewId,
    },
    convertResponse: (data) => data.interview,
  })
}
