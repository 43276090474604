import { preferenceKey } from '../keys'
import { useQueryClient } from '@tanstack/react-query'
import { Logger, type Preference, type Preferences, type EntityId, type API } from '@terros/common'

const logger = new Logger('usePreferenceCache')

// logger.level = 'verbose'

export function usePreferenceCache() {
  const queryClient = useQueryClient()
  return {
    replaceItem: <T extends Preference>(preference: T, value: Preferences[T], targetId?: EntityId) => {
      logger.verbose('replaceItem', preference, value)
      queryClient.setQueryData<API.PreferenceGetSuccess<T>>(preferenceKey(preference, targetId), (existing) => {
        if (!existing) return

        return {
          ...existing,
          current: value,
        }
      })
    },
  }
}
