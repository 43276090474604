import { type GetHookResponse } from '../hooks'
import { interviewReportKey } from '../keys'
import { useTantalimQuery } from '../query'
import { type API, Logger, type TeamInterviewReport } from '@terros/common'

const logger = new Logger('useInterviewReport')

export function useInterviewReport(): GetHookResponse<TeamInterviewReport[]> {
  logger.verbose('calling useInterviewReport')
  return useTantalimQuery<API.InterviewReportInput, API.InterviewReportSuccess, TeamInterviewReport[]>({
    url: '/interview/report',
    queryKey: interviewReportKey(),
    input: {},
    convertResponse: (data) => data.teams,
  })
}
