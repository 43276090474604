import { mutationRemove, type RemoveHookResponse } from '../hooks'
import { useTantalimMutation } from '../mutation'
import { useCompanyCache } from './cache'
import { API, Logger } from '@terros/common'

const logger = new Logger('useCompanies')

export function useRemoveCompany(): RemoveHookResponse<API.CompanyRemoveInput> {
  logger.verbose('calling useRemoveCompany')
  const cache = useCompanyCache()
  const mutation = useTantalimMutation<API.CompanyRemoveInput, API.CompanyRemoveSuccess>({ url: '/company/remove' })

  async function remove(input: API.CompanyRemoveInput): Promise<void> {
    try {
      await mutation.mutateAsync(input)
      cache.clearList()
    } catch (error) {
      throw API.toResponseError(error)
    }
  }

  return mutationRemove(mutation, remove)
}
