import { useReplaceLocation } from '../contact'
import { type AddHookResponse, mutationAdd } from '../hooks'
import { useLocationCache } from '../location'
import { useTantalimMutation } from '../mutation'
import { API, Logger, type ExtendedLocationData } from '@terros/common'

const logger = new Logger('disposition')
logger.level = 'verbose'

export function useAddLocationDisposition(
  confirm: (error: string, message: string | undefined) => Promise<void>
): AddHookResponse<API.LocationDispositionInput, ExtendedLocationData> {
  const replaceLocation = useReplaceLocation()
  const { replaceItem } = useLocationCache()

  const mutation = useTantalimMutation<API.LocationDispositionInput, API.LocationDispositionSuccess>({
    url: '/location/disposition',
  })

  async function add(input: API.LocationDispositionInput): Promise<ExtendedLocationData> {
    try {
      const { location: newLocation } = await mutation.mutateAsync(input)
      logger.verbose('saved location', newLocation)

      replaceLocation({ locationId: input.locationId, newLocation, disposition: input.dispositionId })
      replaceItem(input.locationId, newLocation)
      return newLocation
    } catch (error) {
      const responseError = API.toResponseError(error)
      if (responseError.error === 'Duplicate') {
        await confirm(
          'Duplicate Account',
          'Someone else has already created an account at this location. Are you sure you want to create another?'
        )
        return add({ ...input, bypassDuplicateCheck: true })
      }
      throw API.toResponseError(error)
    }
  }

  return mutationAdd(mutation, add)
}
