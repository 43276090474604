import { eventOutcomesKey } from '../keys'
import { useQueryClient } from '@tanstack/react-query'
import { Logger, type EventOutcomeData, type EventOutcomeId, type API } from '@terros/common'

const logger = new Logger('useEventOutcomesCache')
// logger.level = 'verbose'

type Cached = API.EventOutcomeListSuccess

export function useEventOutcomesCache() {
  const queryClient = useQueryClient()
  return {
    clearList: () => {
      logger.verbose('invalidateQueries eventOutcomesKey')
      queryClient.invalidateQueries({ queryKey: eventOutcomesKey() })
    },
    addSuccess: (response: Cached) => queryClient.setQueryData<Cached>(eventOutcomesKey(), response),
    addItem: (eventOutcome: EventOutcomeData) => {
      logger.verbose('replaceItem', eventOutcome)
      queryClient.setQueryData<Cached>(eventOutcomesKey(), (existing) => {
        if (!existing) return
        return {
          ...existing,
          outcomes: [...existing.outcomes, eventOutcome],
        }
      })
    },
    replaceItem: (eventOutcome: EventOutcomeData) => {
      logger.verbose('replaceItem', eventOutcome)
      queryClient.setQueryData<Cached>(eventOutcomesKey(), (existing) => {
        if (!existing) return
        return {
          ...existing,
          outcomes: existing.outcomes.map((item) => (item.outcomeId === eventOutcome.outcomeId ? eventOutcome : item)),
        }
      })
    },
    removeItem: (outcomeId: EventOutcomeId) => {
      logger.verbose('removeItem', outcomeId)
      queryClient.setQueryData<Cached>(eventOutcomesKey(), (existing) => {
        if (!existing) return
        return {
          ...existing,
          outcomes: existing.outcomes.filter((item) => item.outcomeId !== outcomeId),
        }
      })
    },
  }
}
