import { mutationUpdate, type UpdateHookResponse } from '../hooks'
import { useLocationCache } from '../location'
import { useTantalimMutation } from '../mutation'
import { API, type ExtendedLocationData, type LocationId, Logger, type NoteData } from '@terros/common'

const logger = new Logger('note')

export function useUpdateLocationNote(locationId: LocationId): UpdateHookResponse<NoteData, ExtendedLocationData> {
  logger.verbose('calling useUpdateLocationNote')
  const { replaceItem } = useLocationCache()

  const mutation = useTantalimMutation<API.LocationNoteUpdateInput, API.LocationNoteUpdateSuccess>({
    url: '/location/note/update',
  })

  async function update(note: NoteData): Promise<ExtendedLocationData> {
    try {
      logger.verbose('starting update')
      const output = await mutation.mutateAsync({ locationId, note })
      const { location: newLocationData } = output
      replaceItem(locationId, newLocationData)
      logger.verbose('done with update')
      return output.location
    } catch (error) {
      throw API.toResponseError(error)
    }
  }

  return mutationUpdate(mutation, update)
}
