import { type Address } from './address'
import { type CloserSettings, type CloserStatus } from './closer'
import type { Archivable, AuditProps } from './common'
import { type CompanyId, stubCompanyId } from './company'
import { isId } from './ids'
import { type WithImportId } from './import'
import { type PermissionMap } from './permissions'
import { type TinyRole } from './role'
import { type TeamId, type TeamMembershipType, type UserTeamData, type WithTeamId } from './team'

export type UserId = `U:${string}` | `U.${string}`

export type UserIdentifier = UserId | UserData['email'] | UserData['clientUserId']

/** used in unit tests */
export function stubUserId(id?: string): UserId {
  return `U:${id ?? 'ID'}`
}

export type WithUserId = {
  userId: UserId
}

export function isUserId(val: unknown): val is UserId {
  return isId(val, 'U')
}

export const SYSTEM_USER_ID: UserId = 'U:system'
export function isSystemUser(userId: UserId): boolean {
  return userId.startsWith(SYSTEM_USER_ID)
}

export const SYSTEM_UNASSIGNED_USER_ID: UserId = `${SYSTEM_USER_ID}_unassigned`
export function isUnassignedUser(userId: UserId): boolean {
  return userId.startsWith(SYSTEM_UNASSIGNED_USER_ID)
}

export const SYSTEM_ARCHIVED_USER_ID: UserId = `${SYSTEM_USER_ID}_archived`
export function isArchivedUser(userId: UserId): boolean {
  return userId.startsWith(SYSTEM_ARCHIVED_USER_ID)
}

export const SYSTEM_PLACEHOLDER_USER_ID: UserId = `${SYSTEM_USER_ID}_placeholder`

export const toUserId = (user: WithUserId): UserId => user.userId

/** deterministically returns a number between 0 and 9 */
export function getTestGroup(userId: UserId): number {
  const id = userId.substring(2, 10).replace(/-/g, '')
  const result = parseInt(id, 16)
  if (isNaN(result)) {
    const result2 = parseInt(id, 32)
    if (isNaN(result2)) return 0
    return result2 % 10
  }
  return result % 10
}

export type PrimaryTeam = WithTeamId & {
  /** @defaultValue now */
  staleAfter: number
}

export type UnsavedUser = Archivable &
  WithImportId & {
    lastName: string
    firstName: string
    preferredName?: string
    /** the user's email address; and also their username for login */
    email: string
    /** user's mobile phone number */
    phone?: string
    /** a company id if creating a user for another company other than your own */
    companyId?: CompanyId
    /** defaults to Pacific (not GMT) if empty */
    timeZone?: string
    /** identifier of this employee in client's system */
    clientUserId?: string
    /** @defaultValue Needs_Help */
    closerStatus?: CloserStatus
    /** the manager that is assigned to this user */
    managerId?: UserId
    /** user's title in the company */
    title?: string
    /** team to which all user activities on account, contact and event are linked */
    primaryTeam?: PrimaryTeam
  }

/** used in unit tests */
export function stubUnsavedUser(data?: Partial<UnsavedUser>): UnsavedUser {
  return {
    lastName: 'Doe',
    firstName: 'John',
    email: 'john@doe.com',
    ...data,
  }
}

/** information about the user that may be accessed by anyone */
export type UserData = UnsavedUser & {
  readonly userId: UserId
  readonly companyId: CompanyId
  /** the first time this user ever logged in */
  readonly firstAccess?: number
  /** last time this user logged in (if any) */
  lastAccess?: number
  /** the date the employee first started working for this company */
  startDate?: number
  /** defaults to en_US */
  language?: string
  /** identifier of this employee's team in client's system */
  clientTeamId?: string
  /** @see https://blurha.sh/ */
  avatarBlurhash?: string
  avatarUrl?: string
  teams?: UserTeamData
  roles?: TinyRole[]
  /** Displayed when clicking on their shorts channel */
  biography?: string
  /** Notifcation preferences */
  preferences?: UserPreferences
}

export type UserPreferences = {
  /**
   * true means turn on all notifications of that type (probably will never be used)
   * false means turns off all notifications of that type
   * undefined means use whatever is set
   */
  all?: NotificationPreference
  /** notify dispatcher whenever a close appointment is created */
  dispatcherAssignment?: NotificationPreference
  /** notify closer whenever a close appointment is created/updated */
  closeEvents?: NotificationPreference
  /** notify manager when a shadow event is created */
  shadowEvents?: NotificationPreference
  /** notify manager when a plan is submitted */
  planSubmitted?: NotificationPreference
  /** notify employee after there plan is reviewed */
  planReviewed?: NotificationPreference
  interviews?: NotificationPreference
  /** notify manager an evaluator need to be assigned */
  evaluationAssignment?: NotificationPreference
  /** notify manager after an evaluation is requested*/
  evaluationRequest?: NotificationPreference
  /** notify evaluator that evaluation is ready to be reviewed */
  evaluationReview?: NotificationPreference
  /** notify employee that evaluation has been updated */
  evaluationUpdate?: NotificationPreference
  tasks?: NotificationPreference
  messages?: NotificationPreference
  /** notify employee when an area is assigned */
  areaAssignment?: NotificationPreference
}

export type UserPreferenceKeys = Exclude<keyof NonNullable<UserPreferences | undefined>, 'all'>
export function canSendNotification(
  userPref: UserData['preferences'],
  kind: UserPreferenceKeys,
  type: keyof NotificationPreference
): boolean {
  return userPref?.all?.[type] ?? userPref?.[kind]?.[type] ?? false
}

type NotificationPreference = {
  push?: boolean
  email?: boolean
  // text?: boolean
}

/**
 * Build a UserData object from a more full UserProfileData object
 */
export function toUserData(profile: UserProfileData): UserData {
  return {
    ...toTinyUser(profile),
    email: profile.email,
    companyId: profile.companyId,
    isDeleted: profile.isDeleted,
    firstAccess: profile.firstAccess,
    phone: profile.phone,
    startDate: profile.startDate,
    language: profile.language,
    timeZone: profile.timeZone,
    clientUserId: profile.clientUserId,
    title: profile.title,
    teams: profile.teams,
    roles: profile.roles,
    closerStatus: profile.closerStatus,
    biography: profile.biography,
  }
}

/**
 * Build a TinyUser object from a more full UserData object
 */
export function toTinyUser(profile: TinyUser): TinyUser {
  return {
    userId: profile.userId,
    lastName: profile.lastName,
    firstName: profile.firstName,
    preferredName: profile.preferredName,
    avatarBlurhash: profile.avatarBlurhash,
    avatarUrl: profile.avatarUrl,
    lastAccess: profile.lastAccess,
    clientUserId: profile.clientUserId,
  }
}
export function toSmallUser(profile: UserData): SmallUser {
  return {
    ...toTinyUser(profile),
    email: profile.email,
    phone: profile.phone,
    title: profile.title,
  }
}

/** used in unit tests */
export function stubUserData(data?: Partial<UserData>): UserData {
  return {
    ...stubUnsavedUser(),
    companyId: stubCompanyId(),
    userId: stubUserId(),
    ...data,
  }
}

export type BirthDate = {
  month: number
  day?: number
}

/** private information for the current user and their manager */
export type UserProfileData = UserData &
  AuditProps & {
    /** A map of permissions to the scopes they have */
    permissionMap?: PermissionMap
    /** the most recent datetime they agreed to EULA */
    agreeToTerms?: number
    mailingAddress?: Address
    shoeSize?: string
    shirtSize?: string
    birth?: BirthDate
    uploadAvatarUrl?: string
    /** assigned closer for the user */
    closerIds?: UserId[]
    closer?: CloserSettings
    roles?: TinyRole[]
  }

export type TinyUser = Pick<
  UserData,
  'userId' | 'clientUserId' | 'firstName' | 'lastName' | 'preferredName' | 'avatarBlurhash' | 'avatarUrl' | 'lastAccess'
>

export type SmallUser = TinyUser &
  Pick<UserData, 'phone' | 'email' | 'title' | 'timeZone'> & {
    teamIds?: TeamId[]
  }

/** used in unit tests */
export function stubUserProfileData(data?: Partial<UserProfileData>): UserProfileData {
  return {
    ...stubUserData(),
    ...data,
  }
}

export const UserDefault = {
  language: 'en_US',
  timeZone: 'America/Los_Angeles',
}

export const unknownLinksBiographyPattern = /(?<temp1>https?:\/\/[^\s]+)/g

export const knownLinksBiographyPattern: RegExp[] = [
  /^https?:\/\/(?:www\.)?youtube\.com\/(?:(?:@[a-zA-Z0-9-_]{1,100})|channel\/|user\/|c\/)?[a-zA-Z0-9_-]+\/?$/,
  /^https?:\/\/(?:www\.)?x\.com\/[a-zA-Z0-9_]{1,15}\/?$/,
  /^https?:\/\/(?:www\.)?twitter\.com\/[a-zA-Z0-9_]{1,15}\/?$/,
  /^https?:\/\/(?:www\.)?instagram\.com\/[a-zA-Z0-9_.]{1,30}\/?$/,
  /^https?:\/\/(?:www\.)?linktr\.ee\/[a-zA-Z0-9-_]{1,30}\/?$/,
]

export type UserImport = {
  raw: string
  firstName?: string
  lastName?: string
  email?: string
  phone?: string
  teamName?: string
  externalId?: string
  timezone?: string
  preferredName?: string
  closerStatus?: string
  manager?: TeamMembershipType
}

/** @see https://help.okta.com/en-us/content/topics/users-groups-profiles/usgp-end-user-states.htm */
export type SunrunUserStatus =
  | 'STAGED'
  | 'PROVISIONED'
  | 'ACTIVE'
  | 'RECOVERY'
  | 'PASSWORD EXPIRED'
  | 'LOCKED OUT'
  | 'SUSPENDED'
  | 'DEPROVISIONED'

export type SunrunOktaUser = {
  oktaUserId: string
  status: SunrunUserStatus
  firstName: string
  lastName: string
  nickName: string
  office: string
  title: string
  email: string
  employeeId: string
  phone: string
}
