import { type RoleId } from '../../role'
import { type DefaultRole } from '../../team'

export const DEFAULT_COMPANY_ADMIN_ID: RoleId = 'Role.admin'

export const DEFAULT_COMPANY_ADMIN: DefaultRole = {
  roleId: DEFAULT_COMPANY_ADMIN_ID,
  name: 'Admin',
  permissionOverrides: [
    { permissionType: 'account:import', scope: 'company' }, // import accounts for my company
    { permissionType: 'account:manage', scope: 'company' }, // manage accounts for my company
    { permissionType: 'account:list', scope: 'company' }, // view accounts for my company
    { permissionType: 'attachment:list', scope: 'company' }, // list attachments for my company
    { permissionType: 'attachment:manage', scope: 'company' }, // manage attachments for my company
    { permissionType: 'accountRule:save', scope: 'company' }, // Save and manage account roles for my company
    { permissionType: 'accountStatus:save', scope: 'company' }, // manage account statuses for my company
    { permissionType: 'area:list', scope: 'company' },
    { permissionType: 'area:add', scope: 'company' }, // add areas for my company
    { permissionType: 'company:update', scope: 'company' }, // update my company details
    { permissionType: 'contact:list', scope: 'company' }, // list contacts for any user in my company
    { permissionType: 'contact:coach', scope: 'company' }, // receive tasks to coach anyone in the company who doesn't have a coach on their team
    { permissionType: 'contact:import', scope: 'company' }, // import contacts for my company
    { permissionType: 'customField:list', scope: 'company' }, // list custom fields for my company
    { permissionType: 'customField:save', scope: 'company' }, // save custom fields for my company
    { permissionType: 'disposition:save', scope: 'company' }, // save dispositions for my company
    { permissionType: 'event:import', scope: 'company' }, // import events for my company
    { permissionType: 'event:read', scope: 'company' }, // list events for any user in my company
    { permissionType: 'event:manage', scope: 'company' }, // manage events for any user in my company
    { permissionType: 'event:save', scope: 'company' }, // save events for any user in my company
    { permissionType: 'event:delete', scope: 'company' }, // delete events for my company
    { permissionType: 'event:outcome:save', scope: 'company' }, // save event outcomes for my company
    { permissionType: 'availability:list', scope: 'company' }, // list availability for any user in my company
    { permissionType: 'evaluation:list', scope: 'company' }, // list evaluations for any user in my company
    { permissionType: 'evaluation:manage', scope: 'company' }, // manage evaluations for my company
    { permissionType: 'message:send', scope: 'company' }, // send messages to any and all users in my company
    { permissionType: 'message:send:group', scope: 'company' }, // send messages to groups in my company
    { permissionType: 'message:delete', scope: 'company' }, // delete messages for any user in my company
    { permissionType: 'plan:list', scope: 'company' }, // list plans for any user in my company
    { permissionType: 'plan:manage', scope: 'company' }, // manage and approve plans for any user in my company
    { permissionType: 'plan:update', scope: 'company' }, // update plans for any user in my company
    { permissionType: 'plan:delete', scope: 'company' }, // delete plans for anyone in my company
    { permissionType: 'ratio:save', scope: 'company' }, // manage ratios for my company
    { permissionType: 'role:manage', scope: 'company' }, // manage roles for my company
    { permissionType: 'task:list', scope: 'company' }, // list tasks for any user in my company
    { permissionType: 'task:add', scope: 'company' }, // add tasks for my downline users
    { permissionType: 'task:update', scope: 'company' }, // update or archive tasks for any user in my company
    { permissionType: 'team:add', scope: 'company' }, // add sub teams for my downline
    { permissionType: 'team:update', scope: 'company' }, // update any team in my company
    { permissionType: 'team:archive', scope: 'company' }, // archive any team in my company
    { permissionType: 'user:add', scope: 'company' }, // add users for my company
    { permissionType: 'user:export', scope: 'company' }, // export users for my company
    { permissionType: 'user:manage', scope: 'company' }, // manage users in my company
    { permissionType: 'user:update', scope: 'company' }, // update users in my company
    { permissionType: 'user:import', scope: 'company' }, // import users for my company
    { permissionType: 'admin:assign', scope: 'company' }, // assign admins for my company
    { permissionType: 'webhook:manage', scope: 'company' }, // manage webhooks for my company
    { permissionType: 'apikey:manage', scope: 'company' }, // read apikeys for my company
    { permissionType: 'game:list', scope: 'company' }, // list games for my company
    { permissionType: 'game:save', scope: 'company' }, // save games for my company
  ],
}
