import { type GetHookResponse } from '../hooks'
import { locationPrescreenKey } from '../keys'
import { useTantalimQuery } from '../query'
import { useLocationCache } from './cache'
import { type API, Logger, type PrescreenDecision, DAY } from '@terros/common'

const logger = new Logger('prescreen')

export function usePrescreen(
  input: API.LocationPrescreenInput,
  onPrescreen?: (res: API.LocationPrescreenSuccess) => void
): GetHookResponse<PrescreenDecision> {
  logger.verbose('calling usePrescreen')
  const { updatePrescreen } = useLocationCache()

  return useTantalimQuery<API.LocationPrescreenInput, API.LocationPrescreenSuccess, PrescreenDecision>({
    url: '/location/prescreen',
    queryKey: locationPrescreenKey(input),
    input,
    convertResponse: (res) => {
      updatePrescreen(input.locationId, input.residentId, res.decision)
      onPrescreen?.(res)
      return res.decision
    },
    options: {
      enabled: false,
      gcTime: DAY,
      staleTime: DAY,
      retry: false,
    },
  })
}
