import { accountKey, userKey } from '../keys'
import { useQueryClient, type InfiniteData } from '@tanstack/react-query'
import { isUserId, Logger, type UserData, type AccountData, type AccountId, type API } from '@terros/common'

const logger = new Logger('useAccountCache')

export function useAccountCache() {
  const queryClient = useQueryClient()
  return {
    addItem: (item: AccountData) => {
      logger.verbose('addItem account', item)
      const ownerId = item.ownerId
      if (ownerId) {
        if (isUserId(ownerId)) {
          const userData = queryClient.getQueryData<UserData>(userKey(ownerId))
          if (userData) {
            item = { ...item, owner: userData }
          }
        }
      }
      queryClient.setQueriesData<AccountData[]>({ queryKey: ['accounts'] }, (old) => [...(old || []), item])
      queryClient.setQueriesData<InfiniteData<API.AccountListSuccess>>({ queryKey: ['accounts-infinite'] }, (old) => {
        if (!old) return
        return {
          ...old,
          pages: old.pages.map((page) => ({
            ...page,
            accounts: [...page.accounts, item],
          })),
        }
      })
    },
    removeItem: (accountId: AccountId) => {
      logger.verbose('removeItem account', accountId)
      queryClient.setQueriesData<AccountData[]>({ queryKey: ['accounts'] }, (old) =>
        old?.filter((t) => t.accountId !== accountId)
      )
      queryClient.setQueriesData<InfiniteData<API.AccountListSuccess>>({ queryKey: ['accounts-infinite'] }, (old) => {
        if (!old?.pages) return
        return {
          ...old,
          pages: old.pages.map((page) => ({
            ...page,
            accounts: page.accounts.filter((t) => t.accountId !== accountId),
          })),
        }
      })
    },
    updateItem: (account: AccountData) => {
      logger.verbose('updateItem account', account)
      queryClient.setQueriesData<AccountData[]>({ queryKey: ['accounts'] }, (old) =>
        old?.map((t) => (t.accountId === account.accountId ? account : t))
      )
      queryClient.setQueriesData<API.AccountListSuccess>({ queryKey: ['accounts-infinite'] }, (old) => {
        if (!old) return
        return {
          ...old,
          accounts: old.accounts.map((t) => (t.accountId === account.accountId ? account : t)),
        }
      })
      queryClient.setQueryData<AccountData>(accountKey(account.accountId), account)
    },
  }
}
