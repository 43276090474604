import { serverRequest, useBackend } from '../api'
import { videoNextKey } from '../keys'
import { useVideoCache } from './cache'
import {
  useInfiniteQuery,
  type UseInfiniteQueryResult,
  type InfiniteData,
  type QueryFunction,
  type QueryKey,
} from '@tanstack/react-query'
import { type API, Logger, HOUR, type APIError, messageFromError } from '@terros/common'

type Result = Omit<UseInfiniteQueryResult<InfiniteData<API.VideoNextSuccess>>, 'error'> & {
  error?: string
}

const logger = new Logger('useNextVideos')

export function useNextVideos(enabled = true): Result {
  const backend = useBackend()
  const cache = useVideoCache()
  const queryFn: QueryFunction<API.VideoNextSuccess, QueryKey, string | undefined> = async ({
    pageParam,
  }): Promise<API.VideoNextSuccess> => {
    const res = await serverRequest<API.VideoNextInput, API.VideoNextSuccess>({
      backend,
      path: '/video/next',
      input: {
        recommId: pageParam,
      },
    })
    logger.info(`Got next page of ${res.videos.length} videos`)
    cache.addItems(res.videos)
    return res
  }
  const query = useInfiniteQuery<
    API.VideoNextSuccess,
    APIError,
    InfiniteData<API.VideoNextSuccess>,
    QueryKey,
    string | undefined
  >({
    queryKey: videoNextKey(),
    queryFn,
    initialPageParam: undefined,
    getNextPageParam: (lastPage) => lastPage.recommId,
    enabled,
    staleTime: 6 * HOUR,
    gcTime: 6 * HOUR,
  })
  const error = messageFromError(query.error)
  return { ...query, error }
}
