import { type AddHookResponse, mutationAdd } from '../hooks'
import { useLocationCache } from '../location'
import { useTantalimMutation } from '../mutation'
import { useEventCache } from './cache'
import { API, type CalendarEventData, Logger, type UnsavedCalendarEventData, CURRENT_VERSION } from '@terros/common'

const logger = new Logger('calendar-add')

export function useAddCalendarEvent(): AddHookResponse<UnsavedCalendarEventData, CalendarEventData> {
  const locationCache = useLocationCache()
  const eventCache = useEventCache()
  const mutation = useTantalimMutation<API.CalendarEventAddInput, API.CalendarEventAddSuccess>({
    url: '/calendar/event/add',
  })

  async function add(event: UnsavedCalendarEventData): Promise<CalendarEventData> {
    try {
      const { event: saved } = await mutation.mutateAsync({ version: CURRENT_VERSION, event })
      logger.verbose('add succeeded', saved)
      eventCache.clearList()
      if (saved.locationId) {
        locationCache.clearItem(saved.locationId)
      }
      return saved
    } catch (error) {
      throw API.toResponseError(error)
    }
  }

  return mutationAdd(mutation, add)
}
