import { type QueryKey } from '@tanstack/react-query'
import {
  type API,
  type CalendarEventId,
  type CompanyId,
  type DispositionId,
  type FeatureId,
  type LatLng,
  type LocationId,
  type MessageEntityId,
  type QuestionId,
  type TeamId,
  type UserId,
  type PlanKey,
  type PlanScopeId,
  type InterviewQuestionId,
  type InterviewId,
  type VideoId,
  type ImportId,
  type AttachmentId,
  type Preference,
  type CustomTableType,
  type AccountId,
  type EntityId,
  type AccountDuplicateId,
  type CustomFieldId,
  type PermissionEntityId,
  RatioId,
} from '@terros/common'

export const accountKey = (accountId: AccountId): QueryKey => ['account', accountId]
export const accountsKey = (props: API.AccountListInput): QueryKey => ['accounts', props]
export const accountsInfiniteKey = (props: API.AccountListInput): QueryKey => ['accounts-infinite', props]
export const accountStatusesKey = (): QueryKey => ['account-statuses']
export const accountRulesKey = (): QueryKey => ['account-rules']
export const apiKeyKey = (): QueryKey => ['api-keys']
export const areaCanvassKey = (): QueryKey => ['areas-canvass']
export const areaListKey = (props?: API.AreaListInput): QueryKey => {
  if (!props) return ['areas']
  return ['areas', props]
}
export const attachmentKey = (attachmentId: AttachmentId): QueryKey => ['attachment', attachmentId]
export const autocompleteKey = (query: string): QueryKey => ['autocomplete', query]
export const calendarAvailabilityKey = (input: API.CalendarAvailabilityInput): QueryKey => [
  'calendar-availability',
  input,
]
export const calendarClosersKey = (input: API.CalendarClosersInput): QueryKey => ['calendar-closers', input]
export const companyKey = (companyId: CompanyId | undefined): QueryKey => ['company', companyId]
export const companyListKey = (): QueryKey => ['companies']
export const customFieldsKey = (tableType?: CustomTableType): QueryKey => {
  if (!tableType) return ['custom-fields']
  return ['custom-fields', tableType]
}
export const customFieldKey = (fieldId: CustomFieldId): QueryKey => ['custom-field', fieldId]
export const dispositionKey = (dispositionId?: DispositionId): QueryKey => ['disposition', dispositionId]
export const dispositionsKey = (): QueryKey => ['dispositions']
export const duplicateAccountKey = (accountDuplicateId: AccountDuplicateId): QueryKey => [
  'duplicate-account',
  accountDuplicateId,
]
export const duplicateAccountsKey = (): QueryKey => ['duplicate-accounts']
export const emailListKey = (props: API.UserEmailListInput): QueryKey => ['emails', props]
export const evaluationKey = (evaluationId: string): QueryKey => ['evaluation', evaluationId]
export const evaluationListKey = (props: API.EvaluationListInput): QueryKey => ['evaluations', props]
export const evaluationQuestionsKey = (): QueryKey => ['evaluationQuestions']
export const eventKey = (eventId?: CalendarEventId): QueryKey => {
  if (!eventId) return ['eventId']
  return ['eventId', eventId]
}
export const eventOutcomesKey = (): QueryKey => ['event-outcomes']
export const eventsKey = (props: API.CalendarEventListInput): QueryKey => ['calendar-events', props]
export const eventsInfiniteKey = (props: API.CalendarEventListInput): QueryKey => ['calendar-events-infinite', props]
export const featureKey = (featureId: FeatureId | undefined): QueryKey => ['featureId', featureId]
export const featureGetKey = (props: API.FeatureGetInput): QueryKey => ['featureGet', props]
export const featureHistoryKey = (props: API.FeatureHistoryInput): QueryKey => ['featureHistory', props]
export const featureListKey = (): QueryKey => ['features']
export const importErrorsKey = (importId: ImportId): QueryKey => ['import-errors', importId]
export const importKey = (importId: ImportId): QueryKey => ['import', importId]
export const importsKey = (props: API.ImportListInput): QueryKey => ['imports', props]
export const incompleteAccountsKey = (props: API.AccountListMissingInput): QueryKey => ['incomplete-accounts', props]
export const interviewKey = (interviewId: InterviewId): QueryKey => ['interview', interviewId]
export const interviewReportKey = (): QueryKey => ['interview-report']
export const interviewQuestionsListKey = (props?: API.InterviewQuestionListInput): QueryKey => [
  'interview-questions',
  props,
]
export const interviewQuestionKey = (interviewQuestionId: InterviewQuestionId): QueryKey => [
  'interview-question',
  interviewQuestionId,
]
export const locationKey = (locationId?: LocationId): QueryKey => ['location', locationId]
export const locationCountKey = (coordinates?: LatLng[]): QueryKey => ['locationCount', coordinates]
export const locationPrescreenKey = (input: API.LocationPrescreenInput): QueryKey => {
  const prefix = 'prescreen'
  if (input.residentData) {
    return [prefix, input.locationId, 'residentData', input.residentData.firstName, input.residentData.lastName]
  }
  throw new Error('Unexpected prescreen without residentData')
}
export const locationsKey = (coordinates: LatLng): QueryKey => ['locations', coordinates]
export const locationScorecardKey = (input: API.LocationScorecardInput): QueryKey => ['location-scorecard', input]
export const attendanceHistoryKey = (): QueryKey => ['meeting-attendance-history']
export const messageUnreadsKey = (): QueryKey => ['message-unreads']
export const oauthListKey = (): QueryKey => ['oauth-list']
export const planKey = (version: number, key: PlanKey, createIfMissing?: boolean): QueryKey =>
  createIfMissing !== undefined ? ['plan', version, key, createIfMissing] : ['plan', version, key]
export const planPercentKey = (input: API.PlanPercentInput): QueryKey => ['plan-percent', input]
export const plansKey = (scopeId?: PlanScopeId): QueryKey => ['plans', scopeId]
export const preferenceKey = (preference: Preference, targetId?: EntityId): QueryKey => [
  'preference',
  preference,
  targetId,
]
export const profileKey = (userId?: UserId): QueryKey => ['profile', userId]
export const questionKey = (questionId: QuestionId): QueryKey => ['question', questionId]
export const questionsKey = (): QueryKey => ['questions']
export const questionCheckKey = (): QueryKey => ['questions-check']
export const questionResultsKey = (props: API.QuestionResultListInput): QueryKey => ['question-results', props]
export const reverseGeocodeKey = (location: LatLng): QueryKey => ['reverse-geocode', location]
export const statsKey = (): QueryKey => ['stats']
export const intercomKey = (platform: string): QueryKey => ['intercom', platform]
export const userKey = (userId?: UserId): QueryKey => ['user', userId]
export const userListKey = (props?: API.UserListInput): QueryKey => {
  if (!props) return ['users']
  return ['users', props]
}
export const taskListKey = (props: API.TaskListInput): QueryKey => ['tasks', props]
export const taskSnapshotEmployeesKey = (props: API.SnapshotEmployeesInput): QueryKey => [
  'task-snapshot-employees',
  props,
]
export const taskSnapshotManagersKey = (): QueryKey => ['task-snapshot-managers']
export const teamKey = (teamId?: TeamId): QueryKey => ['team', teamId]
export const teamListKey = (props: API.TeamListInput): QueryKey => ['teams', props]

export const timeslotsKey = (props: API.CalendarTimeslotsInput): QueryKey => ['timeslots', props]
export const threadKey = (targetId: MessageEntityId): QueryKey => ['thread', targetId]
export const threadListKey = (): QueryKey => ['threads']
export const userSearchKey = (props: API.UserSearchInput): QueryKey => ['user-search', props]
export const ratioKey = (ratioId: RatioId): QueryKey => ['ratio', ratioId]
export const ratioListKey = (props: API.RatioListInput): QueryKey => ['ratios', props]
export const rankListKey = (userId?: UserId): QueryKey => ['rank-list', userId]
export const reportColumnKey = (): QueryKey => ['report-column']
export const reportKpiKey = (props: API.ReportKpiInput): QueryKey => {
  props.kpis.sort()
  return ['report-kpi', props]
}
export const reportMapKey = (props: API.ReportMapInput): QueryKey => ['report-map', props]
export const reportUserKpiKey = (): QueryKey => ['report-user-kpi']
export const roleKey = (props: Partial<API.RoleGetInput>): QueryKey => ['role', props]
export const roleListKey = (props: API.RoleListInput): QueryKey => ['roles', props]
export const shadowAvailabilityKey = (props: API.CalendarShadowInput): QueryKey => ['shadow-availability', props]
export const versionKey = (props: API.ClientVersionInput): QueryKey => ['version', props]
export const videoKey = (videoId: VideoId): QueryKey => ['video', videoId]
export const videoListKey = (props: API.VideoListInput): QueryKey => ['videos', props]
export const videoNextKey = (): QueryKey => ['next-videos']
export const videoReportsKey = (limit?: number): QueryKey => ['video-reports', limit]
export const videoReportReasonsKey = (videoId: VideoId): QueryKey => ['video-report-reasons', videoId]
export const videoSearchKey = (props: API.VideoSearchInput): QueryKey => ['video-search', props]
export const videoTagsKey = (props: API.VideoTagsInput): QueryKey => ['video-tags', props]
export const videoUserBookmarkKey = (): QueryKey => ['video-user-bookmark']
export const videoProfileKey = (userId: UserId): QueryKey => ['video-profile', userId]
export const webhooksKey = (): QueryKey => ['webhooks']
export const webhookHistoryKey = (input: API.WebhookHistoryInput): QueryKey => ['webhook-history', input]
export const permissionsKey = (targetId: PermissionEntityId): QueryKey => ['permissions', targetId]
export const permissionCheckKey = (input: API.PermissionCheckInput): QueryKey => [
  'permission-check',
  input.permissionType,
  input.targetId,
]
