export const Color = {
  iosBlue: '#027AFF', // From iOS blue
  secondary: '#FF8702', // Opposite of iOS blue
  tabBarBlue: '#2396F3', // tab color from react-native-tab-view
  iosBackground: '#F2F2F7', // From iOS Calendar and Settings background
  textLight: '#e5e5e5', // Native Base trueGray.200
  textPlaceholder: '#737373', // Native Base muted.500
  textDark: '#262626', // Native Base trueGray.800
  pitch: '#027AFF',
  lead: '#E37400',
  install: '#136f63',
  contact: '#EA4335',
  bronze: '#cd7f32',
  silver: '#c0c0c0',
  gold: '#ffd700',
  primary: '#172F44',
  action: '#64A5DF',
  brand: '#F8BA00',
  backgroundPrimary: '#D8E0EA',
  backgroundSecondary: '#EEE9E1',
}

export const ColorPrimitives = {
  brand: {
    darkBlue: '#172F44',
    blue: '#64A5DF',
    lightBlue: '#D8E0EA',
    tan: '#EEE9E1',
    yellow: '#F8BA00',
  },
  blue: {
    '50': '#EDF2F8',
    '100': '#D8E0EA',
    '200': '#B9CCE1',
    '300': '#94B6D7',
    '400': '#64A5DF',
    '500': '#4C90CF',
    '600': '#3877B4',
    '700': '#285A90',
    '800': '#1D416D',
    '900': '#172F44',
    '950': '#0F1D2E',
  },
  yellow: {
    '50': '#FFF7E5',
    '100': '#FEEFC7',
    '200': '#FDE29B',
    '300': '#FBD470',
    '400': '#F8C547',
    '500': '#F8BA00',
    '600': '#D79F00',
    '700': '#B27F00',
    '800': '#8A6200',
    '900': '#654900',
    '950': '#3E2B00',
  },
  tan: {
    '50': '#FAF9F7',
    '100': '#F5F3EF',
    '200': '#EEE9E1',
    '300': '#DDD5C9',
    '400': '#C7BBAE',
    '500': '#B2A395',
    '600': '#998A7D',
    '700': '#7D6E65',
    '800': '#61544E',
    '900': '#433C39',
    '950': '#282522',
  },
  neutral: {
    '50': '#FAFAFA',
    '100': '#F5F5F5',
    '200': '#E5E5E5',
    '300': '#D4D4D4',
    '400': '#A3A3A3',
    '500': '#737373',
    '600': '#525252',
    '700': '#404040',
    '800': '#262626',
    '900': '#171717',
    '950': '#0A0A0A',
  },
  rose: {
    '50': '#FFF1F2',
    '100': '#FFE4E6',
    '200': '#FECDD3',
    '300': '#FDA4AF',
    '400': '#FB7185',
    '500': '#F43F5E',
    '600': '#E11D48',
    '700': '#BE123C',
    '800': '#9F1239',
    '900': '#881337',
    '950': '#4C0519',
  },
  orange: {
    '50': '#FFF7ED',
    '100': '#FFEDD5',
    '200': '#FED7AA',
    '300': '#FDBA74',
    '400': '#FB923C',
    '500': '#F97316',
    '600': '#EA580C',
    '700': '#C2410C',
    '800': '#9A3412',
    '900': '#7C2D12',
    '950': '#431407',
  },
  emerald: {
    '50': '#ECFDF5',
    '100': '#D1FAE5',
    '200': '#A7F3D0',
    '300': '#6EE7B7',
    '400': '#34D399',
    '500': '#10B981',
    '600': '#059669',
    '700': '#047857',
    '800': '#065F46',
    '900': '#064E3B',
    '950': '#022C22',
  },
  indigo: {
    '50': '#EEF2FF',
    '100': '#E0E7FF',
    '200': '#C7D2FE',
    '300': '#A5B4FC',
    '400': '#818CF8',
    '500': '#6366F1',
    '600': '#4F46E5',
    '700': '#4338CA',
    '800': '#3730A3',
    '900': '#312E81',
    '950': '#1E1B4B',
  },
  black: {
    '50': 'rgba(0, 0, 0, 0.025)',
    '100': 'rgba(0, 0, 0, 0.05)',
    '200': 'rgba(0, 0, 0, 0.1)',
    '300': 'rgba(0, 0, 0, 0.15)',
    '400': 'rgba(0, 0, 0, 0.3)',
    '500': 'rgba(0, 0, 0, 0.45)',
    '600': 'rgba(0, 0, 0, 0.6)',
    '700': 'rgba(0, 0, 0, 0.75)',
    '800': 'rgba(0, 0, 0, 0.90)',
    '900': 'rgba(0, 0, 0, 0.95)',
    '950': 'rgba(0, 0, 0, 0.975)',
    '1000': '#000000',
  },
  white: {
    '50': 'rgba(255, 255, 255, 0.025)',
    '100': 'rgba(255, 255, 255, 0.05)',
    '200': 'rgba(255, 255, 255, 0.1)',
    '300': 'rgba(255, 255, 255, 0.15)',
    '400': 'rgba(255, 255, 255, 0.3)',
    '500': 'rgba(255, 255, 255, 0.45)',
    '600': 'rgba(255, 255, 255, 0.6)',
    '700': 'rgba(255, 255, 255, 0.75)',
    '800': 'rgba(255, 255, 255, 0.90)',
    '900': 'rgba(255, 255, 255, 0.95)',
    '950': 'rgba(255, 255, 255, 0.975)',
    '1000': '#FFFFFF',
  },
} as const

export type ColorValue = `#${string}` | `rgba(${number}, ${number}, ${number}, ${number})`

type PrimarySecondaryTertiary = {
  primary: ColorValue
  secondary: ColorValue
  tertiary: ColorValue
}

type Inverse = {
  inverse: ColorValue
}

export type StatusColors = {
  info: ColorValue
  success: ColorValue
  warning: ColorValue
  danger: ColorValue
  disabled: ColorValue
}

export type ColorTheme = {
  action: {
    primary: ColorValue
    primaryPressed: ColorValue
    accent: ColorValue
    accentPressed: ColorValue
    gray: ColorValue
    grayPressed: ColorValue
  }
  background: {
    neutral: PrimarySecondaryTertiary
    brand: PrimarySecondaryTertiary
    status: StatusColors
  }
  border: {
    neutral: PrimarySecondaryTertiary
    brand: PrimarySecondaryTertiary
    status: StatusColors
  }
  text: {
    neutral: PrimarySecondaryTertiary & Inverse
    brand: PrimarySecondaryTertiary & Inverse
    status: StatusColors
  }
  icon: {
    neutral: PrimarySecondaryTertiary & Inverse
    brand: PrimarySecondaryTertiary & Inverse
    status: StatusColors
  }
}

const light: ColorTheme = {
  action: {
    primary: ColorPrimitives.blue['600'],
    primaryPressed: ColorPrimitives.blue['700'],
    accent: ColorPrimitives.yellow['500'],
    accentPressed: ColorPrimitives.yellow['600'],
    gray: ColorPrimitives.black['100'],
    grayPressed: ColorPrimitives.black['200'],
  },
  background: {
    neutral: {
      primary: ColorPrimitives.white['1000'],
      secondary: ColorPrimitives.neutral['100'],
      tertiary: ColorPrimitives.neutral['200'],
    },
    brand: {
      primary: ColorPrimitives.blue['900'],
      secondary: ColorPrimitives.blue['50'],
      tertiary: ColorPrimitives.tan['200'],
    },
    status: {
      info: ColorPrimitives.indigo['100'],
      success: ColorPrimitives.emerald['100'],
      warning: ColorPrimitives.orange['100'],
      danger: ColorPrimitives.rose['100'],
      disabled: ColorPrimitives.black['100'],
    },
  },
  border: {
    neutral: {
      primary: ColorPrimitives.black['100'],
      secondary: ColorPrimitives.black['300'],
      tertiary: ColorPrimitives.black['600'],
    },
    brand: {
      primary: ColorPrimitives.blue['600'],
      secondary: ColorPrimitives.blue['700'],
      tertiary: ColorPrimitives.blue['900'],
    },
    status: {
      info: ColorPrimitives.indigo['600'],
      success: ColorPrimitives.emerald['600'],
      warning: ColorPrimitives.orange['600'],
      danger: ColorPrimitives.rose['600'],
      disabled: ColorPrimitives.black['300'],
    },
  },
  text: {
    neutral: {
      primary: ColorPrimitives.neutral['900'],
      secondary: ColorPrimitives.neutral['700'],
      tertiary: ColorPrimitives.neutral['500'],
      inverse: ColorPrimitives.white['1000'],
    },
    brand: {
      primary: ColorPrimitives.blue['900'],
      secondary: ColorPrimitives.blue['700'],
      tertiary: ColorPrimitives.blue['600'],
      inverse: ColorPrimitives.blue['50'],
    },
    status: {
      info: ColorPrimitives.indigo['900'],
      success: ColorPrimitives.emerald['900'],
      warning: ColorPrimitives.orange['900'],
      danger: ColorPrimitives.rose['900'],
      disabled: ColorPrimitives.black['400'],
    },
  },
  icon: {
    neutral: {
      primary: ColorPrimitives.neutral['900'],
      secondary: ColorPrimitives.neutral['700'],
      tertiary: ColorPrimitives.neutral['400'],
      inverse: ColorPrimitives.white['1000'],
    },
    brand: {
      primary: ColorPrimitives.blue['900'],
      secondary: ColorPrimitives.blue['700'],
      tertiary: ColorPrimitives.blue['600'],
      inverse: ColorPrimitives.blue['50'],
    },
    status: {
      info: ColorPrimitives.indigo['600'],
      success: ColorPrimitives.emerald['600'],
      warning: ColorPrimitives.orange['600'],
      danger: ColorPrimitives.rose['600'],
      disabled: ColorPrimitives.black['300'],
    },
  },
}

const dark: ColorTheme = {
  action: {
    primary: ColorPrimitives.blue['500'],
    primaryPressed: ColorPrimitives.blue['600'],
    accent: ColorPrimitives.yellow['500'],
    accentPressed: ColorPrimitives.yellow['600'],
    gray: ColorPrimitives.white['200'],
    grayPressed: ColorPrimitives.white['300'],
  },
  background: {
    neutral: {
      primary: ColorPrimitives.neutral['900'],
      secondary: ColorPrimitives.neutral['950'],
      tertiary: ColorPrimitives.neutral['950'],
    },
    brand: {
      primary: ColorPrimitives.blue['900'],
      secondary: ColorPrimitives.blue['950'],
      tertiary: ColorPrimitives.tan['950'],
    },
    status: {
      info: ColorPrimitives.indigo['950'],
      success: ColorPrimitives.emerald['950'],
      warning: ColorPrimitives.orange['950'],
      danger: ColorPrimitives.rose['950'],
      disabled: ColorPrimitives.white['200'],
    },
  },
  border: {
    neutral: {
      primary: ColorPrimitives.white['200'],
      secondary: ColorPrimitives.white['400'],
      tertiary: ColorPrimitives.white['700'],
    },
    brand: {
      primary: ColorPrimitives.blue['600'],
      secondary: ColorPrimitives.blue['700'],
      tertiary: ColorPrimitives.blue['900'],
    },
    status: {
      info: ColorPrimitives.indigo['800'],
      success: ColorPrimitives.emerald['800'],
      warning: ColorPrimitives.orange['800'],
      danger: ColorPrimitives.rose['800'],
      disabled: ColorPrimitives.white['400'],
    },
  },
  text: {
    neutral: {
      primary: ColorPrimitives.neutral['50'],
      secondary: ColorPrimitives.neutral['200'],
      tertiary: ColorPrimitives.neutral['400'],
      inverse: ColorPrimitives.white['1000'],
    },
    brand: {
      primary: ColorPrimitives.white['1000'],
      secondary: ColorPrimitives.white['1000'],
      tertiary: ColorPrimitives.blue['300'],
      inverse: ColorPrimitives.white['1000'],
    },
    status: {
      info: ColorPrimitives.indigo['300'],
      success: ColorPrimitives.emerald['300'],
      warning: ColorPrimitives.orange['300'],
      danger: ColorPrimitives.rose['300'],
      disabled: ColorPrimitives.white['500'],
    },
  },
  icon: {
    neutral: {
      primary: ColorPrimitives.neutral['50'],
      secondary: ColorPrimitives.neutral['200'],
      tertiary: ColorPrimitives.neutral['500'],
      inverse: ColorPrimitives.white['1000'],
    },
    brand: {
      primary: ColorPrimitives.white['1000'],
      secondary: ColorPrimitives.white['1000'],
      tertiary: ColorPrimitives.blue['400'],
      inverse: ColorPrimitives.white['1000'],
    },
    status: {
      info: ColorPrimitives.indigo['400'],
      success: ColorPrimitives.emerald['400'],
      warning: ColorPrimitives.orange['400'],
      danger: ColorPrimitives.rose['400'],
      disabled: ColorPrimitives.white['600'],
    },
  },
}

export const Theme = {
  light,
  dark,
}
