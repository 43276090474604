import { mutationUpdate, type UpdateHookResponse } from '../hooks'
import { useTantalimMutation } from '../mutation'
import { useTaskCache } from '../task'
import { useEvaluationCache } from './cache'
import { API, type EvaluationData, Logger } from '@terros/common'

const logger = new Logger('useUpdateTask')

export function useUpdateEvaluation(): UpdateHookResponse<EvaluationData> {
  logger.verbose('calling useUpdateEvaluation')
  const cache = useEvaluationCache()
  const taskCache = useTaskCache()

  const mutation = useTantalimMutation<API.EvaluationUpdateInput, API.EvaluationUpdateSuccess>({
    url: '/evaluation/update',
  })

  async function update(evaluation: EvaluationData): Promise<EvaluationData> {
    try {
      logger.verbose('starting update')
      const response = await mutation.mutateAsync({ evaluation })
      if (response.evaluation) {
        cache.setItem(response.evaluation.evaluationId, response.evaluation)
      }
      taskCache.clearList()
      logger.verbose('done with update')
      return response.evaluation
    } catch (error) {
      throw API.toResponseError(error)
    }
  }

  return mutationUpdate(mutation, update)
}
