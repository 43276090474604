import { importsKey } from '../keys'
import { useQueryClient } from '@tanstack/react-query'
import { Logger, type ImportData, type ImportId } from '@terros/common'

const logger = new Logger('useImportCache')

export function useImportCache() {
  const queryClient = useQueryClient()
  return {
    clearList: () => {
      logger.verbose('invalidateQueries imports')
      queryClient.invalidateQueries({ queryKey: importsKey({}) })
    },
    addItem: (item: ImportData) => {
      logger.verbose('addItem accountImport', item)
      queryClient.setQueryData<ImportData[]>(importsKey({}), (old) => [...(old || []), item])
    },
    replaceItem: (item: ImportData) => {
      logger.verbose('replaceItem import', item)
      queryClient.setQueryData<ImportData[]>(importsKey({}), (old) => {
        if (!old) {
          return [item]
        }
        return old.map((t) => (t.importId === item.importId ? item : t))
      })
    },
    removeItem: (importId: ImportId) => {
      logger.verbose('removeItem importId', importId)
      queryClient.setQueryData<ImportData[]>(importsKey({}), (old) => old?.filter((t) => t.importId !== importId))
    },
  }
}
