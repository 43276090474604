import { plansKey, planKey } from '../keys'
import { useQueryClient } from '@tanstack/react-query'
import { Logger, type PlanKey, type PlanScopeId, type PlanData } from '@terros/common'

const logger = new Logger('usePlanCache')

export function usePlanCache() {
  const queryClient = useQueryClient()
  return {
    clearList: (scopeId?: PlanScopeId) => {
      logger.verbose('invalidateQueries planListKey')
      queryClient.invalidateQueries({ queryKey: plansKey(scopeId) })
    },
    removeItem: (key: PlanKey) => {
      logger.verbose('removeQueries planKey', key)
      queryClient.setQueriesData<PlanData[]>({ queryKey: ['plans'] }, (prev) => {
        if (!prev) return
        return prev.filter((plan) => plan.scopeId !== key.scopeId && plan.period !== key.period)
      })
    },
    addItem: (key: PlanKey, newPlan: PlanData) => {
      logger.verbose('addItem setQueryData planKey', key, newPlan)
      queryClient.setQueriesData<PlanData[]>({ queryKey: plansKey(key.scopeId) }, (prev) => {
        if (!prev) return [newPlan]
        return [newPlan, ...prev]
      })
    },
    setItem: (version: number, key: PlanKey, newPlan: PlanData) => {
      logger.verbose('setQueryData planKey', key, newPlan)
      queryClient.setQueriesData({ queryKey: planKey(version, key) }, () => newPlan)
      queryClient.setQueriesData<PlanData[]>({ queryKey: plansKey(key.scopeId) }, (prev) => {
        if (!prev) return [newPlan]
        return prev.map((plan) => {
          if (plan.scopeId === key.scopeId && plan.period === key.period) {
            return newPlan
          }
          return plan
        })
      })
    },
  }
}
