import { type GetHookResponse } from '../hooks'
import { autocompleteKey } from '../keys'
import { useTantalimQuery } from '../query'
import { type LocationAutocompleteResult, isNotEmpty, type API } from '@terros/common'

export function useAutocomplete(input: API.LocationAutocompleteInput): GetHookResponse<LocationAutocompleteResult[]> {
  return useTantalimQuery<API.LocationAutocompleteInput, API.LocationAutocompleteSuccess, LocationAutocompleteResult[]>(
    {
      url: '/location/autocomplete',
      queryKey: autocompleteKey(input.query),
      input,
      convertResponse: (data) => {
        return data.results
      },
      options: {
        enabled: isNotEmpty(input.query),
      },
    }
  )
}
