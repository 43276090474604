import { GetHookResponse } from '../../hooks'
import { interviewQuestionKey } from '../../keys'
import { useTantalimQuery } from '../../query'
import { API, InterviewQuestionData, InterviewQuestionId, Logger } from '@terros/common'

const logger = new Logger('useInterviewQuestion')

export function useInterviewQuestion(interviewQuestionId: InterviewQuestionId): GetHookResponse<InterviewQuestionData> {
  logger.verbose('calling useInterviewQuestion', interviewQuestionId)
  return useTantalimQuery<API.InterviewQuestionGetInput, API.InterviewQuestionGetSuccess, InterviewQuestionData>({
    url: '/interview/question/get',
    queryKey: interviewQuestionKey(interviewQuestionId),
    input: {
      questionId: interviewQuestionId,
    },
    convertResponse: (data) => data.question,
  })
}
