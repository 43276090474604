import {
  type PermissionType,
  type PermissionDefinition,
  PERMISSION_DEFINITION,
  type PermissionEditInfo,
  type AllowedScopes,
  unique,
  isDefined,
  type EditScope,
} from '@terros/common'

type GroupedPermissionDefinitions = Map<string, Map<PermissionType, PermissionDefinition>>

function buildPermissionGroups(): GroupedPermissionDefinitions {
  const grouped: GroupedPermissionDefinitions = new Map()
  Object.entries(PERMISSION_DEFINITION).forEach(([k, v]) => {
    const group = grouped.get(v.category) ?? new Map()
    group.set(k, v)
    grouped.set(v.category, group)
  })
  return grouped
}

export const permissionGroups = Array.from(buildPermissionGroups().entries())

export const cleanupPermissionInfo = <T extends PermissionEditInfo<U>, U extends PermissionType>(
  permissionInfo: T
): T => {
  return {
    ...permissionInfo,
    overridable: cleanup([
      ...permissionInfo.overridable,
      permissionInfo.inherits?.scope ?? 'none',
    ] as AllowedScopes<U>[]),
    current: getCurrentScope(permissionInfo),
  }
}

const getCurrentScope = <T extends PermissionEditInfo<U>, U extends PermissionType>(
  permissionInfo: T
): EditScope<U> => {
  if (permissionInfo.current === 'default') {
    if (isDefined(permissionInfo.inherits)) return permissionInfo.inherits.scope
    return 'none'
  }

  return permissionInfo.current
}

const cleanup = <T extends AllowedScopes<PermissionType>>(arr: T[]): T[] => {
  return unique(arr.filter((a) => !['other', 'none'].includes(a)))
}
