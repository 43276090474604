import { type PermissionType } from '../definition'
import { PermissionOverride } from '../types'
import { DEFAULT_COMPANY_ADMIN } from './admin'
import { DEFAULT_TEAM_MANAGER } from './manager'
import { DEFAULT_USER } from './user'

const ADMIN_PERMISSIONS: PermissionType[] = DEFAULT_COMPANY_ADMIN.permissionOverrides?.map(toPermissionType) ?? []
const MANAGER_PERMISSIONS: PermissionType[] = DEFAULT_TEAM_MANAGER.permissionOverrides?.map(toPermissionType) ?? []
const USER_PERMISSIONS: PermissionType[] = DEFAULT_USER.permissionOverrides?.map(toPermissionType) ?? []

export const TERROS_USER_PERMISSIONS: PermissionType[] = [
  ...ADMIN_PERMISSIONS,
  ...MANAGER_PERMISSIONS,
  ...USER_PERMISSIONS,
  // Permissions that are not part of the default roles
  'company:list',
  'company:add',
  'user:login',
]

function toPermissionType(permission: PermissionOverride<PermissionType>): PermissionType {
  return permission.permissionType
}
