import { useReplaceLocation } from '../contact'
import { type AddHookResponse, mutationAdd } from '../hooks'
import { useLocationCache } from '../location'
import { useTantalimMutation } from '../mutation'
import { API, Logger, type ExtendedLocationData } from '@terros/common'

const logger = new Logger('resident')

export function useAddLocationResident(): AddHookResponse<API.LocationResidentInput, ExtendedLocationData> {
  const replaceLocation = useReplaceLocation()
  const { replaceItem } = useLocationCache()

  const mutation = useTantalimMutation<API.LocationResidentInput, API.LocationResidentSuccess>({
    url: '/location/resident',
  })

  async function add({ locationId, resident }: API.LocationResidentInput): Promise<ExtendedLocationData> {
    try {
      const { location: newLocation } = await mutation.mutateAsync({ locationId, resident })
      logger.verbose('saved location', newLocation)

      replaceLocation({ locationId, newLocation, disposition: newLocation.disposition })
      replaceItem(locationId, newLocation)
      return newLocation
    } catch (error) {
      throw API.toResponseError(error)
    }
  }

  return mutationAdd(mutation, add)
}
