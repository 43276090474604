import {
  type TeamId,
  type TinyTeam,
  toTeamId,
  type UserTeamData,
  type WithTeamId,
  isTeamId,
  TERROS_TECH_TEAM_ID,
  type TeamData,
  type TeamMemberData,
  type RoleData,
} from '../model'
import { isEmpty } from './isEmpty'
import { isManagerRole, toRoleId } from './role'

export function isManagerOfUser(managerTeams: TeamId[] | undefined, employeeTeams: UserTeamData | undefined): boolean {
  if (isEmpty(managerTeams)) return false
  return getTeams(employeeTeams)
    .map(toTeamId)
    .some((teamId) => managerTeams.includes(teamId))
}

export function getTeams(teamData?: UserTeamData): TinyTeam[] {
  return teamData?.memberOf ?? []
}

export function getHighestTeam(teamData?: UserTeamData): TinyTeam | undefined {
  return getTeams(teamData).at(-1)
}

export function getLowestTeam(teamData?: UserTeamData): TinyTeam | undefined {
  return getTeams(teamData).at(0)
}

export function isTerrosTech(team: WithTeamId | TeamId | undefined): boolean {
  if (isEmpty(team)) return false
  if (isTeamId(team)) {
    return team === TERROS_TECH_TEAM_ID
  }
  return isTerrosTech(team.teamId)
}

type WithTeamLevel = Pick<TeamData, 'level'>

export function sortLevelsAsc(a: WithTeamLevel, b: WithTeamLevel): number {
  return a.level - b.level
}

export function sortLevelsDesc(a: WithTeamLevel, b: WithTeamLevel): number {
  return b.level - a.level
}

export function teamMemberIsManager(
  member: Pick<TeamMemberData, 'roleIds'>,
  roles: Pick<RoleData, 'roleId' | 'roleType'>[]
): boolean {
  const managerRoles = roles.filter(isManagerRole).map(toRoleId)
  return member.roleIds?.some((r) => managerRoles.includes(r)) ?? false
}

export function uniqueTeams<T extends WithTeamId>(teams: T[]): T[] {
  const map = new Map<TeamId, T>()
  teams.forEach((team) => {
    if (!map.has(team.teamId)) {
      map.set(team.teamId, team)
    }
  })
  return Array.from(map.values())
}
