import { mutationUpdate, type UpdateHookResponse } from '../hooks'
import { useTantalimMutation } from '../mutation'
import { useProfile } from '../user'
import { useUserCache } from '../user/cache'
import { useCompanyCache } from './cache'
import { API, type CompanyData, Logger, type PartialCompanyData } from '@terros/common'

const logger = new Logger('useCompanies')

export function useUpdateCompany(): UpdateHookResponse<PartialCompanyData, CompanyData> {
  logger.verbose('calling useUpdateCompany')
  const profile = useProfile()
  const cache = useCompanyCache()
  const userCache = useUserCache()

  const mutation = useTantalimMutation<API.CompanyUpdateInput, API.CompanyUpdateSuccess>({ url: '/company/update' })

  async function update(company: PartialCompanyData): Promise<CompanyData> {
    try {
      logger.verbose('starting update')
      const response = await mutation.mutateAsync({ company })
      if (response.company) {
        cache.clearItem(company.companyId)
        if (profile.company?.companyId === company.companyId) {
          userCache.clearProfile()
        }
        cache.clearList()
      }
      logger.verbose('done with update')
      return response.company
    } catch (error) {
      throw API.toResponseError(error)
    }
  }

  return mutationUpdate(mutation, update)
}
