import { Size, Typography } from '@front/model'
import { type CSSProperties } from '@mantine/core'

export const getFontSizeCssProps = <T extends keyof (typeof Typography)['size']>(
  size: T
): Pick<CSSProperties, 'fontSize' | 'lineHeight'> => {
  return {
    fontSize: Typography.size[size],
    lineHeight: `${Typography.line[size]}px`,
  }
}

export const defaultFormFieldLabel: CSSProperties = getFontSizeCssProps('sm')

export const defaultFormFieldWrapper: CSSProperties = {
  display: 'flex',
  flexDirection: 'column',
  rowGap: Size.space.lg,
}

export const defaultFormFieldDescription: CSSProperties = getFontSizeCssProps('xs')

export const defaultFormFieldInputStyles = {
  root: defaultFormFieldWrapper,
  label: defaultFormFieldLabel,
  description: defaultFormFieldDescription,
}
