import { videoListKey, videoKey, videoNextKey, videoUserBookmarkKey, videoProfileKey } from '../keys'
import { useQueryClient, type InfiniteData } from '@tanstack/react-query'
import {
  type API,
  Logger,
  type VideoWithUserData,
  type VideoData,
  type VideoId,
  type UserProfileData,
  toTinyUser,
  type VideoUserProfile,
} from '@terros/common'

const logger = new Logger('useVideoCache')

export function useVideoCache() {
  const queryClient = useQueryClient()

  const updateAllVideoQueries = (updateFn: (v: VideoWithUserData) => VideoWithUserData): void => {
    const queryKeys = [['video-search'], ['videos'], videoUserBookmarkKey(), videoNextKey()]
    queryKeys.forEach((key) => {
      queryClient.setQueriesData<VideoWithUserData[] | InfiniteData<API.VideoNextSuccess>>(
        { queryKey: key },
        (data) => {
          if (!data) return data
          if (Array.isArray(data)) {
            return data.map(updateFn)
          }
          return {
            ...data,
            pages: data.pages.map((page) => ({
              ...page,
              videos: page.videos.map(updateFn),
            })),
          }
        }
      )
    })
  }

  return {
    clearList: () => {
      logger.verbose('invalidateQueries videos')
      queryClient.invalidateQueries({ queryKey: videoListKey({}) })
    },
    addItem: (item: VideoWithUserData) => {
      logger.verbose('addItem video', item)
      queryClient.setQueryData<VideoWithUserData[]>(videoListKey({}), (old) => [...(old || []), item])
    },
    addItems: (items: VideoWithUserData[]) => {
      logger.verbose('addItems video', items)
      items.forEach((item) => {
        queryClient.setQueryData<VideoWithUserData>(videoKey(item.video.videoId), () => item)
      })
    },
    updateWithAction: (update: VideoWithUserData) => {
      const updateFn = (v: VideoWithUserData): VideoWithUserData => {
        if (v.video.videoId !== update.video.videoId) return v
        return update
      }

      updateAllVideoQueries(updateFn)
      queryClient.setQueryData<VideoWithUserData>(videoKey(update.video.videoId), (old) => (old ? updateFn(old) : old))
    },
    replaceItem: (item: VideoData) => {
      logger.verbose('replaceItem video', item)
      queryClient.setQueryData<VideoWithUserData>(videoKey(item.videoId), (old) =>
        old ? { ...old, video: item } : old
      )
      updateAllVideoQueries((v) => (v.video.videoId === item.videoId ? { ...v, video: item } : v))
    },
    removeItem: (videoId: VideoId) => {
      logger.verbose('removeItem videoId', videoId)
      queryClient.setQueryData<VideoWithUserData[]>(videoListKey({}), (old) =>
        old?.filter((t) => t.video.videoId !== videoId)
      )
    },
    replaceProfileItem: (user: UserProfileData) => {
      queryClient.setQueryData<VideoUserProfile>(videoProfileKey(user.userId), () => {
        return {
          ...toTinyUser(user),
          biography: user.biography,
        }
      })
    },
  }
}

const getLikeDiff = (prev?: boolean, liked?: boolean): number => {
  if (liked === true && !prev) return 1
  if (!liked && prev) return -1
  return 0
}
