import { useReplaceLocation } from '../contact'
import { type AddHookResponse, mutationAdd } from '../hooks'
import { useLocationCache } from '../location'
import { useTantalimMutation } from '../mutation'
import { API, Logger, type ExtendedLocationData, type LocationId } from '@terros/common'

const logger = new Logger('note')

export function useAddLocationNote(locationId: LocationId): AddHookResponse<string, ExtendedLocationData> {
  const replaceLocation = useReplaceLocation()
  const { replaceItem } = useLocationCache()

  const mutation = useTantalimMutation<API.LocationNoteInput, API.LocationNoteSuccess>({
    url: '/location/note',
  })

  async function add(notes: string): Promise<ExtendedLocationData> {
    try {
      const { location: newLocation } = await mutation.mutateAsync({ locationId, notes })
      logger.verbose('saved location', newLocation)

      replaceLocation({ locationId, newLocation, disposition: newLocation.disposition })
      replaceItem(locationId, newLocation)
      return newLocation
    } catch (error) {
      throw API.toResponseError(error)
    }
  }

  return mutationAdd(mutation, add)
}
