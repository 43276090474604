// noinspection ES6PreferShortImport -- causes require cycle
import { isEmpty } from '../util/isEmpty'

const SEPARATOR = '|'
export type CompositeKeyId = `${string}${typeof SEPARATOR}${string}`

export function buildKey<P, S>(partitionKey: P, sortKey: S): CompositeKeyId {
  return `${partitionKey}${SEPARATOR}${sortKey}`
}

/** @deprecated use isId in schemas/common */
export function isId(val: unknown, prefix: string): boolean {
  if (isEmpty(val)) return false
  if (typeof val !== 'string') return false
  return val.startsWith(prefix + ':') || val.startsWith(prefix + '.') || val.startsWith(prefix + '_')
}
