import { evaluationKey, evaluationListKey } from '../keys'
import { useQueryClient } from '@tanstack/react-query'
import { type EvaluationData, type EvaluationId, Logger, type TinyEvaluation } from '@terros/common'

const logger = new Logger('useEvaluationCache')

export function useEvaluationCache() {
  const queryClient = useQueryClient()
  return {
    clearItem: (evaluationId: EvaluationId) => {
      logger.verbose('invalidateQueries evaluationKey', evaluationId)
      queryClient.invalidateQueries({ queryKey: evaluationKey(evaluationId) })
    },
    clearList: () => {
      logger.verbose('invalidateQueries evaluationListKey')
      queryClient.invalidateQueries({ queryKey: evaluationListKey({}) })
    },
    setItem: (evaluationId: EvaluationId, newEvaluation: EvaluationData) => {
      logger.verbose('setQueryData evaluationKey', evaluationId)
      queryClient.setQueryData(evaluationKey(evaluationId), newEvaluation)
      const data = queryClient.getQueryData(evaluationListKey({})) as TinyEvaluation[]
      const updatedData = data.map((evaluation) => {
        if (evaluation.evaluationId === newEvaluation.evaluationId) {
          return newEvaluation
        }
        return evaluation
      })
      queryClient.setQueryData(evaluationListKey({}), updatedData)
    },
  }
}
