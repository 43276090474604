import { mutationRemove, type RemoveHookResponse } from '../hooks'
import { useTantalimMutation } from '../mutation'
import { useTeamCache } from './cache'
import { API, Logger } from '@terros/common'

const logger = new Logger('useTeams')

export function useRemoveTeam(): RemoveHookResponse<API.TeamRemoveInput> {
  logger.verbose('calling useRemoveTeam')
  const cache = useTeamCache()
  const mutation = useTantalimMutation<API.TeamRemoveInput, API.TeamRemoveSuccess>({ url: '/team/remove' })

  async function remove(input: API.TeamRemoveInput): Promise<void> {
    try {
      await mutation.mutateAsync(input)
      cache.clearList()
      cache.clearItem(input.teamId)
    } catch (error) {
      throw API.toResponseError(error)
    }
  }

  return mutationRemove(mutation, remove)
}
