import { BLACK_BLURHASH } from '../avatar'
import { teamKey, teamListKey } from '../keys'
import { type TeamList } from '../types'
import { useQueryClient } from '@tanstack/react-query'
import { type API, Logger, type TeamData, type TeamId, type UserProfileData, type TeamMemberData } from '@terros/common'

const logger = new Logger('useTeamCache')

// logger.level = 'verbose'

export function useTeamCache() {
  const queryClient = useQueryClient()

  const clearItem = (teamId: TeamId): void => {
    logger.verbose('invalidateQueries teamKey', teamId)
    queryClient.invalidateQueries({ queryKey: teamKey(teamId) })
  }

  const updateMembership = (
    members: TeamMemberData[],
    { teamId, userId, locked, roleIds }: API.TeamMemberAddInput
  ): TeamMemberData[] => {
    if (members.some((m) => m.user.userId === userId)) {
      return members.map((m): TeamMemberData => {
        if (m.user.userId === userId) {
          return {
            ...m,
            locked,
            roleIds,
          }
        }
        return m
      })
    }
    clearItem(teamId)
    return members
  }

  return {
    clearItem,
    clearList: (props: API.TeamListInput = {}) => {
      logger.verbose('invalidateQueries teamListKey')
      queryClient.invalidateQueries({ queryKey: teamListKey(props) })
    },
    getItem: (teamId: TeamId): TeamData | undefined => {
      logger.verbose('get teamKey', teamId)
      return queryClient.getQueryData<TeamList>(teamListKey({}))?.find((t) => teamId === t.teamId)
    },
    updateMembership: (input: API.TeamMemberAddInput): void => {
      const { teamId } = input
      queryClient.setQueryData<API.TeamGetSuccess>(teamKey(teamId), (existing) => {
        if (!existing) return
        const updated = updateMembership(existing.members, input)
        return {
          ...existing,
          members: updated,
        }
      })
    },
    removeMembership: (input: API.TeamMemberRemoveInput): void => {
      const { teamId, userId } = input
      queryClient.setQueryData<API.TeamGetSuccess>(teamKey(teamId), (existing) => {
        if (!existing) return
        const updated = existing.members.filter((m) => m.user.userId !== userId)
        return {
          ...existing,
          members: updated,
        }
      })
    },
    replaceUser: (user: UserProfileData): void => {
      logger.verbose('replaceUser', user)
      queryClient.setQueriesData<API.TeamGetSuccess>({ queryKey: ['team'] }, (existing) => {
        if (!existing) return
        return {
          ...existing,
          members: existing.members.map((member) => {
            if (member.user.userId === user.userId) {
              return {
                ...member,
                user,
              }
            }
            return member
          }),
        }
      })
    },
    updateAvatar: (teamId: TeamId): void => {
      queryClient.setQueryData<API.TeamGetSuccess>(teamKey(teamId), (existing) => {
        if (!existing) return
        return {
          ...existing,
          team: {
            ...existing.team,
            avatarUrl: existing.team.avatarUrl + '?v=' + Date.now(),
            avatarBlurhash: existing.team.avatarBlurhash ?? BLACK_BLURHASH,
          },
        }
      })
    },
  }
}
