import { type GetHookResponse } from '../hooks'
import { locationsKey } from '../keys'
import { useTantalimQuery } from '../query'
import { keepPreviousData } from '@tanstack/react-query'
import { type API, DAY, HOUR, type LatLng, Logger, type TinyLocationData } from '@terros/common'

const logger = new Logger('useLocationSearch')
// logger.level = 'verbose'

export type LocationsState = GetHookResponse<TinyLocationData[]>

export function useLocationSearch(coordinates: LatLng): LocationsState {
  const query = useTantalimQuery<API.LocationSearchInput | undefined, API.LocationSearchSuccess, TinyLocationData[]>({
    url: '/location/search',
    queryKey: locationsKey(coordinates),
    input: {
      latitude: coordinates.latitude,
      longitude: coordinates.longitude,
    },
    convertResponse: (data) => data.locations2,
    options: {
      placeholderData: keepPreviousData,
      gcTime: HOUR,
      staleTime: DAY,
    },
  })

  if (query.isSuccess) {
    logger.verbose('data length', query.data?.length, JSON.stringify(coordinates))
  }

  return query
}
