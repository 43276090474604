import { accountRulesKey } from '../keys'
import { useQueryClient } from '@tanstack/react-query'
import { type AccountRuleData, AccountRuleId, Logger } from '@terros/common'

const logger = new Logger('useAccountRuleCache')
// logger.level = 'verbose'

export function useAccountRuleCache() {
  const queryClient = useQueryClient()
  return {
    clearList: () => {
      logger.verbose('invalidateQueries accountRuleesKey')
      queryClient.invalidateQueries({ queryKey: accountRulesKey() })
    },
    updateItem: (rule: AccountRuleData) => {
      queryClient.setQueryData<AccountRuleData[]>(accountRulesKey(), (existing) => {
        if (!existing) return
        const index = existing.findIndex((item) => item.ruleId === rule.ruleId)
        if (index === -1) return [...existing, rule]
        return existing.map((item, i) => (i === index ? rule : item))
      })
    },
    removeItem: (ruleId: AccountRuleId) => {
      logger.verbose('removeItem', ruleId)
      queryClient.setQueryData<AccountRuleData[]>(accountRulesKey(), (existing) => {
        if (!existing) return
        return existing.filter((item) => item.ruleId !== ruleId)
      })
    },
  }
}
