import { mutationRemove, type RemoveHookResponse } from '../hooks'
import { useTantalimMutation } from '../mutation'
import { useQuestionCache } from './cache'
import { API, Logger } from '@terros/common'

const logger = new Logger('useRemoveQuestion')

export function useRemoveQuestion(): RemoveHookResponse<API.QuestionRemoveInput> {
  logger.verbose('calling useRemoveQuestion')
  const cache = useQuestionCache()
  const mutation = useTantalimMutation<API.QuestionRemoveInput, API.QuestionRemoveSuccess>({
    url: '/question/manage/remove',
  })

  async function remove(input: API.QuestionRemoveInput): Promise<void> {
    try {
      await mutation.mutateAsync(input)
      cache.removeItem(input.questionId)
    } catch (error) {
      throw API.toResponseError(error)
    }
  }

  return mutationRemove(mutation, remove)
}
