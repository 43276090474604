import { webhooksKey } from '../keys'
import { useQueryClient } from '@tanstack/react-query'
import { Logger, type WebhookData, type WebhookId } from '@terros/common'

const logger = new Logger('useWebhookCache')

export function useWebhookCache() {
  const queryClient = useQueryClient()
  return {
    clearList: () => {
      logger.verbose('invalidateQueries Webhooks')
      queryClient.invalidateQueries({ queryKey: webhooksKey() })
    },
    addItem: (item: WebhookData) => {
      logger.verbose('addItem Webhook', item)
      queryClient.setQueryData<WebhookData[]>(webhooksKey(), (old) => [...(old || []), item])
    },
    replaceItem: (item: WebhookData) => {
      logger.verbose('replaceItem Webhook', item)
      queryClient.setQueryData<WebhookData[]>(webhooksKey(), (old) => {
        if (!old) {
          return [item]
        }
        return old.map((t) => (t.webhookId === item.webhookId ? item : t))
      })
    },
    removeItem: (webhookId: WebhookId) => {
      logger.verbose('removeItem Webhook', webhookId)
      queryClient.setQueryData<WebhookData[]>(webhooksKey(), (old) => old?.filter((t) => t.webhookId !== webhookId))
    },
  }
}
