import { serverRequest, type BackendContextType, useBackend } from '../api'
import { useAutocomplete } from './useAutocomplete'
import {
  Logger,
  type API,
  type LocationAutocompleteResult,
  type LatLng,
  type ExternalAddressWithLocationId,
} from '@terros/common'
import { useEffect, useState, type Dispatch, type SetStateAction } from 'react'

const logger = new Logger('usePlacesAutocomplete')

type Params = {
  search: string
  setSearch: Dispatch<SetStateAction<string>>
  bias?: LatLng
}

type Output = {
  data: LocationAutocompleteResult[]
  getDetails: (place_id: string) => Promise<ExternalAddressWithLocationId>
}

export const useAutocompleteBox = ({ search, setSearch, bias }: Params): Output => {
  const backend = useBackend()
  const [sessionToken, setSessionToken] = useState<string>(makeid(16))
  const { data: autoCompleteData, error } = useAutocomplete({ query: search, sessionToken, bias })
  const [prevData, setPrevData] = useState<LocationAutocompleteResult[]>([])

  useEffect(() => {
    if (autoCompleteData !== undefined) setPrevData(autoCompleteData)
  }, [autoCompleteData])

  const data = autoCompleteData ?? prevData

  useEffect(() => {
    if (error) {
      logger.error('Error fetching places:', error)
    }
  }, [error])

  const getDetails = async (placeId: string): Promise<ExternalAddressWithLocationId> => {
    setSearch('')
    setSessionToken(makeid(16))
    return fetchPlaceDetails(backend, placeId, sessionToken)
  }

  return { data, getDetails }
}

const fetchPlaceDetails = async (
  backend: BackendContextType,
  placeId: string,
  sessionToken: string
): Promise<ExternalAddressWithLocationId> => {
  const response = await serverRequest<API.LocationExchangeInput, API.LocationExchangeSuccess>({
    backend,
    path: '/location/exchange',
    input: {
      placeId,
      sessionToken,
    },
  })

  return response.details
}

/**
 * This is not cryptographically secure
 * @param length
 */
function makeid(length: number): string {
  let result = ''
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
  const charactersLength = characters.length
  let counter = 0
  while (counter < length) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength))
    counter += 1
  }
  return result
}
