/* eslint-disable no-console -- This is literally calling the console on purpose */
let logLevels: Record<string, Level> = {}

const colors = {
  black: '0;0;0',
  red: '255;0;0',
  green: '0;255;0',
  yellow: '255;255;0',
  blue: '0;0;255',
  magenta: '255;0;255',
  cyan: '0;255;255',
  white: '255;255;255',
  gray: '128;128;128',
} as const

type LevelInfo = {
  /** Log priority level. Lower numbers are more important. */
  numericLevel: number
  color?: keyof typeof colors
}
const levels = {
  error: { numericLevel: 0 },
  warn: { numericLevel: 1, color: 'yellow' },
  info: { numericLevel: 2, color: 'white' },
  timer: { numericLevel: 3, color: 'cyan' },
  verbose: { numericLevel: 4, color: 'gray' },
} as const
export type Level = keyof typeof levels

function isDisabled(): boolean {
  if (typeof process === 'undefined') return false
  if (typeof process.env === 'undefined') return false
  return process.env.NODE_ENV === 'test'
}

/**
 * The Logger provides a framework for writing messages to the console.
 * Modules that log can each have their own Logger instance with a unique name.
 * Log levels can be set differently for each Logger instance.
 * It supports different levels and filters all messages that are lower priority than the current level.
 * See `levels` for information on what levels are available and their priority.
 *
 * If there is a logging level configuration set in `logLevels` and the Logger constructor
 * is not passed a log level, it will try to find it from `logLevels`.
 */
export class Logger {
  /** A set of all active loggers, by name. Used to dynamically configure log level. */
  public static loggers: Record<string, Logger> = {}
  /** An optional function that will be called with each log message */
  public static logWatch?: (message: string) => void
  /** If true, all loggers will be disabled */
  public static showLoggerOutput = true
  public disabled = isDisabled()
  private _level: Level = 'error'
  private numericLevel = 0
  private startTime?: number

  constructor(
    public name: string,
    level?: Level
  ) {
    Logger.loggers[name] = this
    this.level = level ?? logLevelFromConfig(name) ?? 'timer'
  }

  public get level(): Level {
    return this._level
  }

  public set level(level: Level) {
    if (levels[level]) {
      this._level = level
      this.numericLevel = levels[level].numericLevel
    }
  }

  /**
   * Log level static configuration. The format is `"name": ${level}`.
   * The `name` key can be a regular expression which will match against
   * the entire logger name (it is wrapped with `^` and `$`).
   * For example, `{'api.*': 'info'}` will set loggers starting with "api" to "info".
   * Note: This must be set before calling new Logger(''), such as at the start of your application
   */
  static addLogLevel(pattern: string, level: Level): void {
    logLevels[pattern] = level
    findLoggerNames(pattern).forEach((logger) => (Logger.loggers[logger].level = level))
  }

  static clearLogLevels(): void {
    logLevels = {}
  }

  findIndexToSplit(stack: string[]): number | undefined {
    const found = stack.findIndex((l) => l.includes('throwError'))
    if (found >= 0) return found
    const foundAPIError = stack.findIndex((l) => l.includes('buildError'))
    if (foundAPIError >= 0) return foundAPIError
  }

  split(stack?: string): string | undefined {
    if (!stack) return
    const lines = stack.split('\n')
    const indexToSplit = this.findIndexToSplit(lines)
    const first = lines.at(0)
    const rest = lines.slice(indexToSplit ? indexToSplit + 1 : 0).join('\n')
    return first + '\n' + rest
  }

  error(message: unknown, ...other: unknown[]): void {
    if (typeof message === 'string') this.log('error', message, ...other)
    else this.log('error', '', message, other)
  }

  warn(message: string, ...other: unknown[]): void {
    this.log('warn', message, ...other)
  }

  info(message: string, ...other: unknown[]): void {
    this.log('info', message, ...other)
  }

  verbose(message: string, ...other: unknown[]): void {
    this.log('verbose', message, ...other)
  }

  log(level: Level, message: string, ...other: unknown[]): void {
    const info = levels[level]
    if (!info) return
    if (info.numericLevel > this.numericLevel) return

    const m = this.format(info, message)
    if (Logger.logWatch) {
      try {
        const others = other.map((o) => JSON.stringify(o)).join('\n')
        Logger.logWatch(`${this.buildPrefix()} ${message}${others ? '\n' + others : ''}`)
      } catch (e) {
        console.error('Failed to call logWatch', e)
      }
    }

    if (level === 'error') {
      // TODO extract this logic to a separate function
      const errorObj = other.at(0)
      if (errorObj instanceof Error) {
        errorObj.stack = this.split(errorObj.stack)
        console.error(errorObj)
      } else {
        const error = new Error(m)
        error.stack = this.split(error.stack)
        console.error(error, ...other)
      }
    } else if (level === 'warn') {
      console.log(m, ...other)
    } else {
      if (!Logger.showLoggerOutput) return // turn off logging to console globally
      if (this.disabled) return // turn off logging to console for
      console.log(m, ...other)
    }
  }

  startTimer(): void {
    this.startTime = Date.now()
  }

  timer(message: string): number {
    const elapsed = Date.now() - (this.startTime ?? 0)
    this.log('timer', `${elapsed.toString()}ms - ${message}`)
    return elapsed
  }

  static colorize(color: keyof typeof colors | undefined, message: string): string {
    if (!color || !showColor()) return message
    const colorStart = `\u001b[38;2;${colors[color]};40m`
    const RESET = '\u001b[0m'
    return `${colorStart}${message}${RESET}`
  }

  private format(info: LevelInfo, message: string): string {
    return `${Logger.colorize(info.color, this.buildPrefix())} ${message}`
  }
  private buildPrefix(): string {
    return `${this.name} (${this.getTime()}):`
  }
  private getTime(): string {
    const now = new Date()
    return `${now.getHours().toString()}:${pad(now.getMinutes())}:${pad(now.getSeconds())}.${(now.getTime() % 1000).toString()}`
  }
}

function pad(value: number): string {
  if (value < 10) return '0' + value.toString()
  return value.toString()
}

/**
 * Reads the log level from the `logLevels` config variable (above).
 * Treats keys as reg-ex's and finds a match to `name`, if possible.
 */
function logLevelFromConfig(name: string): Level | undefined {
  let level: Level | undefined
  Object.entries(logLevels).forEach(([expression, value]) => {
    const loggers = findLoggerNames(expression).map((n) => Logger.loggers[n])
    loggers.some((logger) => {
      if (logger.name === name) {
        level = value
        console.log(`Setting ${name} log level to '${level}'`)
        return true
      }
      return false
    })
  })
  return level
}

/**
 * Finds all loggers whose name matches the expression.
 * The expression can be a plain string or a reg-ex.
 */
function findLoggerNames(exp: string): string[] {
  const regex = RegExp('^' + exp + '$')
  return Object.keys(Logger.loggers).filter((name) => regex.test(name))
}

function showColor(): boolean {
  if (typeof process === 'undefined') return true
  //TODO: Switch it to getStage() later
  const stage = process.env.SST_STAGE
  if (stage === 'dev') return false
  if (stage === 'prod') return false
  return true
}
