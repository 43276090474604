import { type AddHookResponse, mutationAdd } from '../hooks'
import { useTantalimMutation } from '../mutation'
import { useTaskCache } from '../task'
import { useEventCache } from './cache'
import { API, type CalendarEventData, Logger } from '@terros/common'

const logger = new Logger('calendar-event-confirm')

export function useConfirmCalendarEvent(): AddHookResponse<API.CalendarEventConfirmInput, CalendarEventData> {
  const eventCache = useEventCache()
  const taskCache = useTaskCache()
  const mutation = useTantalimMutation<API.CalendarEventConfirmInput, API.CalendarEventConfirmSuccess>({
    url: '/calendar/event/confirm',
  })

  async function add(input: API.CalendarEventConfirmInput): Promise<CalendarEventData> {
    try {
      const output = await mutation.mutateAsync(input)
      eventCache.replaceItem(output.event)
      taskCache.clearList()
      logger.verbose('confirm succeeded', output)
      return output.event
    } catch (error) {
      throw API.toResponseError(error)
    }
  }

  return mutationAdd(mutation, add)
}
