import { mutationRemove, type RemoveHookResponse } from '../hooks'
import { useTantalimMutation } from '../mutation'
import { useAccountCache } from './cache'
import { API, Logger } from '@terros/common'

const logger = new Logger('useAccounts')

export function useRemoveAccount(): RemoveHookResponse<API.AccountRemoveInput> {
  logger.verbose('calling useRemoveAccount')
  const mutation = useTantalimMutation<API.AccountRemoveInput, API.AccountRemoveSuccess>({
    url: '/account/remove',
  })
  const { removeItem } = useAccountCache()

  async function remove(input: API.AccountRemoveInput): Promise<void> {
    try {
      await mutation.mutateAsync(input)
      removeItem(input.accountId)
    } catch (error) {
      throw API.toResponseError(error)
    }
  }

  return mutationRemove(mutation, remove)
}
