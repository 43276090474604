import { featureGetKey, featureKey, featureListKey } from '../keys'
import { useQueryClient } from '@tanstack/react-query'
import { type FeatureId, Logger } from '@terros/common'

const logger = new Logger('useFeatureCache')

// logger.level = 'verbose'

export function useFeatureCache() {
  const queryClient = useQueryClient()
  return {
    clearItem: (featureId: FeatureId) => {
      logger.verbose('invalidateQueries featureKey', featureId)
      queryClient.invalidateQueries({ queryKey: featureKey(featureId) })
      queryClient.invalidateQueries({ queryKey: featureGetKey({ featureId }) })
    },
    clearList: () => {
      logger.verbose('invalidateQueries featureListKey')
      queryClient.invalidateQueries({ queryKey: featureListKey() })
    },
  }
}
