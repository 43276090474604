import classes from './button.module.css'
import { type ButtonSize, type ButtonType, type ButtonVariant } from './types'
import { getVariant, getButtonColor, isIconButton, getBorderRadius, getDefaultIconSize, getButtonPadding } from './util'
import { SizePrimitives } from '@front/model'
import {
  ActionIcon,
  type ButtonProps as MButtonProps,
  Button as MButton,
  createPolymorphicComponent,
  Tooltip,
  type ActionIconProps,
} from '@mantine/core'
import { type Icon, type IconProps } from '@tabler/icons-react'
import { getFontSizeCssProps, cn } from '@util'
import { type ButtonHTMLAttributes, forwardRef } from 'react'

export type TButtonProps = Omit<ButtonHTMLAttributes<HTMLButtonElement>, keyof MButtonProps> &
  MButtonProps & {
    variant?: ButtonVariant
    size?: ButtonSize
    buttonType?: ButtonType
    icon?: Icon
    iconProps?: IconProps
    iconPosition?: 'left' | 'right'
    tooltip?: string
  }

const _TButton = forwardRef<HTMLButtonElement, TButtonProps>(({ tooltip, ...props }, ref) => {
  if (tooltip) {
    return (
      <Tooltip label={tooltip}>
        <ButtonDisplay {...props} ref={ref} />
      </Tooltip>
    )
  }

  return <ButtonDisplay {...props} ref={ref} />
})

_TButton.displayName = 'TButton'

export const TButton = createPolymorphicComponent<'button', TButtonProps>(_TButton)

const ButtonDisplay = forwardRef<HTMLButtonElement, Omit<TButtonProps, 'tooltip'>>(
  (
    {
      variant = 'primary',
      size = 'small',
      buttonType = 'default',
      children,
      icon: Icon,
      iconProps,
      iconPosition = 'left',
      leftSection,
      rightSection,
      color,
      styles,
      className,
      ...props
    },
    ref
  ) => {
    const buttonVariant = getVariant(variant)
    const buttonColor = color ?? getButtonColor(variant)
    const iconSize = iconProps?.size ?? getDefaultIconSize(size)

    if (isIconButton(buttonType)) {
      return (
        <ActionIcon
          {...(props as ActionIconProps)}
          aria-label={typeof children === 'string' ? children : undefined}
          className={cn(classes.button, className)}
          color={buttonColor}
          ref={ref}
          size={size === 'medium' ? 'xl' : 'lg'}
          styles={{
            root: {
              borderRadius: getBorderRadius(buttonType, size),
              aspectRatio: 1,
              height: size === 'small' ? SizePrimitives['8'] : SizePrimitives['11'],
            },
          }}
          variant={buttonVariant}
        >
          {Icon && <Icon {...iconProps} size={iconSize} />}
        </ActionIcon>
      )
    }

    let left = leftSection
    let right = rightSection
    if (Icon) {
      if (iconPosition === 'left') {
        left = leftSection ?? <Icon {...iconProps} size={iconSize} />
      } else {
        right = rightSection ?? <Icon {...iconProps} size={iconSize} />
      }
    }

    return (
      <MButton
        {...props}
        className={cn(classes.button, className)}
        color={buttonColor}
        leftSection={left}
        ref={ref}
        rightSection={right}
        styles={{
          root: {
            ...getButtonPadding(variant, size),
            borderRadius: getBorderRadius(buttonType, size),
            height: 'fit-content',
            minHeight: size === 'small' ? SizePrimitives['8'] : SizePrimitives['11'],
          },
          loader: {
            fontSize: size === 'small' ? SizePrimitives['3'] : SizePrimitives['5'],
          },
          label: getFontSizeCssProps(size === 'small' ? 'xs' : 'sm'),
        }}
        variant={buttonVariant}
      >
        {children}
      </MButton>
    )
  }
)

ButtonDisplay.displayName = 'TButtonDisplay'
