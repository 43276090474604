import { type GetHookResponse } from '../hooks'
import { roleKey } from '../keys'
import { useTantalimQuery } from '../query'
import { type API, HOUR, Logger } from '@terros/common'

const logger = new Logger('useRole')

export function useRole(input: Partial<API.RoleGetInput>, enabled = true): GetHookResponse<API.RoleGetSuccess> {
  const { roleId = 'Role.EMPTY_ROLE' } = input
  logger.verbose('calling useRole', roleId)
  return useTantalimQuery<API.RoleGetInput, API.RoleGetSuccess, API.RoleGetSuccess>({
    url: '/role/get',
    queryKey: roleKey(input),
    input: {
      ...input,
      roleId,
    },
    convertResponse: (data) => data,
    options: {
      gcTime: HOUR,
      staleTime: HOUR,
      enabled: Boolean(roleId) && enabled,
    },
  })
}
