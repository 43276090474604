import { type AddHookResponse, mutationAdd } from '../hooks'
import { useTantalimMutation } from '../mutation'
import { useAccountStatusCache } from './cache'
import { Logger, type UnsavedAccountStatus, type AccountStatusData, API } from '@terros/common'

const logger = new Logger('useAddAccountStatus')

export function useAddAccountStatus(): AddHookResponse<UnsavedAccountStatus, AccountStatusData> {
  const { addItem } = useAccountStatusCache()
  const mutation = useTantalimMutation<API.AccountStatusAddInput, API.AccountStatusAddSuccess>({
    url: '/account/status/add',
  })

  async function add(unsaved: UnsavedAccountStatus): Promise<AccountStatusData> {
    try {
      const { status } = await mutation.mutateAsync({ status: unsaved })
      logger.verbose('add succeeded', status)
      addItem(status)
      return status
    } catch (error) {
      throw API.toResponseError(error)
    }
  }

  return mutationAdd(mutation, add)
}
