import { oauthListKey } from '../keys'
import { useQueryClient } from '@tanstack/react-query'
import { Logger, type UserCredentialData, type UserCredentialProvider } from '@terros/common'

const logger = new Logger('useOAuthCache')

export function useOAuthCache() {
  const queryClient = useQueryClient()
  return {
    addItem: (provider: UserCredentialProvider) => {
      logger.verbose('setQueryData oauthListKey')
      queryClient.setQueriesData<UserCredentialData[]>({ queryKey: oauthListKey() }, (oldData) => {
        if (oldData) return [...oldData, { provider, enabled: true }]
        return [{ provider, enabled: true }]
      })
    },
    removeItem: (provider: UserCredentialProvider) => {
      logger.verbose('removeItem oauthListKey')
      queryClient.setQueriesData<UserCredentialData[]>({ queryKey: oauthListKey() }, (oldData) => {
        if (oldData) return oldData.filter((item) => item.provider !== provider)
        return []
      })
    },
  }
}
