import { serverRequest, useBackend } from '../api'
import { eventsInfiniteKey } from '../keys'
import {
  useInfiniteQuery,
  type InfiniteData,
  type QueryKey,
  type QueryFunction,
  type UseInfiniteQueryResult,
} from '@tanstack/react-query'
import {
  type CalendarEventData,
  HOUR,
  type API,
  CURRENT_VERSION,
  type APIError,
  messageFromError,
  type CalendarEventDataWithDetails,
  WEEK,
} from '@terros/common'
import { DateTime } from 'luxon'

export type CalendarEventPageInput = Required<Pick<API.CalendarEventListInput, 'startTime' | 'endTime'>>

export type CalendarEventPageSuccess = {
  events: CalendarEventDataWithDetails[]
  startTime: number
  endTime: number
}

type Result = Omit<UseInfiniteQueryResult<InfiniteData<CalendarEventPageSuccess>>, 'error'> & {
  error?: string
}

const CALENDAR_PAGE_SIZE = WEEK

const INITIAL_START_TIME = DateTime.now().startOf('day').toMillis()
const INITIAL_END_TIME = Date.now() + CALENDAR_PAGE_SIZE

export function useInfiniteCalendarEvents(input: API.CalendarEventListInput = {}): Result {
  const { startTime, endTime, ...rest } = input
  const backend = useBackend()

  const queryFn: QueryFunction<CalendarEventPageSuccess, QueryKey, CalendarEventPageInput> = async ({
    pageParam,
  }): Promise<CalendarEventPageSuccess> => {
    const { events } = await serverRequest<API.CalendarEventListInput, API.CalendarEventListSuccess>({
      backend,
      path: '/calendar/event/list',
      input: {
        ...pageParam,
        ...rest,
        version: CURRENT_VERSION,
      },
    })
    events.sort(sortByEventDate)
    return {
      events,
      startTime: pageParam.startTime,
      endTime: pageParam.endTime,
    }
  }

  const query = useInfiniteQuery<
    CalendarEventPageSuccess,
    APIError,
    InfiniteData<CalendarEventPageSuccess>,
    QueryKey,
    CalendarEventPageInput
  >({
    queryKey: eventsInfiniteKey(rest),
    queryFn,
    initialPageParam: {
      startTime: startTime ?? INITIAL_START_TIME,
      endTime: endTime ?? INITIAL_END_TIME,
    },
    getPreviousPageParam: (firstPage) => ({
      startTime: firstPage.startTime - CALENDAR_PAGE_SIZE,
      endTime: firstPage.startTime,
    }),
    getNextPageParam: (lastPage) => ({
      startTime: lastPage.endTime,
      endTime: lastPage.endTime + CALENDAR_PAGE_SIZE,
    }),
    staleTime: HOUR,
    gcTime: 6 * HOUR,
  })
  const error = messageFromError(query.error)
  return { ...query, error }
}

function sortByEventDate(a: CalendarEventData, b: CalendarEventData): number {
  return a.eventDate - b.eventDate
}
