import { type GetHookResponse } from '../hooks'
import { permissionCheckKey } from '../keys'
import { useTantalimQuery } from '../query'
import { type API, type PermissionType, type EntityId, type SingleOrOptionalEntityIds } from '@terros/common'

export const TARGET_MISSING: EntityId = `U:TARGET_MISSING`

export function usePermissionCheck({
  permissionType,
  targetId = TARGET_MISSING,
}: {
  permissionType: PermissionType
  targetId?: SingleOrOptionalEntityIds
}): GetHookResponse<boolean> {
  return useTantalimQuery<API.PermissionCheckInput, API.PermissionCheckSuccess, boolean>({
    url: '/permission/check',
    queryKey: permissionCheckKey({ permissionType, targetId }),
    input: {
      permissionType,
      targetId,
    },
    convertResponse: (data) => data.hasPermission,
    options: {
      enabled: targetId !== TARGET_MISSING,
    },
  })
}
