export const Typography = {
  foundation: {
    family: {
      default: 'Sora',
      mono: 'Geist Mono',
    },
    weight: {
      regular: 'Regular',
      bold: 'Bold',
    },
  },
  size: {
    mini: 10,
    xs: 12,
    sm: 14,
    md: 16,
    lg: 20,
    xl: 24,
    '2xl': 32,
  },
  line: {
    mini: 12,
    xs: 16,
    sm: 20,
    md: 24,
    lg: 28,
    xl: 32,
    '2xl': 44,
  },
} as const
