import { type AddHookResponse, mutationAdd } from '../hooks'
import { useTantalimMutation } from '../mutation'
import { useEventOutcomesCache } from './cache'
import { API, Logger, type UnsavedEventOutcome, type EventOutcomeData } from '@terros/common'

const logger = new Logger('useAddEventOutcome')

export function useAddEventOutcome(): AddHookResponse<UnsavedEventOutcome, EventOutcomeData> {
  const { addItem } = useEventOutcomesCache()
  const mutation = useTantalimMutation<API.EventOutcomeAddInput, API.EventOutcomeAddSuccess>({
    url: '/calendar/outcome/add',
  })

  async function add(unsaved: UnsavedEventOutcome): Promise<EventOutcomeData> {
    try {
      const { outcome } = await mutation.mutateAsync({ outcome: unsaved })
      logger.verbose('add succeeded', outcome)
      addItem(outcome)
      return outcome
    } catch (error) {
      throw API.toResponseError(error)
    }
  }

  return mutationAdd(mutation, add)
}
