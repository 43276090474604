import { taskListKey } from '../keys'
import { useQueryClient } from '@tanstack/react-query'
import { Logger } from '@terros/common'

const logger = new Logger('useTaskCache')

// logger.level = 'verbose'

export function useTaskCache() {
  const queryClient = useQueryClient()
  return {
    clearList: () => {
      logger.verbose('invalidateQueries taskListKey')
      queryClient.invalidateQueries({ queryKey: taskListKey({}) })
    },
  }
}
