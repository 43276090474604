import { eventKey } from '../keys'
import { type CalendarEventPageSuccess } from './listMulti'
import { useQueryClient, type InfiniteData } from '@tanstack/react-query'
import { type CalendarEventData, type CalendarEventId, Logger, type TinyResidentData } from '@terros/common'
import { useCallback } from 'react'

const logger = new Logger('useEventCache')
// logger.level = 'verbose'

type Result = {
  clearItem: (eventId: CalendarEventId) => void
  clearList: VoidFunction
  replaceItem: (eventData: CalendarEventData) => void
  removeItem: (eventId: CalendarEventId) => void
  replaceResident: (eventId: CalendarEventId, residentData: TinyResidentData) => void
}

export function useEventCache(): Result {
  const queryClient = useQueryClient()

  const clearItem = useCallback((eventId: CalendarEventId) => {
    logger.verbose('invalidateQueries eventKey', eventId)
    queryClient.invalidateQueries({ queryKey: eventKey(eventId) })
  }, [])

  const clearList = useCallback(() => {
    logger.verbose('invalidateQueries eventListKey')
    queryClient.invalidateQueries({ queryKey: ['calendar-events'] })
    queryClient.invalidateQueries({ queryKey: ['calendar-events-infinite'] })
  }, [])

  const replaceItem = useCallback((eventData: CalendarEventData) => {
    logger.verbose('replaceItem', eventData)
    queryClient.setQueryData<CalendarEventData>(eventKey(eventData.eventId), eventData)
    updateQueries(queryClient, (events) => events.map((e) => (e.eventId === eventData.eventId ? eventData : e)))
  }, [])

  const removeItem = useCallback((eventId: CalendarEventId) => {
    logger.verbose('removeItem', eventId)
    queryClient.setQueryData<CalendarEventData>(eventKey(eventId), undefined)
    updateQueries(queryClient, (events) => events.filter((e) => e.eventId !== eventId))
  }, [])

  const replaceResident = useCallback((eventId: CalendarEventId, resident: TinyResidentData) => {
    logger.verbose('replaceResident', eventId, resident)
    queryClient.setQueryData<CalendarEventData>(eventKey(eventId), (e) => {
      if (!e) return
      return {
        ...e,
        resident,
      }
    })

    updateQueries(queryClient, (events) =>
      events.map((e) => {
        if (e.eventId === eventId) {
          logger.verbose('found event in cache, replacing resident')
          return {
            ...e,
            resident,
          }
        }
        return e
      })
    )
  }, [])

  return {
    clearItem,
    clearList,
    replaceItem,
    removeItem,
    replaceResident,
  }
}

function updateQueries(
  queryClient: ReturnType<typeof useQueryClient>,
  updateFn: (events: CalendarEventData[]) => CalendarEventData[]
): void {
  queryClient.setQueriesData<CalendarEventData[]>({ queryKey: ['calendar-events'] }, (old) => {
    if (!old) return
    return updateFn(old)
  })
  queryClient.setQueriesData<InfiniteData<CalendarEventPageSuccess>>(
    { queryKey: ['calendar-events-infinite'] },
    (old) => {
      if (!old) return
      return {
        ...old,
        pages: old.pages.map((p) => ({
          ...p,
          events: updateFn(p.events),
        })),
      }
    }
  )
}
